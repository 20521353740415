import React from 'react';
import { AlertCircle, X } from 'lucide-react';

interface AlertModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  message: string;
  children?: React.ReactNode;
}

export default function AlertModal({ isOpen, onClose, title, message, children }: AlertModalProps) {
  if (!isOpen) return null;

  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onClose();
  };

  return (
    <div 
      className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      onClick={handleClose}
    >
      <div 
        className="glass-card w-full max-w-md p-6 rounded-2xl relative"
        onClick={e => e.stopPropagation()}
      >
        <button
          onClick={handleClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-white transition-colors"
        >
          <X size={20} />
        </button>

        <div className="flex items-center gap-3 mb-4">
          <AlertCircle className="w-6 h-6 text-[#3B82F6]" />
          <h2 className="text-xl font-medium text-white">{title}</h2>
        </div>

        <p className="text-gray-300">{message}</p>

        {children || (
          <button
            onClick={handleClose}
            className="glow-button w-full mt-6 !py-2.5"
          >
            Compris
          </button>
        )}
      </div>
    </div>
  );
}