import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

export default function UpdateHeader() {
  const navigate = useNavigate();

  return (
    <div className="mb-8">
      <button
        onClick={() => navigate('/dashboard')}
        className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors mb-4"
      >
        <ArrowLeft className="w-5 h-5" />
        Retour au tableau de bord
      </button>
      
      <h1 className="text-2xl font-medium text-white mb-2">
        Modifier votre abonnement
      </h1>
    </div>
  );
}