import React from 'react';
import { Calendar } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

interface GeneralInformationProps {
  order: any;
}

export default function GeneralInformation({ order }: GeneralInformationProps) {
  const getProcessingStatusText = (status: string) => {
    switch (status) {
      case 'pending_processing':
        return 'À traiter';
      case 'processing':
        return 'En cours de traitement';
      case 'processed':
        return 'Traitée';
      default:
        return 'À traiter';
    }
  };

  return (
    <div className="glass-card rounded-2xl p-6">
      <h3 className="text-lg font-medium text-white flex items-center gap-2 mb-4">
        <Calendar className="w-5 h-5 text-blue-500" />
        Informations de la commande
      </h3>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <span className="text-sm text-gray-400">Numéro de commande</span>
          <p className="text-white">#{order.id.slice(0, 8)}</p>
        </div>

        <div>
          <span className="text-sm text-gray-400">Date</span>
          <p className="text-white">
            {format(new Date(order.createdAt), 'dd MMMM yyyy HH:mm', { locale: fr })}
          </p>
        </div>

        <div>
          <span className="text-sm text-gray-400">Statut</span>
          <p className="text-white">
            {order.status === 'paid' ? 'Payée' : 'Annulée'}
          </p>
        </div>

        <div>
          <span className="text-sm text-gray-400">État du traitement</span>
          <p className="text-white">
            {getProcessingStatusText(order.processingStatus)}
          </p>
        </div>
      </div>
    </div>
  );
}