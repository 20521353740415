import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { getCart } from '../services/cartService';
import StripePayment from '../components/checkout/StripePayment';
import StepIndicator from '../components/checkout/StepIndicator';
import AccountForm from '../components/checkout/AccountForm';
import CompanyForm from '../components/checkout/CompanyForm';
import '../styles/checkout.css';

interface LocationState {
  cartId?: string;
  message?: string;
}

type CheckoutStep = 'account' | 'company' | 'payment';

export default function Checkout() {
  const [currentStep, setCurrentStep] = useState<CheckoutStep>('account');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [cartData, setCartData] = useState<any>(null);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    contactName: '',
    phone: '',
    companyName: '',
    vatNumber: '',
    billingAddress: {
      line1: '',
      line2: '',
      city: '',
      postalCode: '',
      country: ''
    }
  });

  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { cartId, message } = location.state as LocationState || {};

  // Charger les données du panier
  useEffect(() => {
    const loadCartData = async () => {
      const localCartId = cartId || localStorage.getItem('cartId');
      
      if (!localCartId) {
        navigate('/cart');
        return;
      }

      try {
        const data = await getCart(localCartId);
        if (!data) {
          navigate('/cart');
          return;
        }
        console.log('Cart data loaded:', data);
        setCartData(data);
      } catch (err) {
        console.error('Error loading cart data:', err);
        setError('Error loading cart data');
      }
    };

    loadCartData();
  }, [cartId, navigate]);

  // Charger les données du client
  useEffect(() => {
    const loadClientData = async () => {
      if (!currentUser) return;

      try {
        const accountDoc = await getDoc(doc(db, 'accounts', currentUser.uid));
        if (accountDoc.exists()) {
          const data = accountDoc.data();
          setFormData({
            ...formData,
            email: data.contact?.email || '',
            contactName: data.contact?.name || '',
            phone: data.contact?.phone || '',
            companyName: data.company?.name || '',
            vatNumber: data.billing?.vatNumber || '',
            billingAddress: data.billing?.billingAddress || {
              line1: '',
              line2: '',
              city: '',
              postalCode: '',
              country: ''
            }
          });
        }
      } catch (err) {
        console.error('Error loading client data:', err);
        setError('Erreur lors du chargement des données client');
      }
    };

    loadClientData();
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      setCurrentStep('company');
    }
  }, [currentUser]);

  const handleAccountSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setError('Les mots de passe ne correspondent pas');
      return;
    }

    setLoading(true);
    try {
      // Ici, gérer la création du compte
      setCurrentStep('company');
      setError(null);
    } catch (err) {
      setError('Erreur lors de la création du compte');
    } finally {
      setLoading(false);
    }
  };

  const handleCompanySubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Ici, gérer la mise à jour des informations de l'entreprise
      setCurrentStep('payment');
      setError(null);
    } catch (err) {
      setError('Erreur lors de la mise à jour des informations');
    } finally {
      setLoading(false);
    }
  };

  const handleFormChange = (changes: Partial<typeof formData>) => {
    setFormData(prev => ({
      ...prev,
      ...changes
    }));
  };

  if (!currentUser && currentStep !== 'account') {
    navigate('/login');
    return null;
  }

  const steps: CheckoutStep[] = ['account', 'company', 'payment'];

  return (
    <div className="min-h-screen p-6">
      <div className="max-w-4xl mx-auto">
        <div className="glass-card rounded-2xl p-6">
          <div className="flex items-center justify-between mb-8">
            <h1 className="text-2xl font-medium text-white">Finaliser la commande</h1>
            <StepIndicator currentStep={currentStep} steps={steps} />
          </div>

          {message && (
            <div className="mb-6 p-4 bg-blue-500/10 border border-blue-500 rounded-lg text-blue-500">
              {message}
            </div>
          )}

          {error && (
            <div className="mb-6 p-4 bg-red-500/10 border border-red-500 rounded-lg text-red-500">
              {error}
            </div>
          )}

          {currentStep === 'account' && !currentUser && (
            <AccountForm
              formData={formData}
              onChange={handleFormChange}
              loading={loading}
              error={error}
              onSubmit={handleAccountSubmit}
            />
          )}

          {currentStep === 'company' && (
            <CompanyForm
              formData={formData}
              onChange={handleFormChange}
              loading={loading}
              error={error}
              onSubmit={handleCompanySubmit}
            />
          )}

          {currentStep === 'payment' && (
            <StripePayment
              email={currentUser?.email}
              loading={loading}
              onError={setError}
              onStartLoading={() => setLoading(true)}
              cartData={cartData}
            />
          )}
        </div>
      </div>
    </div>
  );
}