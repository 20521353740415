import axios from 'axios';

const API_BASE_URL = import.meta.env.DEV ? '/api' : 'https://api.pf.ripee.fr';

interface PriceResponse {
  yearly: {
    connect: number;
    production: number;
  };
  monthly: {
    connect: number;
    production: number;
  };
}

export const calculatePrices = async (machines: { code: string; quantity: number }[]): Promise<PriceResponse> => {
  try {
    // Transform array of objects into object with code-quantity pairs
    const machineData = machines.reduce((acc, { code, quantity }) => ({
      ...acc,
      [code]: quantity
    }), {});

    const response = await axios.post(`${API_BASE_URL}/prices`, machineData);
    
    if (!response.data) {
      throw new Error('Invalid API response');
    }

    // Ensure we have valid numbers and round them
    return {
      yearly: {
        connect: Math.round(Number(response.data.yearly?.connect) || 0),
        production: Math.round(Number(response.data.yearly?.production) || 0)
      },
      monthly: {
        connect: Math.round(Number(response.data.monthly?.connect) || 0),
        production: Math.round(Number(response.data.monthly?.production) || 0)
      }
    };
  } catch (error) {
    console.error('Error calculating prices:', error);
    // Return default values instead of throwing to prevent unhandled rejections
    return {
      yearly: {
        connect: 0,
        production: 0
      },
      monthly: {
        connect: 0,
        production: 0
      }
    };
  }
};