import React from 'react';
import { useNavigate } from 'react-router-dom';

interface ErrorStateProps {
  error: string;
}

export default function ErrorState({ error }: ErrorStateProps) {
  const navigate = useNavigate();

  return (
    <div className="p-4">
      <div className="glass-card p-8 rounded-2xl text-center">
        <h2 className="text-xl font-medium text-white mb-4">{error}</h2>
        <button
          onClick={() => navigate('/admin?tab=orders')}
          className="glow-button"
        >
          Retour
        </button>
      </div>
    </div>
  );
}