import React from "react";
import { Helmet } from "react-helmet-async";
import "./footer.css";

const Footer = () => {
  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.4.2/css/all.min.css"
        />
      </Helmet>
      <footer className="footer">
        <div className="os-compatibility">
          <p>PrintFactory est compatible</p>
          <div className="os-icons">
            <i className="fab fa-apple fa-2x"></i>
            <i className="fab fa-windows fa-2x"></i>
          </div>
        </div>
        <div className="footer-content">
          <div className="footer-section">
            <h3>À Propos de Ripee</h3>
            <p>Experts en gestion d'atelier depuis plus de 20 ans, nous assurons la distribution exclusive du logiciel PrintFactory en France.</p>
            <div className="social-links">
              <a href="#" aria-label="LinkedIn">
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
          </div>
          <div className="footer-section">
            <h3>Liens Rapides</h3>
            <ul className="footer-links">
              <li><a href="https://ripee.fr/explorez.html"><i className="fas fa-chevron-right"></i> Explorez</a></li>
              <li><a href="https://ripee.fr/services.html"><i className="fas fa-chevron-right"></i> Nos Services</a></li>
              <li><a href="https://app.ripee.fr/pricing"><i className="fas fa-chevron-right"></i> Tarifs</a></li>
              <li><a href="https://app.ripee.fr/support"><i className="fas fa-chevron-right"></i> Contact</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Contact</h3>
            <ul className="footer-links">
              <li><a href="mailto:contact@ripee.fr"><i className="fas fa-envelope"></i> contact@ripee.fr</a></li>
              <li><a href="#"><i className="fas fa-map-marker-alt"></i> Paris, France</a></li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 PrintFactory. Tous droits réservés.</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
