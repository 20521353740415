import axios from 'axios';
import { Machine } from '../types/machine';

// Utiliser le proxy Vite en développement
const API_BASE_URL = import.meta.env.DEV ? '/api' : 'https://api.pf.ripee.fr';

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

// Données de fallback en cas d'erreur API
const fallbackMachines: Machine[] = [
  {
    id: "D.41.25",
    name: "240tech 4c_HS_UVDTF",
    code: "D.41.25"
  }
];

export const getMachines = async (): Promise<Machine[]> => {
  try {
    const response = await apiClient.get('/drivers');
    
    if (!response.data || !Array.isArray(response.data)) {
      console.warn('Invalid API response format');
      return fallbackMachines;
    }

    return response.data.map((driver: any) => ({
      id: driver.Code || `machine-${Math.random().toString(36).substr(2, 9)}`,
      name: driver.Name || driver.name || 'Nom indisponible',
      code: driver.Code || driver.code || 'CODE_INCONNU'
    }));
  } catch (error) {
    console.error('Error fetching machines from Ripee API:', error);
    return fallbackMachines;
  }
};

export const getMachine = async (machineId: string): Promise<Machine | null> => {
  try {
    const machines = await getMachines();
    return machines.find(machine => machine.id === machineId) || null;
  } catch (error) {
    console.error('Error fetching machine:', error);
    return fallbackMachines.find(machine => machine.id === machineId) || null;
  }
};