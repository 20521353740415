import React, { useState, useEffect } from 'react';
import { Search } from 'lucide-react';
import { getMachines } from '../services/machineService';
import { Machine } from '../types/machine';
import { useCart } from '../contexts/CartContext';
import { useNavigate } from 'react-router-dom';
import { saveLicenseConfig, getLicenseConfig } from '../services/cartService';
import { v4 as uuidv4 } from 'uuid';
import StepHeader from '../components/pricing/StepHeader';
import PricingPlanSelector from '../components/pricing/PricingPlanSelector';
import MachineSearchList from '../components/pricing/MachineSearchList';
import CartSummary from '../components/pricing/CartSummary';
import BillingCycleSelector from '../components/pricing/BillingCycleSelector';
import SelectedMachinesList from '../components/pricing/SelectedMachinesList';
import LoadingOverlay from '../components/LoadingOverlay';
import '../styles/pricing.css';

export default function Pricing() {
  const [machines, setMachines] = useState<Machine[]>([]);
  const [filteredMachines, setFilteredMachines] = useState<Machine[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [selectedMachines, setSelectedMachines] = useState<Array<{
    id: string;
    name: string;
    code: string;
    quantity: number;
  }>>([]);

  const { 
    selectedPlan,
    setSelectedPlan,
    billingCycle,
    setBillingCycle,
    addItem,
    items
  } = useCart();
  
  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        setError(null);

        const machinesData = await getMachines();
        setMachines(machinesData);

        const subscriptionItem = items.find(item => item.configId);
        if (subscriptionItem?.configId) {
          console.log('Found existing config:', subscriptionItem.configId);
          const config = await getLicenseConfig(subscriptionItem.configId);
          if (config) {
            console.log('Restored config:', config);
            setSelectedMachines(config.machines);
            setSelectedPlan(config.selectedPlan);
            setBillingCycle(config.billingCycle);
          }
        }
      } catch (err) {
        console.error('Error loading data:', err);
        setError('Erreur lors du chargement des données');
      } finally {
        setLoading(false);
        setInitialLoadComplete(true);
      }
    };

    loadData();
  }, [setSelectedPlan, setBillingCycle, items]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setShowSearchResults(!!query.trim());

    if (!query.trim()) {
      setFilteredMachines([]);
      return;
    }

    const searchTerm = query.toLowerCase();
    const results = machines.filter(machine => 
      machine.name.toLowerCase().includes(searchTerm)
    );
    setFilteredMachines(results);
  };

  const handleAddMachine = (machine: Machine) => {
    setSelectedMachines(prev => [...prev, {
      id: machine.id,
      name: machine.name,
      code: machine.code,
      quantity: 1
    }]);
    setSearchQuery('');
    setShowSearchResults(false);
  };

  const handleRemoveMachine = (id: string) => {
    setSelectedMachines(prev => prev.filter(machine => machine.id !== id));
  };

  const handleUpdateQuantity = (id: string, quantity: number) => {
    setSelectedMachines(prev => prev.map(machine =>
      machine.id === id ? { ...machine, quantity } : machine
    ));
  };

  const handleCheckout = async () => {
    setIsProcessing(true);
    try {
      const configId = uuidv4();
      
      await saveLicenseConfig(configId, {
        machines: selectedMachines,
        selectedPlan,
        billingCycle,
        createdAt: new Date().toISOString()
      });

      await addItem({
        id: configId,
        name: `Plan ${selectedPlan === 'production' ? 'Production' : 'Connect'}`,
        code: configId,
        price: 0,
        plan: selectedPlan,
        quantity: 1,
        configId
      });

      navigate('/cart');
    } catch (error) {
      console.error('Checkout error:', error);
      setError('Une erreur est survenue lors de la commande');
    } finally {
      setIsProcessing(false);
    }
  };

  const canProceed = selectedMachines.length > 0;

  if (!initialLoadComplete) {
    return <LoadingOverlay message="Chargement de la configuration..." />;
  }

  return (
    <div className="pricing-container">
      <div className="pricing-content">
        <section className="pricing-section">
          <StepHeader number="1" title="Sélectionnez vos machines" />
          
          <div className="search-container">
            <div className="search-input-wrapper">
              <Search className="search-icon" />
              <input
                type="text"
                placeholder="Rechercher une machine..."
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
                className="search-input"
              />
              
              {showSearchResults && (
                <>
                  <div 
                    className="overlay"
                    onClick={() => setShowSearchResults(false)}
                  />
                  <div className="search-results">
                    <MachineSearchList
                      machines={filteredMachines}
                      loading={loading}
                      error={error}
                      selectedItems={selectedMachines}
                      onAdd={handleAddMachine}
                      onRemove={handleRemoveMachine}
                      onUpdateQuantity={handleUpdateQuantity}
                    />
                  </div>
                </>
              )}
            </div>

            <SelectedMachinesList
              items={selectedMachines}
              onUpdateQuantity={handleUpdateQuantity}
              onRemove={handleRemoveMachine}
            />
          </div>
        </section>

        <section className={`pricing-section ${!canProceed ? 'disabled' : ''}`}>
          <StepHeader number="2" title="Choisissez votre plan" />
          <PricingPlanSelector
            selectedPlan={selectedPlan}
            onPlanChange={setSelectedPlan}
          />
        </section>

        <section className={`pricing-section ${!canProceed ? 'disabled' : ''}`}>
          <StepHeader number="3" title="Cycle de facturation" />
          <BillingCycleSelector
            selectedCycle={billingCycle}
            onCycleChange={setBillingCycle}
            machines={selectedMachines.map(m => ({
              code: m.code,
              quantity: m.quantity
            }))}
            selectedPlan={selectedPlan}
          />
        </section>

        <section className={`pricing-section ${!canProceed ? 'disabled' : ''}`}>
          <StepHeader number="4" title="Revendeur" />
          <div className="cart-summary-container">
            <CartSummary
              items={selectedMachines}
              selectedPlan={selectedPlan}
              billingCycle={billingCycle}
              onCheckout={handleCheckout}
              isProcessing={isProcessing}
            />
          </div>
        </section>
      </div>
    </div>
  );
}