import React from 'react';
import { Minus, Plus, X, ShoppingBag } from 'lucide-react';
import { CartItemType } from '../../types/cart';

interface RegularItemsProps {
  items: CartItemType[];
  onUpdateQuantity: (id: string, quantity: number) => void;
  onRemoveItem: (id: string) => void;
}

export default function RegularItems({ items, onUpdateQuantity, onRemoveItem }: RegularItemsProps) {
  if (items.length === 0) return null;

  return (
    <div className="cart-section">
      <div className="cart-section-header">
        <ShoppingBag className="cart-section-icon" />
        <h2 className="cart-section-title">Autres produits</h2>
      </div>

      <div className="cart-items-grid">
        {items.map((item) => {
          const itemPrice = item.price || 0;
          const totalPrice = itemPrice * item.quantity;

          return (
            <div key={item.id} className="cart-regular-item">
              <div className="cart-regular-item-content">
                <div className="cart-regular-item-info">
                  <h3 className="cart-regular-item-title">{item.name}</h3>
                  <div className="cart-regular-item-price">{itemPrice.toFixed(2)}€ HT</div>
                </div>

                <div className="cart-regular-item-controls">
                  <div className="cart-regular-item-quantity">
                    <button
                      onClick={() => onUpdateQuantity(item.id, Math.max(1, item.quantity - 1))}
                      className="cart-quantity-button"
                      aria-label="Diminuer la quantité"
                    >
                      <Minus className="w-4 h-4" />
                    </button>
                    
                    <span className="cart-quantity-value">{item.quantity}</span>
                    
                    <button
                      onClick={() => onUpdateQuantity(item.id, item.quantity + 1)}
                      className="cart-quantity-button"
                      aria-label="Augmenter la quantité"
                    >
                      <Plus className="w-4 h-4" />
                    </button>
                  </div>

                  <div className="cart-regular-item-total">
                    <span className="cart-regular-item-total-label">Total HT</span>
                    <span className="cart-regular-item-total-value">{totalPrice.toFixed(2)}€</span>
                  </div>

                  <button
                    onClick={() => onRemoveItem(item.id)}
                    className="cart-remove-button"
                    aria-label="Supprimer l'article"
                  >
                    <X className="w-4 h-4" />
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}