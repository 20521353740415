import React, { useState, useEffect } from 'react';
import { Plus, X, AlertCircle } from 'lucide-react';
import { getResellers } from '../../services/resellerService';
import { createAffiliateCode, deleteAffiliateCode, getAffiliatesByReseller } from '../../services/affiliateService';
import { AffiliateCode } from '../../types/affiliate';

interface Reseller {
  id: string;
  companyName: string;
}

export default function AffiliateManagement() {
  const [resellers, setResellers] = useState<Reseller[]>([]);
  const [affiliates, setAffiliates] = useState<Record<string, AffiliateCode[]>>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedReseller, setSelectedReseller] = useState<string>('');
  const [newCode, setNewCode] = useState('');
  const [discountAmount, setDiscountAmount] = useState<number>(0);
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      const loadedResellers = await getResellers();
      setResellers(loadedResellers);

      const affiliatesData: Record<string, AffiliateCode[]> = {};
      for (const reseller of loadedResellers) {
        const resellerAffiliates = await getAffiliatesByReseller(reseller.id);
        affiliatesData[reseller.id] = resellerAffiliates;
      }
      setAffiliates(affiliatesData);
    } catch (err) {
      setError('Erreur lors du chargement des données');
      console.error('Error loading data:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateCode = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedReseller || !newCode.trim()) return;

    setIsCreating(true);
    try {
      await createAffiliateCode(selectedReseller, newCode.trim(), discountAmount);
      await loadData();
      setShowCreateModal(false);
      setNewCode('');
      setSelectedReseller('');
      setDiscountAmount(0);
    } catch (err) {
      setError('Erreur lors de la création du code');
    } finally {
      setIsCreating(false);
    }
  };

  const handleDeleteCode = async (code: string) => {
    try {
      await deleteAffiliateCode(code);
      await loadData();
    } catch (err) {
      setError('Erreur lors de la suppression du code');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center justify-between mb-8">
        <h2 className="text-2xl font-medium text-white">Gestion des codes d'affiliation</h2>
        <button
          onClick={() => setShowCreateModal(true)}
          className="glow-button flex items-center gap-2"
        >
          <Plus className="w-4 h-4" />
          Nouveau code
        </button>
      </div>

      {error && (
        <div className="mb-6 p-4 bg-red-500/10 border border-red-500 rounded-lg flex items-center gap-2 text-red-500">
          <AlertCircle className="w-5 h-5" />
          <p>{error}</p>
        </div>
      )}

      <div className="space-y-8">
        {resellers.map(reseller => (
          <div key={reseller.id} className="glass-card p-6 rounded-xl">
            <h3 className="text-lg font-medium text-white mb-4">{reseller.companyName}</h3>
            
            {affiliates[reseller.id]?.length > 0 ? (
              <div className="space-y-3">
                {affiliates[reseller.id].map(affiliate => (
                  <div
                    key={affiliate.code}
                    className="flex items-center justify-between p-4 bg-gray-800/50 rounded-lg"
                  >
                    <div>
                      <p className="text-white font-medium">{affiliate.code}</p>
                      {affiliate.discountAmount > 0 && (
                        <p className="text-sm text-gray-400">
                          Remise : {affiliate.discountAmount}€
                        </p>
                      )}
                      <p className="text-sm text-gray-400">
                        Créé le {new Date(affiliate.createdAt).toLocaleDateString('fr-FR')}
                      </p>
                    </div>
                    <button
                      onClick={() => handleDeleteCode(affiliate.code)}
                      className="p-2 text-red-400 hover:text-red-300 transition-colors"
                    >
                      <X className="w-5 h-5" />
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-400">Aucun code d'affiliation</p>
            )}
          </div>
        ))}
      </div>

      {showCreateModal && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
          <div className="glass-card w-full max-w-md rounded-2xl">
            <div className="p-6 border-b border-gray-700">
              <div className="flex items-center justify-between">
                <h3 className="text-xl font-medium text-white">Nouveau code d'affiliation</h3>
                <button
                  onClick={() => setShowCreateModal(false)}
                  className="text-gray-400 hover:text-white transition-colors"
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
            </div>

            <form onSubmit={handleCreateCode} className="p-6 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  Revendeur
                </label>
                <select
                  value={selectedReseller}
                  onChange={(e) => setSelectedReseller(e.target.value)}
                  required
                  className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                >
                  <option value="">Sélectionner un revendeur</option>
                  {resellers.map(reseller => (
                    <option key={reseller.id} value={reseller.id}>
                      {reseller.companyName}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  Code d'affiliation
                </label>
                <input
                  type="text"
                  value={newCode}
                  onChange={(e) => setNewCode(e.target.value)}
                  required
                  className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  placeholder="ex: RESELLER2024"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  Montant de la remise (€)
                </label>
                <input
                  type="number"
                  value={discountAmount}
                  onChange={(e) => setDiscountAmount(Math.max(0, Number(e.target.value)))}
                  min="0"
                  step="0.01"
                  className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
                <p className="mt-1 text-sm text-gray-400">
                  Laissez à 0 pour créer un code sans remise
                </p>
              </div>

              <div className="flex justify-end gap-3 pt-4">
                <button
                  type="button"
                  onClick={() => setShowCreateModal(false)}
                  className="px-4 py-2 text-gray-300 hover:text-white transition-colors"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  disabled={isCreating}
                  className="glow-button"
                >
                  {isCreating ? 'Création...' : 'Créer le code'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}