import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  getAuth 
} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

export const createCheckoutAccount = async (
  email: string,
  password: string,
  contactName: string,
  phone: string
) => {
  const auth = getAuth();
  
  try {
    // Créer le compte Firebase Auth
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const userId = userCredential.user.uid;

    // Créer le document dans la collection accounts
    const accountRef = doc(db, 'accounts', userId);
    await setDoc(accountRef, {
      role: 'client',
      status: 'active',
      contact: {
        name: contactName,
        email: email,
        phone: phone
      },
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    });

    return userId;
  } catch (error: any) {
    if (error.code === 'auth/email-already-in-use') {
      throw new Error('Cette adresse email est déjà utilisée');
    }
    throw error;
  }
};