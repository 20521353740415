import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import { Client } from '../../../types/client';
import CartDetails from './CartDetails';

interface LicensesTabProps {
  client: Client;
}

export default function LicensesTab({ client }: LicensesTabProps) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [cartData, setCartData] = useState<any>(null);

  useEffect(() => {
    const fetchCartData = async () => {
      try {
        setLoading(true);
        setError(null);

        console.log('Fetching cart data for client:', client);

        // Get account document to retrieve cartId
        const accountDoc = await getDoc(doc(db, 'accounts', client.id));
        if (!accountDoc.exists()) {
          console.log('Account not found for ID:', client.id);
          throw new Error('Account not found');
        }

        const accountData = accountDoc.data();
        console.log('Account data:', accountData);

        const cartId = accountData.cartId;
        console.log('Cart ID from account:', cartId);

        if (!cartId) {
          console.log('No cartId found in account document');
          throw new Error('No cart associated with this account');
        }

        // Get cart data using cartId
        const cartDoc = await getDoc(doc(db, 'carts', cartId));
        if (!cartDoc.exists()) {
          console.log('Cart document not found for ID:', cartId);
          throw new Error('Cart not found');
        }

        const data = cartDoc.data();
        console.log('Cart data retrieved:', data);

        // Format the data with proper date handling
        const formattedData = {
          ...data,
          createdAt: data.createdAt || new Date().toISOString(),
          subscriptionStartDate: accountData.billing?.subscriptionStartDate || data.createdAt || new Date().toISOString(),
          updatedAt: data.updatedAt || new Date().toISOString()
        };

        console.log('Formatted cart data:', formattedData);
        setCartData(formattedData);

      } catch (err) {
        console.error('Error fetching cart data:', err);
        setError('Erreur lors du chargement des données de commande');
      } finally {
        setLoading(false);
      }
    };

    fetchCartData();
  }, [client.id]);

  return (
    <CartDetails
      cartData={cartData}
      loading={loading}
      error={error}
    />
  );
}