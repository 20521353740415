import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Package, Calendar, CreditCard, Building2, ArrowLeft } from 'lucide-react';
import { cancelOrderSubscription } from '../../../services/orderService';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import LoadingState from './LoadingState';
import ErrorState from './ErrorState';
import GeneralInformation from './GeneralInformation';
import SubscriptionDetails from './SubscriptionDetails';
import PaymentDetails from './PaymentDetails';

interface OrderDetailsProps {
  orderId: string;
  onBack: () => void;
}

export default function OrderDetails({ orderId, onBack }: OrderDetailsProps) {
  const [order, setOrder] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showCancelModal, setShowCancelModal] = useState(false);

  useEffect(() => {
    const loadOrder = async () => {
      try {
        setLoading(true);
        setError(null);

        const orderRef = doc(db, 'orders', orderId);
        const orderDoc = await getDoc(orderRef);

        if (!orderDoc.exists()) {
          setError('Commande non trouvée');
          return;
        }

        // Ajouter l'ID de commande à l'objet subscription
        const orderData = orderDoc.data();
        if (orderData.subscription) {
          orderData.subscription.orderId = orderId;
        }

        setOrder({
          id: orderDoc.id,
          ...orderData
        });
      } catch (err) {
        console.error('Error loading order:', err);
        setError('Erreur lors du chargement de la commande');
      } finally {
        setLoading(false);
      }
    };

    loadOrder();
  }, [orderId]);

  const handleCancelSubscription = async () => {
    if (!order || !order.accountId) return;

    try {
      await cancelOrderSubscription(order.id, order.accountId);
      
      setOrder(prev => ({
        ...prev,
        subscription: {
          ...prev.subscription,
          status: 'cancelled',
          cancelledAt: new Date().toISOString()
        }
      }));

      setShowCancelModal(false);
    } catch (err) {
      console.error('Error canceling subscription:', err);
      throw new Error('Erreur lors de l\'annulation de l\'abonnement');
    }
  };

  if (loading) return <LoadingState />;
  if (error) return <ErrorState error={error} />;
  if (!order) return <ErrorState error="Commande non trouvée" />;

  const canCancelSubscription = order.status === 'paid' && 
    order.subscription?.status !== 'cancelled' &&
    order.subscription?.billingCycle;

  return (
    <div className="space-y-6">
      <button
        onClick={onBack}
        className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors"
      >
        <ArrowLeft className="w-5 h-5" />
        Retour aux commandes
      </button>

      <GeneralInformation order={order} />

      <SubscriptionDetails 
        subscription={order.subscription}
        onCancelSubscription={canCancelSubscription ? () => setShowCancelModal(true) : undefined}
      />

      <PaymentDetails order={order} />

      {showCancelModal && (
        <CancelSubscriptionModal
          isOpen={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          onConfirm={handleCancelSubscription}
          billingCycle={order.subscription.billingCycle}
        />
      )}
    </div>
  );
}