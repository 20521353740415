import React, { useState } from 'react';
import { CreditCard, Building2, Shield, Check } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

interface StripePaymentProps {
  email: string | null;
  loading: boolean;
  onError: (error: string) => void;
  onStartLoading: () => void;
  cartData: any;
}

export default function StripePayment({
  email,
  loading,
  onError,
  onStartLoading,
  cartData
}: StripePaymentProps) {
  const [selectedMethod, setSelectedMethod] = useState<'card' | 'sepa'>();
  const { currentUser } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!selectedMethod || !cartData) {
      onError('Veuillez sélectionner une méthode de paiement');
      return;
    }

    if (!currentUser?.uid) {
      onError('Utilisateur non authentifié');
      return;
    }

    onStartLoading();
    try {
      const response = await fetch('/.netlify/functions/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          cartData: {
            ...cartData,
            cartId: localStorage.getItem('cartId')
          },
          accountId: currentUser.uid,
          paymentMethod: selectedMethod
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Erreur lors de la création de la session de paiement');
      }

      const data = await response.json();
      if (!data?.url) {
        throw new Error('Réponse invalide du serveur de paiement');
      }

      window.location.href = data.url;
    } catch (error: any) {
      console.error('Error during checkout:', error);
      onError(error.message || 'Une erreur est survenue lors du paiement');
    }
  };

  return (
    <div className="glass-card rounded-xl p-8">
      <h2 className="text-2xl font-medium text-white mb-8 text-center">
        Choisissez votre méthode de paiement
      </h2>

      <form onSubmit={handleSubmit} className="space-y-8">
        <div className="grid grid-cols-2 gap-6">
          <button
            type="button"
            onClick={() => setSelectedMethod('card')}
            className={`
              group relative overflow-hidden rounded-xl transition-all duration-300 text-left h-full
              ${selectedMethod === 'card' 
                ? 'border-ripee bg-ripee-light' 
                : 'glass-card hover:border hover:border-[rgb(208_121_61_/_50%)]'
              }
            `}
          >
            {selectedMethod === 'card' && (
              <Check className="absolute top-0 left-0 w-5 h-5 text-ripee m-4" />
            )}
            
            <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-700">
              <div className="absolute inset-0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-1000 bg-gradient-to-r from-transparent via-white/10 to-transparent" />
            </div>
            
            <div className="h-full relative flex flex-col p-8">
              <div className="flex items-center gap-3">
                <div className={`p-2 rounded-lg ${
                  selectedMethod === 'card' 
                    ? 'bg-ripee text-white' 
                    : 'bg-ripee/10 text-ripee'
                }`}>
                  <CreditCard className="w-6 h-6" />
                </div>
                <div>
                  <span className={`font-medium text-lg ${
                    selectedMethod === 'card' ? 'text-ripee' : 'text-white'
                  }`}>Carte bancaire</span>
                  <p className="text-sm text-gray-400 mt-1">Paiement immédiat et sécurisé</p>
                </div>
              </div>
            </div>
          </button>

          <button
            type="button"
            onClick={() => setSelectedMethod('sepa')}
            className={`
              group relative overflow-hidden rounded-xl transition-all duration-300 text-left h-full
              ${selectedMethod === 'sepa' 
                ? 'border-ripee bg-ripee-light' 
                : 'glass-card hover:border hover:border-[rgb(208_121_61_/_50%)]'
              }
            `}
          >
            {selectedMethod === 'sepa' && (
              <Check className="absolute top-0 left-0 w-5 h-5 text-ripee m-4" />
            )}
            
            <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-700">
              <div className="absolute inset-0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-1000 bg-gradient-to-r from-transparent via-white/10 to-transparent" />
            </div>
            
            <div className="h-full relative flex flex-col p-8">
              <div className="flex items-center gap-3">
                <div className={`p-2 rounded-lg ${
                  selectedMethod === 'sepa' 
                    ? 'bg-ripee text-white' 
                    : 'bg-ripee/10 text-ripee'
                }`}>
                  <Building2 className="w-6 h-6" />
                </div>
                <div>
                  <span className={`font-medium text-lg ${
                    selectedMethod === 'sepa' ? 'text-ripee' : 'text-white'
                  }`}>Prélèvement SEPA</span>
                  <p className="text-sm text-gray-400 mt-1">Paiement automatique sécurisé</p>
                </div>
              </div>
            </div>
          </button>
        </div>

        <div className="glass-card p-6 rounded-xl flex items-center gap-4">
          <Shield className="w-8 h-8 text-green-500 shrink-0" />
          <div>
            <p className="text-white font-medium mb-1">Paiement 100% sécurisé</p>
            <p className="text-sm text-gray-300">
              Vos informations de paiement sont protégées par Stripe, leader mondial du paiement en ligne.
            </p>
          </div>
        </div>

        <button
          type="submit"
          disabled={!selectedMethod || loading}
          className="w-full glow-button !py-4 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {loading ? (
            <div className="flex items-center justify-center gap-2">
              <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
              <span>Traitement en cours...</span>
            </div>
          ) : (
            'Continuer vers le paiement'
          )}
        </button>
      </form>
    </div>
  );
}