import React from 'react';
import PaymentMethodSection from './PaymentMethodSection';
import InvoicesSection from './InvoicesSection';

interface BillingTabProps {
  accountData: any;
}

export default function BillingTab({ accountData }: BillingTabProps) {
  return (
    <div className="space-y-6">
      <PaymentMethodSection 
        customerId={accountData.id}
      />
      <InvoicesSection 
        customerId={accountData.stripeCustomerId}
      />
    </div>
  );
}