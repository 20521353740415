import React from 'react';
import { useNavigate } from 'react-router-dom';

interface ErrorStateProps {
  error: string;
}

export default function ErrorState({ error }: ErrorStateProps) {
  const navigate = useNavigate();

  return (
    <div className="glass-card rounded-2xl p-6 text-center">
      <h2 className="text-xl font-medium text-white mb-4">{error}</h2>
      <button
        onClick={() => navigate('/dashboard?tab=orders')}
        className="glow-button"
      >
        Retour aux commandes
      </button>
    </div>
  );
}