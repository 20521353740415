import React from 'react';
import { X, Plus, Minus } from 'lucide-react';
import { CartItemType } from '../../types/cart';

interface SelectedMachinesListProps {
  items: CartItemType[];
  onUpdateQuantity: (id: string, quantity: number) => void;
  onRemove: (id: string) => void;
}

export default function SelectedMachinesList({
  items,
  onUpdateQuantity,
  onRemove
}: SelectedMachinesListProps) {
  if (items.length === 0) return null;

  const handleQuantityChange = (e: React.MouseEvent | React.TouchEvent, id: string, newQuantity: number) => {
    e.preventDefault();
    e.stopPropagation();
    onUpdateQuantity(id, Math.max(1, newQuantity));
  };

  const handleRemove = (e: React.MouseEvent | React.TouchEvent, id: string) => {
    e.preventDefault();
    e.stopPropagation();
    onRemove(id);
  };

  return (
    <div className="mt-6 space-y-3">
      {items.map((item) => (
        <div
          key={item.id}
          className="glass-card p-4 rounded-xl flex flex-col sm:flex-row sm:items-center justify-between gap-4"
        >
          <h3 className="text-white font-medium">{item.name}</h3>
          
          <div className="flex items-center justify-between sm:justify-end gap-4">
            <div className="flex items-center gap-2">
              <button
                type="button"
                onClick={(e) => handleQuantityChange(e, item.id, item.quantity - 1)}
                onTouchEnd={(e) => handleQuantityChange(e, item.id, item.quantity - 1)}
                className="w-10 h-10 flex items-center justify-center rounded-lg bg-gray-800 text-white hover:bg-gray-700 active:bg-gray-600 transition-colors touch-manipulation"
                aria-label="Diminuer la quantité"
              >
                <Minus className="w-4 h-4" />
              </button>
              
              <input
                type="number"
                min="1"
                value={item.quantity}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  if (!isNaN(value) && value > 0) {
                    onUpdateQuantity(item.id, value);
                  }
                }}
                className="w-16 px-2 py-1 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-ripee/30 focus:border-transparent text-center"
              />
              
              <button
                type="button"
                onClick={(e) => handleQuantityChange(e, item.id, item.quantity + 1)}
                onTouchEnd={(e) => handleQuantityChange(e, item.id, item.quantity + 1)}
                className="w-10 h-10 flex items-center justify-center rounded-lg bg-gray-800 text-white hover:bg-gray-700 active:bg-gray-600 transition-colors touch-manipulation"
                aria-label="Augmenter la quantité"
              >
                <Plus className="w-4 h-4" />
              </button>
            </div>
            
            <button
              type="button"
              onClick={(e) => handleRemove(e, item.id)}
              onTouchEnd={(e) => handleRemove(e, item.id)}
              className="w-10 h-10 flex items-center justify-center rounded-lg text-red-400 hover:text-red-300 hover:bg-red-400/10 active:bg-red-400/20 transition-colors touch-manipulation"
              aria-label="Supprimer"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}