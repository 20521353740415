import React, { useState, useEffect } from 'react';
import { Package, Clock, CheckCircle, XCircle, Search, Loader2, Box, Archive } from 'lucide-react';
import { collection, getDocs, query, where, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';
import { OrderProcessingStatus, OrderStatus } from '../../types/order';

export default function OrdersManagement() {
  const [orders, setOrders] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState<OrderStatus | 'archived' | 'all'>('paid');
  const [orderCounts, setOrderCounts] = useState<{
    pending: number;
    cancelled: number;
    archived: number;
  }>({ pending: 0, cancelled: 0, archived: 0 });
  const navigate = useNavigate();

  // Calculate counters
  const calculateCounts = (orders: any[]) => {
    const counts = {
      pending: 0,
      cancelled: 0,
      archived: 0
    };

    orders.forEach(order => {
      if (order.processingStatus === 'pending_processing') {
        if (order.status === 'paid') {
          counts.pending++;
        } else if (order.status === 'cancelled') {
          counts.cancelled++;
        }
      }
      if (order.status === 'archived') {
        counts.archived++;
      }
    });

    return counts;
  };

  // Load orders and update counters
  useEffect(() => {
    setLoading(true);
    setError(null);

    const ordersRef = collection(db, 'orders');
    const unsubscribe = onSnapshot(
      query(ordersRef, where('status', 'in', ['paid', 'cancelled', 'archived'])),
      async (snapshot) => {
        try {
          const ordersData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          const ordersWithAccounts = await Promise.all(
            ordersData.map(async (order) => {
              if (order.accountId) {
                const accountRef = doc(db, 'accounts', order.accountId);
                const accountSnapshot = await getDoc(accountRef);
                
                if (accountSnapshot.exists()) {
                  const accountData = accountSnapshot.data();
                  return {
                    ...order,
                    companyName: accountData.company?.name,
                    contactName: accountData.contact?.name
                  };
                }
              }
              return order;
            })
          );

          setOrders(ordersWithAccounts);
          setOrderCounts(calculateCounts(ordersWithAccounts));
          setLoading(false);
        } catch (err) {
          console.error('Error processing orders:', err);
          setError('Erreur lors du chargement des commandes');
          setLoading(false);
        }
      },
      (err) => {
        console.error('Error listening to orders:', err);
        setError('Erreur lors de la surveillance des commandes');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  // Filter orders based on search and status
  const filteredOrders = orders.filter(order => {
    const matchesSearch = !searchQuery.trim() || [
      order.companyName,
      order.contactName,
      order.customerEmail,
      order.id
    ].some(field => field?.toLowerCase().includes(searchQuery.toLowerCase()));

    const matchesStatus = statusFilter === 'all' || order.status === statusFilter;

    return matchesSearch && matchesStatus;
  });

  // Helper functions for display
  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'paid':
        return <CheckCircle className="w-5 h-5 text-green-500" />;
      case 'cancelled':
        return <XCircle className="w-5 h-5 text-red-500" />;
      case 'archived':
        return <Archive className="w-5 h-5 text-blue-500" />;
      default:
        return <Clock className="w-5 h-5 text-yellow-500" />;
    }
  };

  const getProcessingStatusInfo = (status: OrderProcessingStatus) => {
    switch (status) {
      case 'pending_processing':
        return {
          icon: <Clock className="w-5 h-5" />,
          text: 'À traiter',
          className: 'bg-yellow-500/10 text-yellow-500'
        };
      case 'processing':
        return {
          icon: <Loader2 className="w-5 h-5 animate-spin" />,
          text: 'En cours',
          className: 'bg-blue-500/10 text-blue-500'
        };
      case 'processed':
        return {
          icon: <Box className="w-5 h-5" />,
          text: 'Traitée',
          className: 'bg-green-500/10 text-green-500'
        };
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'paid':
        return 'Payée';
      case 'cancelled':
        return 'Annulée';
      case 'archived':
        return 'Archivée';
      default:
        return 'En attente';
    }
  };

  const getStatusClass = (status: string) => {
    switch (status) {
      case 'paid':
        return 'bg-green-500/10 text-green-500';
      case 'cancelled':
        return 'bg-red-500/10 text-red-500';
      case 'archived':
        return 'bg-blue-500/10 text-blue-500';
      default:
        return 'bg-yellow-500/10 text-yellow-500';
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="flex items-center justify-between mb-8">
        <h2 className="text-2xl font-medium text-white">Gestion des commandes</h2>
        <div className="flex items-center gap-4">
          {/* Status filters */}
          <div className="flex items-center gap-2 bg-gray-800 rounded-lg p-1">
            <button
              onClick={() => setStatusFilter('all')}
              className={`px-3 py-1.5 rounded-md text-sm font-medium transition-colors ${
                statusFilter === 'all'
                  ? 'bg-blue-500 text-white'
                  : 'text-gray-400 hover:text-white'
              }`}
            >
              Toutes
            </button>
            <div className="relative">
              <button
                onClick={() => setStatusFilter('paid')}
                className={`px-3 py-1.5 rounded-md text-sm font-medium transition-colors ${
                  statusFilter === 'paid'
                    ? 'bg-green-500 text-white'
                    : 'text-gray-400 hover:text-white'
                }`}
              >
                Payées
                {orderCounts.pending > 0 && (
                  <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs w-5 h-5 rounded-full flex items-center justify-center">
                    {orderCounts.pending}
                  </span>
                )}
              </button>
            </div>
            <div className="relative">
              <button
                onClick={() => setStatusFilter('cancelled')}
                className={`px-3 py-1.5 rounded-md text-sm font-medium transition-colors ${
                  statusFilter === 'cancelled'
                    ? 'bg-red-500 text-white'
                    : 'text-gray-400 hover:text-white'
                }`}
              >
                Annulées
                {orderCounts.cancelled > 0 && (
                  <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs w-5 h-5 rounded-full flex items-center justify-center">
                    {orderCounts.cancelled}
                  </span>
                )}
              </button>
            </div>
            <div className="relative">
              <button
                onClick={() => setStatusFilter('archived')}
                className={`px-3 py-1.5 rounded-md text-sm font-medium transition-colors ${
                  statusFilter === 'archived'
                    ? 'bg-blue-500 text-white'
                    : 'text-gray-400 hover:text-white'
                }`}
              >
                Archivées
                {orderCounts.archived > 0 && (
                  <span className="absolute -top-1 -right-1 bg-blue-500 text-white text-xs w-5 h-5 rounded-full flex items-center justify-center">
                    {orderCounts.archived}
                  </span>
                )}
              </button>
            </div>
          </div>

          {/* Search */}
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              placeholder="Rechercher une commande..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="pl-10 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white w-64 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
        </div>
      </div>

      {error ? (
        <div className="p-4 bg-red-500/10 border border-red-500 rounded-lg text-red-500">
          {error}
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="text-left border-b border-gray-700">
                <th className="pb-3 text-gray-400 font-medium">Date</th>
                <th className="pb-3 text-gray-400 font-medium">Client</th>
                <th className="pb-3 text-gray-400 font-medium">Email</th>
                <th className="pb-3 text-gray-400 font-medium">Statut</th>
                <th className="pb-3 text-gray-400 font-medium">Traitement</th>
                <th className="pb-3 text-gray-400 font-medium">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders.map((order) => {
                const processingStatus = getProcessingStatusInfo(order.processingStatus || 'pending_processing');
                
                return (
                  <tr key={order.id} className="border-b border-gray-800">
                    <td className="py-4 text-white">
                      {format(new Date(order.createdAt), 'dd/MM/yyyy HH:mm', { locale: fr })}
                    </td>
                    <td className="py-4">
                      <div>
                        <p className="text-white">{order.companyName || 'N/A'}</p>
                        <p className="text-sm text-gray-400">{order.contactName}</p>
                      </div>
                    </td>
                    <td className="py-4 text-white">{order.customerEmail}</td>
                    <td className="py-4">
                      <span className={`inline-flex items-center gap-1.5 px-2.5 py-1 rounded-full text-xs font-medium ${getStatusClass(order.status)}`}>
                        {getStatusIcon(order.status)}
                        {getStatusText(order.status)}
                      </span>
                    </td>
                    <td className="py-4">
                      <span className={`inline-flex items-center gap-1.5 px-2.5 py-1 rounded-full text-xs font-medium ${processingStatus.className}`}>
                        {processingStatus.icon}
                        {processingStatus.text}
                      </span>
                    </td>
                    <td className="py-4">
                      <button
                        onClick={() => navigate(`/admin/orders/${order.id}`)}
                        className="text-gray-400 hover:text-white transition-colors"
                      >
                        Voir détails
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}