import { ClientFormData } from '../types/client';
import { calculatePrices } from '../services/priceService';
import { MONTHLY_RIPEE_FEE } from '../constants/pricing';

export const calculateMonthlyPrice = async (formData: ClientFormData): Promise<number> => {
  try {
    if (!formData.licenses || formData.licenses.length === 0) {
      return 0;
    }

    const machines = formData.licenses.map(license => ({
      code: license.machineId,
      quantity: license.quantity
    }));

    const prices = await calculatePrices(machines);
    const basePrice = Math.ceil(prices.monthly[formData.plan] / 100) + MONTHLY_RIPEE_FEE;
    
    return formData.billingCycle === 'annual' 
      ? Math.round(basePrice * 0.84) // 16% discount
      : basePrice;
  } catch (error) {
    console.error('Error calculating monthly price:', error);
    return 0;
  }
};

export const calculateAnnualPrice = async (formData: ClientFormData): Promise<number> => {
  try {
    if (!formData.licenses || formData.licenses.length === 0) {
      return 0;
    }

    const machines = formData.licenses.map(license => ({
      code: license.machineId,
      quantity: license.quantity
    }));

    const prices = await calculatePrices(machines);
    const monthlyBasePrice = Math.ceil(prices.monthly[formData.plan] / 100) + MONTHLY_RIPEE_FEE;
    const annualBasePrice = monthlyBasePrice * 12;
    
    return formData.billingCycle === 'annual'
      ? Math.round(annualBasePrice * 0.84) // 16% discount
      : annualBasePrice;
  } catch (error) {
    console.error('Error calculating annual price:', error);
    return 0;
  }
};

export const calculateAnnualSavings = async (formData: ClientFormData): Promise<number> => {
  try {
    const annualWithoutDiscount = await calculateAnnualPrice({ ...formData, billingCycle: 'monthly' });
    const annualWithDiscount = await calculateAnnualPrice({ ...formData, billingCycle: 'annual' });
    
    return Math.max(0, Math.round(annualWithoutDiscount - annualWithDiscount));
  } catch (error) {
    console.error('Error calculating annual savings:', error);
    return 0;
  }
};