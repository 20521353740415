import React, { useState, useEffect } from 'react';
import { Plus } from 'lucide-react';
import { useClientManagement } from '../../hooks/useClientManagement';
import { getMachines } from '../../services/machineService';
import { Machine } from '../../types/pricing';
import ClientModal from './client-modal/ClientModal';
import CreateClientModal from './CreateClientModal';
import DeleteClientModal from './DeleteClientModal';
import AlertModal from '../AlertModal';
import { Client, ClientFormData } from '../../types/client';
import ClientList from './ClientList';

export default function ClientManagement() {
  const {
    clients,
    loading: clientsLoading,
    error,
    setError,
    loadClients,
    createClient,
    updateClient,
    deleteClient
  } = useClientManagement();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [machines, setMachines] = useState<Machine[]>([]);
  const [machinesLoading, setMachinesLoading] = useState(true);

  useEffect(() => {
    const loadMachines = async () => {
      try {
        setMachinesLoading(true);
        const loadedMachines = await getMachines();
        setMachines(loadedMachines);
      } catch (err) {
        console.error('Error loading machines:', err);
        setError('Erreur lors du chargement des machines');
      } finally {
        setMachinesLoading(false);
      }
    };

    loadMachines();
  }, [setError]);

  useEffect(() => {
    loadClients();
  }, [loadClients]);

  const resellers = clients.filter(client => client.type === 'reseller');

  const handleCreateClient = async (data: ClientFormData) => {
    try {
      setIsCreating(true);
      setError(null);
      await createClient(data);
      await loadClients();
      setShowCreateModal(false);
      setError('Client créé avec succès');
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Erreur lors de la création du client');
      }
    } finally {
      setIsCreating(false);
    }
  };

  const handleUpdateClient = async (data: ClientFormData) => {
    if (!selectedClient) return;
    try {
      setError(null);
      await updateClient(selectedClient.id, data);
      await loadClients();
      setSelectedClient(null);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Erreur lors de la mise à jour du client');
      }
    }
  };

  const handleDeleteClient = async () => {
    if (!selectedClient) return;
    try {
      setError(null);
      await deleteClient(selectedClient.id);
      await loadClients();
      setShowDeleteModal(false);
      setSelectedClient(null);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Erreur lors de la suppression du client');
      }
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-8">
        <h2 className="text-2xl font-medium text-white">Gestion des clients</h2>
        <button
          onClick={() => setShowCreateModal(true)}
          disabled={isCreating}
          className="glow-button flex items-center gap-2"
        >
          {isCreating ? (
            <>
              <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
              Création...
            </>
          ) : (
            <>
              <Plus className="w-4 h-4" />
              Nouveau client
            </>
          )}
        </button>
      </div>

      <ClientList
        clients={clients}
        loading={clientsLoading}
        onEdit={setSelectedClient}
        onDelete={(client) => {
          setSelectedClient(client);
          setShowDeleteModal(true);
        }}
        searchQuery={searchQuery}
      />

      {showCreateModal && (
        <CreateClientModal
          isOpen={showCreateModal}
          onClose={() => setShowCreateModal(false)}
          onSubmit={handleCreateClient}
          isCreating={isCreating}
          machines={machines}
          loading={machinesLoading}
          resellers={resellers}
        />
      )}

      {selectedClient && !showDeleteModal && (
        <ClientModal
          isOpen={true}
          onClose={() => setSelectedClient(null)}
          onSubmit={handleUpdateClient}
          client={selectedClient}
          mode="edit"
          resellers={resellers}
        />
      )}

      {showDeleteModal && selectedClient && (
        <DeleteClientModal
          isOpen={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
            setSelectedClient(null);
          }}
          onConfirm={handleDeleteClient}
          clientName={selectedClient.companyName}
          clientId={selectedClient.id}
        />
      )}

      {error && (
        <AlertModal
          isOpen={!!error}
          onClose={() => setError(null)}
          title="Information"
          message={error}
        />
      )}
    </div>
  );
}