import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { CheckCircle, Package } from 'lucide-react';
import confetti from 'canvas-confetti';
import { getStripeSession } from '../services/stripeService';
import { useCart } from '../contexts/CartContext';
import LoadingOverlay from '../components/LoadingOverlay';

export default function OrderConfirmation() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [order, setOrder] = useState<any>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { clearCart } = useCart();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');

    if (!sessionId) {
      navigate('/');
      return;
    }

    const loadOrder = async () => {
      try {
        setLoading(true);
        
        // Rechercher la commande par sessionId
        const ordersRef = collection(db, 'orders');
        const q = query(ordersRef, where('stripeSessionId', '==', sessionId));
        const snapshot = await getDocs(q);

        if (snapshot.empty) {
          setError('Commande non trouvée');
          return;
        }

        const orderDoc = snapshot.docs[0];
        const orderData = {
          id: orderDoc.id,
          ...orderDoc.data()
        };

        // Vérifier le statut du paiement avec Stripe
        const session = await getStripeSession(sessionId);
        if (session?.payment_status === 'paid' && orderData.status !== 'paid') {
          // Mettre à jour le statut de la commande
          const orderRef = doc(db, 'orders', orderDoc.id);
          await updateDoc(orderRef, {
            status: 'paid',
            updatedAt: new Date().toISOString()
          });
          orderData.status = 'paid';

          // Nettoyer le panier et les données associées
          await clearCart();
          
          // Lancer l'animation de confettis
          confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 }
          });
        }

        setOrder(orderData);
      } catch (err) {
        console.error('Error loading order:', err);
        setError('Erreur lors du chargement de la commande');
      } finally {
        setLoading(false);
      }
    };

    loadOrder();
  }, [location.search, navigate, clearCart]);

  if (loading) {
    return <LoadingOverlay message="Chargement de votre commande..." />;
  }

  if (error) {
    return (
      <div className="flex items-center justify-center p-4">
        <div className="glass-card p-8 rounded-2xl text-center max-w-md">
          <div className="text-red-500 mb-4">
            <Package className="w-16 h-16 mx-auto" />
          </div>
          <h1 className="text-2xl font-medium text-white mb-4">Une erreur est survenue</h1>
          <p className="text-gray-400 mb-6">{error}</p>
          <button
            onClick={() => navigate('/')}
            className="glow-button"
          >
            Retour à l'accueil
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center p-4">
      <div className="glass-card p-8 rounded-2xl text-center max-w-lg">
        <div className="text-green-500 mb-6">
          <CheckCircle className="w-20 h-20 mx-auto" />
        </div>
        
        <h1 className="text-3xl font-medium text-white mb-4">
          {order.status === 'paid' ? 'Commande confirmée !' : 'Commande en attente de paiement'}
        </h1>
        
        <p className="text-gray-400 mb-8">
          Votre commande #{order?.id.slice(0, 8)} a été {order.status === 'paid' ? 'confirmée' : 'enregistrée'} avec succès. 
          {order.status === 'paid' && ' Vous recevrez un email de confirmation dans quelques instants.'}
        </p>

        {order?.subscription && (
          <div className="glass-card p-6 rounded-xl mb-8 text-left">
            <h3 className="text-lg font-medium text-white mb-4">Détails de l'abonnement</h3>
            <div className="space-y-2">
              <div className="flex justify-between">
                <span className="text-gray-400">Plan</span>
                <span className="text-white">
                  {order.subscription.plan === 'production' ? 'Production' : 'Connect'}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-400">Facturation</span>
                <span className="text-white">
                  {order.subscription.billingCycle === 'monthly' ? 'Mensuelle' : 'Annuelle'}
                </span>
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-col sm:flex-row gap-4 justify-center">
          <button
            onClick={() => navigate('/dashboard')}
            className="glow-button"
          >
            Accéder à mon compte
          </button>
          <button
            onClick={() => navigate('/')}
            className="px-6 py-3 rounded-xl text-gray-300 hover:text-white transition-colors"
          >
            Retour à l'accueil
          </button>
        </div>
      </div>
    </div>
  );
}