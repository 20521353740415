import React, { useState } from 'react';
import { AlertTriangle } from 'lucide-react';
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../config/firebase';

interface DeleteClientModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  clientName: string;
  clientId: string;
}

export default function DeleteClientModal({ isOpen, onClose, onConfirm, clientName, clientId }: DeleteClientModalProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleDelete = async () => {
    try {
      setLoading(true);
      setError(null);
      
      // Supprimer le document de la collection accounts
      const accountRef = doc(db, 'accounts', clientId);
      await deleteDoc(accountRef);
      
      onConfirm();
    } catch (err) {
      console.error('Error deleting client:', err);
      setError('Une erreur est survenue lors de la suppression');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="glass-card w-full max-w-md p-6 rounded-2xl">
        <div className="flex items-center gap-4 mb-4">
          <div className="w-12 h-12 rounded-full bg-red-500/10 flex items-center justify-center">
            <AlertTriangle className="w-6 h-6 text-red-500" />
          </div>
          <h2 className="text-xl font-medium text-white">Supprimer le client</h2>
        </div>

        <p className="text-gray-300 mb-6">
          Êtes-vous sûr de vouloir supprimer le compte de <strong className="text-white">{clientName}</strong> ? 
          Cette action est irréversible et supprimera toutes les données associées.
        </p>

        {error && (
          <div className="mb-6 p-4 bg-red-500/10 border border-red-500 rounded-lg text-red-500">
            {error}
          </div>
        )}

        <div className="flex justify-end gap-3">
          <button
            onClick={onClose}
            disabled={loading}
            className="px-4 py-2 text-gray-300 hover:text-white transition-colors disabled:opacity-50"
          >
            Annuler
          </button>
          <button
            onClick={handleDelete}
            disabled={loading}
            className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg transition-colors disabled:opacity-50 flex items-center gap-2"
          >
            {loading ? (
              <>
                <div className="w-4 h-4 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                Suppression...
              </>
            ) : (
              'Supprimer'
            )}
          </button>
        </div>
      </div>
    </div>
  );
}