import React, { useState, useEffect } from 'react';
import { Check, X, Send } from 'lucide-react';
import { verifyAffiliateCode } from '../../services/affiliateService';
import { useCart } from '../../contexts/CartContext';

interface CartSummaryProps {
  items: any[];
  selectedPlan: 'production' | 'connect';
  billingCycle: 'monthly' | 'annual';
  onCheckout: () => void;
  isProcessing: boolean;
}

export default function CartSummary({
  items,
  selectedPlan,
  billingCycle,
  onCheckout,
  isProcessing
}: CartSummaryProps) {
  const [affiliateCode, setAffiliateCode] = useState('');
  const [verifyingCode, setVerifyingCode] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isValidCode, setIsValidCode] = useState(false);
  const [discountAmount, setDiscountAmount] = useState<number>(0);
  
  const { setAffiliateCode: updateCartAffiliateCode } = useCart();

  const handleVerifyAffiliate = async () => {
    if (!affiliateCode.trim()) return;

    setVerifyingCode(true);
    setError(null);
    
    try {
      const result = await verifyAffiliateCode(affiliateCode);
      if (result.isValid) {
        await updateCartAffiliateCode(affiliateCode);
        setIsValidCode(true);
        setDiscountAmount(result.amount);
        setError(null);
      } else {
        setError('Code revendeur invalide');
        setIsValidCode(false);
        setDiscountAmount(0);
        await updateCartAffiliateCode('');
      }
    } catch (err) {
      setError('Erreur lors de la vérification du code');
      setIsValidCode(false);
      setDiscountAmount(0);
      await updateCartAffiliateCode('');
    } finally {
      setVerifyingCode(false);
    }
  };

  return (
    <div className="space-y-12">
      <div className="flex flex-col gap-2">
        <div className="flex flex-col sm:flex-row gap-2">
          <div className="relative flex-1">
            <input
              type="text"
              value={affiliateCode}
              onChange={(e) => {
                setAffiliateCode(e.target.value);
                setError(null);
                setIsValidCode(false);
              }}
              placeholder="Code revendeur (optionnel)"
              className={`w-full px-4 py-2 bg-gray-800 border rounded-lg text-white focus:ring-2 focus:ring-ripee focus:border-transparent pr-10 ${
                isValidCode ? 'border-green-500' : error ? 'border-red-500' : 'border-gray-700'
              }`}
            />
            {isValidCode && (
              <div className="absolute right-3 top-1/2 -translate-y-1/2">
                <div className="w-6 h-6 bg-green-500 rounded-full flex items-center justify-center">
                  <Check className="w-4 h-4 text-white" />
                </div>
              </div>
            )}
            {error && (
              <div className="absolute right-3 top-1/2 -translate-y-1/2">
                <div className="w-6 h-6 bg-red-500 rounded-full flex items-center justify-center">
                  <X className="w-4 h-4 text-white" />
                </div>
              </div>
            )}
          </div>
          <button
            onClick={handleVerifyAffiliate}
            disabled={!affiliateCode.trim() || verifyingCode}
            className="w-full sm:w-auto px-4 py-2 bg-ripee hover:bg-ripee/90 text-white rounded-lg font-medium transition-all disabled:opacity-50"
          >
            {verifyingCode ? 'Vérification...' : 'Vérifier'}
          </button>
        </div>
        
        {isValidCode && discountAmount > 0 && (
          <div className="text-sm text-green-400 flex items-center gap-2">
            <Check className="w-4 h-4" />
            Économie de {discountAmount}€ sur {billingCycle === 'monthly' ? 'le premier mois' : 'la première année'}
          </div>
        )}
        
        {error && (
          <p className="text-sm text-red-400 flex items-center gap-2">
            <X className="w-4 h-4" />
            {error}
          </p>
        )}
      </div>

      <button
        onClick={onCheckout}
        disabled={isProcessing || items.length === 0}
        className="w-full px-6 py-3 bg-ripee hover:bg-ripee/90 text-white rounded-xl font-medium transition-all flex items-center justify-center gap-2 group disabled:opacity-50"
      >
        {isProcessing ? (
          <>
            <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
            Traitement en cours...
          </>
        ) : (
          <>
            Ajouter au panier
            <Send className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
          </>
        )}
      </button>
    </div>
  );
}