import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { X, ArrowLeft, Mail, AlertCircle } from 'lucide-react';

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAuthSuccess: () => void;
}

type AuthMode = 'login' | 'signup' | 'reset';

export default function AuthModal({ isOpen, onClose, onAuthSuccess }: AuthModalProps) {
  const [mode, setMode] = useState<AuthMode>('login');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);
  
  const { login, signup, resetPassword } = useAuth();

  if (!isOpen) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    try {
      if (mode === 'login') {
        await login(email, password);
        onAuthSuccess();
        onClose();
      } else if (mode === 'signup') {
        await signup(email, password);
        onAuthSuccess();
        onClose();
      } else if (mode === 'reset') {
        await resetPassword(email);
        setResetEmailSent(true);
        setSuccess('Email de réinitialisation envoyé !');
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('Une erreur est survenue. Veuillez réessayer.');
      }
      console.error('Auth error:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderResetSuccess = () => (
    <div className="text-center p-6">
      <div className="w-16 h-16 bg-green-500 bg-opacity-10 rounded-full flex items-center justify-center mx-auto mb-4">
        <Mail className="w-8 h-8 text-green-500" />
      </div>
      <h3 className="text-xl font-semibold text-white mb-2">Email envoyé !</h3>
      <p className="text-gray-400 mb-6">
        Vérifiez votre boîte de réception et suivez les instructions pour réinitialiser votre mot de passe.
      </p>
      <button
        onClick={() => {
          setMode('login');
          setResetEmailSent(false);
          setSuccess('');
        }}
        className="glow-button w-full"
      >
        Retour à la connexion
      </button>
    </div>
  );

  const renderHeader = () => {
    if (mode === 'reset' && !resetEmailSent) {
      return (
        <div className="flex items-center gap-4 mb-6">
          <button
            onClick={() => setMode('login')}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <ArrowLeft size={24} />
          </button>
          <h2 className="text-2xl font-bold text-white">
            Réinitialiser le mot de passe
          </h2>
        </div>
      );
    }

    return (
      <h2 className="text-2xl font-bold text-white mb-6">
        {mode === 'login' ? 'Connexion' : 'Inscription'}
      </h2>
    );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="glass-card max-w-md w-full relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors"
        >
          <X size={24} />
        </button>

        <div className="p-8">
          {mode === 'reset' && resetEmailSent ? (
            renderResetSuccess()
          ) : (
            <>
              {renderHeader()}

              {error && (
                <div className="bg-red-500 bg-opacity-10 border border-red-500 text-red-500 px-4 py-3 rounded-lg mb-4 flex items-center gap-2">
                  <AlertCircle className="w-5 h-5 shrink-0" />
                  <p className="text-sm">{error}</p>
                </div>
              )}

              {success && !resetEmailSent && (
                <div className="bg-green-500 bg-opacity-10 border border-green-500 text-green-500 px-4 py-2 rounded-lg mb-4">
                  {success}
                </div>
              )}

              <form onSubmit={handleSubmit} className="space-y-4">
                {mode === 'signup' && (
                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-1">
                      Nom complet
                    </label>
                    <input
                      type="text"
                      value={displayName}
                      onChange={(e) => setDisplayName(e.target.value)}
                      className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    />
                  </div>
                )}

                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">
                    Email
                  </label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    required
                  />
                </div>

                {mode !== 'reset' && (
                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-1">
                      Mot de passe
                    </label>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    />
                  </div>
                )}

                <button
                  type="submit"
                  disabled={loading}
                  className="w-full glow-button !py-2"
                >
                  {loading ? 'Chargement...' : (
                    mode === 'login' ? 'Se connecter' :
                    mode === 'signup' ? 'S\'inscrire' :
                    'Envoyer le lien de réinitialisation'
                  )}
                </button>
              </form>

              <div className="mt-4 flex flex-col items-center gap-2 text-sm">
                {mode === 'login' && (
                  <>
                    <button
                      onClick={() => setMode('reset')}
                      className="text-blue-400 hover:text-blue-300"
                    >
                      Mot de passe oublié ?
                    </button>
                    <button
                      onClick={() => setMode('signup')}
                      className="text-blue-400 hover:text-blue-300"
                    >
                      Créer un compte
                    </button>
                  </>
                )}
                {mode === 'signup' && (
                  <button
                    onClick={() => setMode('login')}
                    className="text-blue-400 hover:text-blue-300"
                  >
                    Déjà inscrit ? Se connecter
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}