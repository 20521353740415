import React from 'react';
import { Building2, User, Mail, Phone, MapPin } from 'lucide-react';

interface ProfileTabProps {
  accountData: any;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  onSave: () => void;
  onUpdate: (field: string, value: any) => void;
}

export default function ProfileTab({ 
  accountData, 
  isEditing, 
  setIsEditing, 
  onSave,
  onUpdate 
}: ProfileTabProps) {
  return (
    <div className="glass-card rounded-2xl p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-lg font-medium text-white">Informations du compte</h2>
        <button
          onClick={() => isEditing ? onSave() : setIsEditing(true)}
          className="px-4 py-2 rounded-xl text-sm font-medium bg-[#3B82F6] text-white hover:bg-[#2563EB] transition-colors"
        >
          {isEditing ? 'Enregistrer' : 'Modifier'}
        </button>
      </div>

      <div className="grid gap-6">
        <div className="space-y-4">
          <div className="flex items-center gap-3 text-gray-300">
            <Building2 size={20} className="text-[#3B82F6]" />
            <input
              type="text"
              value={accountData.company?.name || ''}
              onChange={(e) => onUpdate('company.name', e.target.value)}
              disabled={!isEditing}
              className="bg-transparent border-b border-gray-700 focus:border-[#3B82F6] px-2 py-1 w-full disabled:opacity-50"
              placeholder="Nom de l'entreprise"
            />
          </div>

          <div className="flex items-center gap-3 text-gray-300">
            <User size={20} className="text-[#3B82F6]" />
            <input
              type="text"
              value={accountData.contact?.name || ''}
              onChange={(e) => onUpdate('contact.name', e.target.value)}
              disabled={!isEditing}
              className="bg-transparent border-b border-gray-700 focus:border-[#3B82F6] px-2 py-1 w-full disabled:opacity-50"
              placeholder="Nom complet"
            />
          </div>

          <div className="flex items-center gap-3 text-gray-300">
            <Mail size={20} className="text-[#3B82F6]" />
            <input
              type="email"
              value={accountData.contact?.email || ''}
              disabled={true}
              className="bg-transparent border-b border-gray-700 px-2 py-1 w-full opacity-50"
              placeholder="Email"
            />
          </div>

          <div className="flex items-center gap-3 text-gray-300">
            <Phone size={20} className="text-[#3B82F6]" />
            <input
              type="tel"
              value={accountData.contact?.phone || ''}
              onChange={(e) => onUpdate('contact.phone', e.target.value)}
              disabled={!isEditing}
              className="bg-transparent border-b border-gray-700 focus:border-[#3B82F6] px-2 py-1 w-full disabled:opacity-50"
              placeholder="Téléphone"
            />
          </div>

          <div className="flex items-start gap-3 text-gray-300">
            <MapPin size={20} className="text-[#3B82F6] mt-2" />
            <div className="flex-1 space-y-3">
              <input
                type="text"
                value={accountData.billing?.billingAddress?.line1 || ''}
                onChange={(e) => onUpdate('billing.billingAddress.line1', e.target.value)}
                disabled={!isEditing}
                className="bg-transparent border-b border-gray-700 focus:border-[#3B82F6] px-2 py-1 w-full disabled:opacity-50"
                placeholder="Adresse ligne 1"
              />
              <input
                type="text"
                value={accountData.billing?.billingAddress?.line2 || ''}
                onChange={(e) => onUpdate('billing.billingAddress.line2', e.target.value)}
                disabled={!isEditing}
                className="bg-transparent border-b border-gray-700 focus:border-[#3B82F6] px-2 py-1 w-full disabled:opacity-50"
                placeholder="Adresse ligne 2"
              />
              <div className="grid grid-cols-2 gap-4">
                <input
                  type="text"
                  value={accountData.billing?.billingAddress?.city || ''}
                  onChange={(e) => onUpdate('billing.billingAddress.city', e.target.value)}
                  disabled={!isEditing}
                  className="bg-transparent border-b border-gray-700 focus:border-[#3B82F6] px-2 py-1 w-full disabled:opacity-50"
                  placeholder="Ville"
                />
                <input
                  type="text"
                  value={accountData.billing?.billingAddress?.postalCode || ''}
                  onChange={(e) => onUpdate('billing.billingAddress.postalCode', e.target.value)}
                  disabled={!isEditing}
                  className="bg-transparent border-b border-gray-700 focus:border-[#3B82F6] px-2 py-1 w-full disabled:opacity-50"
                  placeholder="Code postal"
                />
              </div>
              <input
                type="text"
                value={accountData.billing?.billingAddress?.country || ''}
                onChange={(e) => onUpdate('billing.billingAddress.country', e.target.value)}
                disabled={!isEditing}
                className="bg-transparent border-b border-gray-700 focus:border-[#3B82F6] px-2 py-1 w-full disabled:opacity-50"
                placeholder="Pays"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}