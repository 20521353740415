import React from 'react';
import MachinesList from './cart-sections/MachinesList';
import SubscriptionDetails from './cart-sections/SubscriptionDetails';
import PricingDetails from './cart-sections/PricingDetails';

interface CartData {
  items: Array<{
    name: string;
    quantity: number;
  }>;
  selectedPlan: 'production' | 'connect';
  billingCycle: 'monthly' | 'annual';
  createdAt: string;
  subscriptionStartDate?: string;
  updatedAt: string;
  pricing: {
    firstPeriodPrice: number;
    regularPrice: number;
    affiliateDiscount?: {
      code: string;
      amount: number;
    };
  };
}

interface CartDetailsProps {
  cartData: CartData | null;
  loading: boolean;
  error: string | null;
}

export default function CartDetails({ cartData, loading, error }: CartDetailsProps) {
  if (loading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 bg-red-500/10 border border-red-500 rounded-lg text-red-500">
        {error}
      </div>
    );
  }

  if (!cartData) {
    return (
      <div className="text-center text-gray-400 py-8">
        Aucune donnée de panier disponible
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <MachinesList items={cartData.items} />
      <SubscriptionDetails
        selectedPlan={cartData.selectedPlan}
        billingCycle={cartData.billingCycle}
        createdAt={cartData.createdAt}
        subscriptionStartDate={cartData.subscriptionStartDate}
      />
      <PricingDetails
        pricing={cartData.pricing}
        billingCycle={cartData.billingCycle}
      />
    </div>
  );
}