import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { AlertCircle, Mail, Lock, ArrowRight } from 'lucide-react';
import styles from '../styles/Login.module.css';

export default function Login() {
  const [mode, setMode] = useState<'login' | 'signup' | 'reset'>('login');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);

  const { currentUser, login, signup, resetPassword } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    try {
      if (mode === 'login') {
        await login(email, password);
        navigate('/dashboard');
      } else if (mode === 'signup') {
        await signup(email, password);
        navigate('/dashboard');
      } else if (mode === 'reset') {
        await resetPassword(email);
        setResetEmailSent(true);
        setSuccess('Email de réinitialisation envoyé !');
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('Une erreur est survenue. Veuillez réessayer.');
      }
      console.error('Auth error:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderResetSuccess = () => (
    <div className={styles.resetSuccess}>
      <div className={styles.resetIcon}>
        <Mail />
      </div>
      <h3 className={styles.title}>Email envoyé !</h3>
      <p className="text-gray-400 mb-6">
        Vérifiez votre boîte de réception et suivez les instructions pour réinitialiser votre mot de passe.
      </p>
      <button
        onClick={() => {
          setMode('login');
          setResetEmailSent(false);
          setSuccess('');
        }}
        className={styles.submitButton}
      >
        Retour à la connexion
      </button>
    </div>
  );

  return (
    <div className={styles.loginContainer}>
      <div className={styles.formContainer}>
        <div className={styles.card}>
          {mode === 'reset' && resetEmailSent ? (
            renderResetSuccess()
          ) : (
            <>
              <h2 className={styles.title}>
                {mode === 'login' ? 'Connexion' : 
                 mode === 'signup' ? 'Inscription' : 
                 'Réinitialiser le mot de passe'}
              </h2>

              {error && (
                <div className={styles.error}>
                  <AlertCircle className="shrink-0" />
                  <p className="text-sm">{error}</p>
                </div>
              )}

              {success && (
                <div className={styles.success}>
                  {success}
                </div>
              )}

              <form onSubmit={handleSubmit}>
                {mode === 'signup' && (
                  <div className={styles.inputGroup}>
                    <label className={styles.label}>
                      Nom complet
                    </label>
                    <div className={styles.inputWrapper}>
                      <input
                        type="text"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                        className={styles.input}
                        required
                      />
                    </div>
                  </div>
                )}

                <div className={styles.inputGroup}>
                  <label className={styles.label}>
                    Email
                  </label>
                  <div className={styles.inputWrapper}>
                    <Mail className={styles.icon} />
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className={styles.input}
                      required
                    />
                  </div>
                </div>

                {mode !== 'reset' && (
                  <div className={styles.inputGroup}>
                    <label className={styles.label}>
                      Mot de passe
                    </label>
                    <div className={styles.inputWrapper}>
                      <Lock className={styles.icon} />
                      <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className={styles.input}
                        required
                      />
                    </div>
                  </div>
                )}

                <button
                  type="submit"
                  disabled={loading}
                  className={styles.submitButton}
                >
                  {loading ? (
                    <>
                      <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                      Chargement...
                    </>
                  ) : (
                    <>
                      {mode === 'login' ? 'Se connecter' :
                       mode === 'signup' ? 'S\'inscrire' :
                       'Envoyer le lien de réinitialisation'}
                      <ArrowRight className="group-hover:translate-x-1 transition-transform" />
                    </>
                  )}
                </button>
              </form>

              <div className={styles.linksContainer}>
                {mode === 'login' && (
                  <>
                    <button
                      onClick={() => setMode('reset')}
                      className={styles.linkButton}
                    >
                      Mot de passe oublié ?
                    </button>
                    <button
                      onClick={() => setMode('signup')}
                      className={styles.linkButton}
                    >
                      Créer un compte
                    </button>
                  </>
                )}
                {mode === 'signup' && (
                  <button
                    onClick={() => setMode('login')}
                    className={styles.linkButton}
                  >
                    Déjà inscrit ? Se connecter
                  </button>
                )}
                {mode === 'reset' && (
                  <button
                    onClick={() => setMode('login')}
                    className={styles.linkButton}
                  >
                    Retour à la connexion
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}