import React, { useState } from 'react';
import { AlertTriangle, Loader2 } from 'lucide-react';

interface CancelSubscriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  billingCycle: 'monthly' | 'annual';
}

export default function CancelSubscriptionModal({
  isOpen,
  onClose,
  onConfirm,
  billingCycle
}: CancelSubscriptionModalProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleConfirm = async () => {
    try {
      setLoading(true);
      setError(null);
      await onConfirm();
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Une erreur est survenue');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="glass-card w-full max-w-md p-6 rounded-2xl">
        <div className="flex items-center gap-4 mb-4">
          <div className="w-12 h-12 rounded-full bg-red-500/10 flex items-center justify-center">
            <AlertTriangle className="w-6 h-6 text-red-500" />
          </div>
          <h2 className="text-xl font-medium text-white">Annuler l'abonnement</h2>
        </div>

        <div className="space-y-4">
          <p className="text-gray-300">
            Êtes-vous sûr de vouloir annuler votre abonnement ?
          </p>

          <div className="p-4 bg-yellow-500/10 border border-yellow-500 rounded-lg">
            <p className="text-yellow-500 text-sm">
              <strong>Important :</strong> La période {billingCycle === 'monthly' ? 'mensuelle' : 'annuelle'} en cours sera facturée intégralement.
              L'accès aux services sera maintenu jusqu'à la fin de la période en cours.
            </p>
          </div>

          {error && (
            <div className="p-4 bg-red-500/10 border border-red-500 rounded-lg text-red-500 text-sm">
              {error}
            </div>
          )}

          <div className="flex justify-end gap-3 pt-4">
            <button
              onClick={onClose}
              disabled={loading}
              className="px-4 py-2 text-gray-300 hover:text-white transition-colors disabled:opacity-50"
            >
              Annuler
            </button>
            <button
              onClick={handleConfirm}
              disabled={loading}
              className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg transition-colors disabled:opacity-50 flex items-center gap-2"
            >
              {loading ? (
                <>
                  <Loader2 className="w-4 h-4 animate-spin" />
                  Annulation...
                </>
              ) : (
                'Confirmer l\'annulation'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}