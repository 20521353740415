import React, { useState, useCallback, useMemo } from 'react';
import { Plus, Search, X } from 'lucide-react';
import { Machine } from '../../types/pricing';

interface MachineSelectorProps {
  machines: Machine[];
  onSelect: (machine: Machine, quantity: number) => void;
  loading?: boolean;
  buttonText?: string;
  buttonClassName?: string;
  existingMachines?: { machineId: string; quantity: number }[];
}

export default function MachineSelector({
  machines = [],
  onSelect,
  loading = false,
  buttonText = "Ajouter une machine",
  buttonClassName = "glow-button flex items-center gap-2",
  existingMachines = []
}: MachineSelectorProps) {
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedMachine, setSelectedMachine] = useState<Machine | null>(null);
  const [quantity, setQuantity] = useState(1);

  // Filtrer les machines en fonction de la recherche
  const filteredMachines = useMemo(() => {
    const query = searchQuery.toLowerCase().trim();
    if (!query) return [];
    
    return machines.filter(machine => {
      const nameMatch = machine.name?.toLowerCase().includes(query);
      const codeMatch = machine.code?.toLowerCase().includes(query);
      return nameMatch || codeMatch;
    });
  }, [machines, searchQuery]);

  const handleOpenModal = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowModal(true);
    setSearchQuery('');
    setSelectedMachine(null);
    setQuantity(1);
  }, []);

  const handleCloseModal = useCallback((e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setShowModal(false);
    setSearchQuery('');
    setSelectedMachine(null);
    setQuantity(1);
  }, []);

  const handleSelectMachine = useCallback((e: React.MouseEvent, machine: Machine) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedMachine(machine);
    setQuantity(1);
  }, []);

  const handleConfirmSelection = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedMachine && quantity > 0) {
      onSelect(selectedMachine, quantity);
      handleCloseModal();
    }
  }, [selectedMachine, quantity, onSelect, handleCloseModal]);

  if (!showModal) {
    return (
      <button
        onClick={handleOpenModal}
        className={buttonClassName}
        disabled={loading}
      >
        <Plus className="w-4 h-4" />
        {buttonText}
      </button>
    );
  }

  return (
    <div 
      className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-[100] p-4"
      onClick={handleCloseModal}
    >
      <div 
        className="glass-card w-full max-w-2xl rounded-2xl flex flex-col max-h-[85vh]"
        onClick={e => e.stopPropagation()}
      >
        <div className="p-6 border-b border-gray-700">
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-xl font-medium text-white">Sélectionner une machine</h3>
            <button
              onClick={handleCloseModal}
              className="text-gray-400 hover:text-white transition-colors"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
            <input
              type="text"
              placeholder="Rechercher une machine..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-3 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              autoFocus
            />
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-6">
          {loading ? (
            <div className="flex items-center justify-center py-8">
              <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
            </div>
          ) : !searchQuery.trim() ? (
            <div className="text-center py-8 text-gray-400">
              Commencez à taper pour rechercher une machine
            </div>
          ) : filteredMachines.length === 0 ? (
            <div className="text-center py-8 text-gray-400">
              Aucune machine trouvée
            </div>
          ) : (
            <div className="grid gap-2">
              {filteredMachines.map((machine) => {
                const isExisting = existingMachines.some(m => m.machineId === machine.id);
                return (
                  <button
                    key={machine.id}
                    onClick={(e) => handleSelectMachine(e, machine)}
                    className={`w-full p-4 rounded-xl text-left transition-all duration-200 ${
                      selectedMachine?.id === machine.id
                        ? 'bg-blue-500/20 border border-blue-500/30'
                        : 'glass-card hover:bg-white/5'
                    }`}
                  >
                    <div className="flex items-center justify-between">
                      <div>
                        <h4 className="font-medium text-white">{machine.name}</h4>
                        {machine.code && (
                          <p className="text-sm text-gray-400">Code: {machine.code}</p>
                        )}
                      </div>
                      {isExisting && (
                        <span className="text-sm text-blue-400">
                          Déjà ajoutée
                        </span>
                      )}
                    </div>
                  </button>
                );
              })}
            </div>
          )}
        </div>

        {selectedMachine && (
          <div className="p-6 border-t border-gray-700 bg-gray-800/50">
            <div className="flex flex-col space-y-4">
              <div className="flex items-center justify-between">
                <div className="text-sm font-medium text-white">
                  {selectedMachine.name}
                </div>
                <div className="flex items-center gap-2">
                  <button
                    type="button"
                    onClick={() => setQuantity(Math.max(1, quantity - 1))}
                    className="w-8 h-8 flex items-center justify-center rounded-lg bg-gray-700 text-white hover:bg-gray-600 transition-colors"
                  >
                    -
                  </button>
                  <input
                    type="number"
                    min="1"
                    value={quantity}
                    onChange={(e) => {
                      const value = parseInt(e.target.value);
                      if (!isNaN(value) && value > 0) {
                        setQuantity(value);
                      }
                    }}
                    className="w-16 px-2 py-1 bg-gray-700 border border-gray-600 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent text-center"
                  />
                  <button
                    type="button"
                    onClick={() => setQuantity(quantity + 1)}
                    className="w-8 h-8 flex items-center justify-center rounded-lg bg-gray-700 text-white hover:bg-gray-600 transition-colors"
                  >
                    +
                  </button>
                </div>
              </div>

              <button
                type="button"
                onClick={handleConfirmSelection}
                className="glow-button w-full !py-2"
              >
                Ajouter la machine
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}