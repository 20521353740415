import React, { useState, useEffect } from 'react';
import { X, ArrowLeft, ArrowRight, AlertCircle } from 'lucide-react';
import { ClientFormData } from '../../types/client';
import { Machine } from '../../types/pricing';
import MachineSelector from './MachineSelector';
import { calculateMonthlyPrice, calculateAnnualPrice, calculateAnnualSavings } from '../../utils/priceCalculator';

interface CreateClientModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: ClientFormData) => Promise<void>;
  machines: Machine[];
  loading?: boolean;
  isCreating: boolean;
  resellers: any[];
}

export default function CreateClientModal({
  isOpen,
  onClose,
  onSubmit,
  machines,
  loading = false,
  isCreating,
  resellers
}: CreateClientModalProps) {
  const [currentStep, setCurrentStep] = useState<'profile' | 'licenses' | 'plan'>('profile');
  const [error, setError] = useState<string | null>(null);
  const [processingSteps, setProcessingSteps] = useState<string[]>([]);
  const [currentProcessStep, setCurrentProcessStep] = useState<string>('');

  const [formData, setFormData] = useState<ClientFormData>({
    companyName: '',
    contactName: '',
    email: '',
    phone: '',
    type: 'end-user',
    plan: 'production',
    billingCycle: 'monthly',
    address: {
      line1: '',
      line2: '',
      city: '',
      postalCode: '',
      country: ''
    },
    vatNumber: '',
    licenses: [],
    resellerId: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (currentStep !== 'plan') {
      return;
    }

    try {
      setError(null);
      setProcessingSteps([
        'Création du compte utilisateur...',
        'Configuration des licences...',
        'Envoi de l\'email d\'activation...',
        'Finalisation...'
      ]);
      
      setCurrentProcessStep('Création du compte utilisateur...');
      await onSubmit(formData);
      
      setCurrentProcessStep('Finalisation...');
      onClose();
      
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Une erreur est survenue';
      setError(errorMessage);
      
      if (errorMessage.toLowerCase().includes('email')) {
        setCurrentStep('profile');
      }
      
      setProcessingSteps([]);
      setCurrentProcessStep('');
    }
  };

  const handleAddMachine = (machine: Machine, quantity: number) => {
    setFormData(prev => ({
      ...prev,
      licenses: [
        ...prev.licenses,
        {
          machineId: machine.id,
          machineName: machine.name,
          quantity
        }
      ]
    }));
  };

  const handleRemoveMachine = (machineId: string) => {
    setFormData(prev => ({
      ...prev,
      licenses: prev.licenses.filter(license => license.machineId !== machineId)
    }));
  };

  const handleUpdateQuantity = (machineId: string, quantity: number) => {
    setFormData(prev => ({
      ...prev,
      licenses: prev.licenses.map(license => 
        license.machineId === machineId 
          ? { ...license, quantity }
          : license
      )
    }));
  };

  const canProceedToLicenses = () => {
    if (!formData.companyName || !formData.contactName || !formData.email) {
      return false;
    }
    
    if (formData.type === 'client-reseller' && !formData.resellerId) {
      return false;
    }
    
    return true;
  };

  const canProceedToPlan = () => {
    // Pour les revendeurs, les licences sont optionnelles
    if (formData.type === 'reseller') {
      return true;
    }
    // Pour les autres types, au moins une licence est requise
    return formData.licenses.length > 0;
  };

  const handleNext = (e: React.FormEvent) => {
    e.preventDefault();
    if (currentStep === 'profile' && canProceedToLicenses()) {
      setCurrentStep('licenses');
    } else if (currentStep === 'licenses' && canProceedToPlan()) {
      setCurrentStep('plan');
    }
  };

  const handleBack = () => {
    if (currentStep === 'licenses') {
      setCurrentStep('profile');
    } else if (currentStep === 'plan') {
      setCurrentStep('licenses');
    }
  };

  if (!isOpen) return null;

  if (currentProcessStep) {
    return (
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
        <div className="glass-card w-full max-w-md p-8 rounded-2xl">
          <div className="text-center">
            <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin mx-auto mb-6"></div>
            <h3 className="text-xl font-medium text-white mb-2">Création en cours...</h3>
            <p className="text-gray-400">{currentProcessStep}</p>
            
            <div className="mt-8 space-y-2">
              {processingSteps.map((step, index) => (
                <div
                  key={step}
                  className={`flex items-center gap-2 ${
                    step === currentProcessStep ? 'text-white' : 'text-gray-500'
                  }`}
                >
                  <div className={`w-2 h-2 rounded-full ${
                    step === currentProcessStep ? 'bg-blue-500' : 'bg-gray-700'
                  }`}></div>
                  <span>{step}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const monthlyPrice = calculateMonthlyPrice(formData);
  const annualPrice = calculateAnnualPrice(formData);
  const annualSavings = calculateAnnualSavings(formData);

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="glass-card w-full max-w-2xl rounded-2xl flex flex-col">
        <div className="p-6 border-b border-gray-700 flex-shrink-0">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-medium text-white">Nouveau client</h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-white transition-colors"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          <div className="flex items-center gap-2 mt-6">
            <div className={`w-1/3 h-2 rounded-full ${
              currentStep === 'profile' ? 'bg-blue-500' : 'bg-blue-500/50'
            }`} />
            <div className={`w-1/3 h-2 rounded-full ${
              currentStep === 'licenses' ? 'bg-blue-500' : 'bg-blue-500/50'
            }`} />
            <div className={`w-1/3 h-2 rounded-full ${
              currentStep === 'plan' ? 'bg-blue-500' : 'bg-blue-500/50'
            }`} />
          </div>
        </div>

        <div className="p-6">
          <form id="createClientForm" onSubmit={handleSubmit} className="space-y-6">
            {currentStep === 'profile' && (
              <div className="space-y-6">
                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-1">
                      Type de compte
                    </label>
                    <select
                      value={formData.type}
                      onChange={(e) => setFormData(prev => ({
                        ...prev,
                        type: e.target.value as 'end-user' | 'reseller' | 'client-reseller',
                        resellerId: e.target.value === 'client-reseller' ? prev.resellerId : ''
                      }))}
                      className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    >
                      <option value="end-user">Client direct</option>
                      <option value="reseller">Revendeur</option>
                      <option value="client-reseller">Client via revendeur</option>
                    </select>
                  </div>

                  {formData.type === 'client-reseller' && (
                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-1">
                        Revendeur
                      </label>
                      <select
                        value={formData.resellerId}
                        onChange={(e) => setFormData(prev => ({
                          ...prev,
                          resellerId: e.target.value
                        }))}
                        className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        required={formData.type === 'client-reseller'}
                      >
                        <option value="">Sélectionner un revendeur</option>
                        {resellers.map(reseller => (
                          <option key={reseller.id} value={reseller.id}>
                            {reseller.companyName}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-1">
                      Nom de l'entreprise
                    </label>
                    <input
                      type="text"
                      value={formData.companyName}
                      onChange={(e) => setFormData(prev => ({
                        ...prev,
                        companyName: e.target.value
                      }))}
                      className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-1">
                      Nom du contact
                    </label>
                    <input
                      type="text"
                      value={formData.contactName}
                      onChange={(e) => setFormData(prev => ({
                        ...prev,
                        contactName: e.target.value
                      }))}
                      className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-1">
                      Email
                    </label>
                    <input
                      type="email"
                      value={formData.email}
                      onChange={(e) => setFormData(prev => ({
                        ...prev,
                        email: e.target.value
                      }))}
                      className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-1">
                      Téléphone
                    </label>
                    <input
                      type="tel"
                      value={formData.phone}
                      onChange={(e) => setFormData(prev => ({
                        ...prev,
                        phone: e.target.value
                      }))}
                      className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-1">
                      Numéro de TVA
                    </label>
                    <input
                      type="text"
                      value={formData.vatNumber}
                      onChange={(e) => setFormData(prev => ({
                        ...prev,
                        vatNumber: e.target.value
                      }))}
                      className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">
                    Adresse
                  </label>
                  <div className="space-y-4">
                    <input
                      type="text"
                      placeholder="Ligne 1"
                      value={formData.address.line1}
                      onChange={(e) => setFormData(prev => ({
                        ...prev,
                        address: {
                          ...prev.address,
                          line1: e.target.value
                        }
                      }))}
                      className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                    <input
                      type="text"
                      placeholder="Ligne 2 (optionnel)"
                      value={formData.address.line2}
                      onChange={(e) => setFormData(prev => ({
                        ...prev,
                        address: {
                          ...prev.address,
                          line2: e.target.value
                        }
                      }))}
                      className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                    <div className="grid grid-cols-3 gap-4">
                      <input
                        type="text"
                        placeholder="Ville"
                        value={formData.address.city}
                        onChange={(e) => setFormData(prev => ({
                          ...prev,
                          address: {
                            ...prev.address,
                            city: e.target.value
                          }
                        }))}
                        className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      />
                      <input
                        type="text"
                        placeholder="Code postal"
                        value={formData.address.postalCode}
                        onChange={(e) => setFormData(prev => ({
                          ...prev,
                          address: {
                            ...prev.address,
                            postalCode: e.target.value
                          }
                        }))}
                        className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      />
                      <input
                        type="text"
                        placeholder="Pays"
                        value={formData.address.country}
                        onChange={(e) => setFormData(prev => ({
                          ...prev,
                          address: {
                            ...prev.address,
                            country: e.target.value
                          }
                        }))}
                        className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {currentStep === 'licenses' && (
              <div className="space-y-6">
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-medium text-white">Machines</h3>
                  <MachineSelector
                    machines={machines}
                    onSelect={handleAddMachine}
                    loading={loading}
                  />
                </div>

                {formData.licenses.length === 0 ? (
                  <div className="text-center py-8">
                    <p className="text-gray-400">
                      {formData.type === 'reseller' 
                        ? 'Vous pouvez ajouter des machines ou passer à l\'étape suivante'
                        : 'Aucune machine sélectionnée'}
                    </p>
                  </div>
                ) : (
                  <div className="space-y-4">
                    {formData.licenses.map((license) => (
                      <div
                        key={license.machineId}
                        className="glass-card p-4 rounded-xl flex items-center justify-between"
                      >
                        <div>
                          <h4 className="font-medium text-white">{license.machineName}</h4>
                        </div>
                        <div className="flex items-center gap-4">
                          <div className="flex items-center gap-2">
                            <button
                              type="button"
                              onClick={() => handleUpdateQuantity(
                                license.machineId,
                                Math.max(1, license.quantity - 1)
                              )}
                              className="w-8 h-8 flex items-center justify-center rounded-lg bg-gray-700 text-white hover:bg-gray-600 transition-colors"
                            >
                              -
                            </button>
                            <input
                              type="number"
                              min="1"
                              value={license.quantity}
                              onChange={(e) => {
                                const value = parseInt(e.target.value);
                                if (!isNaN(value) && value > 0) {
                                  handleUpdateQuantity(license.machineId, value);
                                }
                              }}
                              className="w-16 px-2 py-1 bg-gray-700 border border-gray-600 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent text-center"
                            />
                            <button
                              type="button"
                              onClick={() => handleUpdateQuantity(
                                license.machineId,
                                license.quantity + 1
                              )}
                              className="w-8 h-8 flex items-center justify-center rounded-lg bg-gray-700 text-white hover:bg-gray-600 transition-colors"
                            >
                              +
                            </button>
                          </div>
                          <button
                            type="button"
                            onClick={() => handleRemoveMachine(license.machineId)}
                            className="p-2 text-red-400 hover:text-red-300 transition-colors"
                          >
                            <X className="w-5 h-5" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}

            {currentStep === 'plan' && formData.type !== 'reseller' && (
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">
                    Plan
                  </label>
                  <select
                    value={formData.plan}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      plan: e.target.value as 'production' | 'connect'
                    }))}
                    className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  >
                    <option value="production">Production</option>
                    <option value="connect">Connect</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">
                    Cycle de facturation
                  </label>
                  <select
                    value={formData.billingCycle}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      billingCycle: e.target.value as 'monthly' | 'annual'
                    }))}
                    className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  >
                    <option value="monthly">Mensuel</option>
                    <option value="annual">Annuel (-16%)</option>
                  </select>
                </div>

                <div className="bg-gray-800/50 rounded-xl p-4 space-y-2">
                  <div className="flex justify-between items-center">
                    <span className="text-gray-400">Prix mensuel :</span>
                    <span className="text-white font-medium">{monthlyPrice.toFixed(2)}€</span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-gray-400">Prix annuel :</span>
                    <div className="text-right">
                      <span className="text-white font-medium">{annualPrice.toFixed(2)}€</span>
                      {formData.billingCycle === 'annual' && annualSavings > 0 && (
                        <span className="text-xs text-green-400 ml-2">
                          Économie de {annualSavings.toFixed(2)}€
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>

        <div className="p-6 border-t border-gray-700 flex-shrink-0">
          {error && (
            <div className="mb-4 p-4 bg-red-500/10 border border-red-500 rounded-lg flex items-center gap-2 text-red-500">
              <AlertCircle className="w-5 h-5 shrink-0" />
              <p>{error}</p>
            </div>
          )}

          <div className="flex justify-between gap-3">
            <button
              type="button"
              onClick={currentStep === 'profile' ? onClose : handleBack}
              className="px-4 py-2 text-gray-300 hover:text-white transition-colors flex items-center gap-2"
              disabled={isCreating}
            >
              <ArrowLeft className="w-4 h-4" />
              {currentStep === 'profile' ? 'Annuler' : 'Retour'}
            </button>

            {currentStep === 'plan' ? (
              <button
                type="submit"
                form="createClientForm"
                disabled={isCreating}
                className="glow-button flex items-center gap-2"
              >
                {isCreating ? (
                  <>
                    <div className="w-4 h-4 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                    Création...
                  </>
                ) : (
                  <>
                    Créer le client
                    <ArrowRight className="w-4 h-4" />
                  </>
                )}
              </button>
            ) : (
              <button
                type="button"
                onClick={handleNext}
                disabled={
                  isCreating ||
                  (currentStep === 'profile' && !canProceedToLicenses()) ||
                  (currentStep === 'licenses' && !canProceedToPlan())
                }
                className="glow-button flex items-center gap-2"
              >
                Suivant
                <ArrowRight className="w-4 h-4" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}