import React from 'react';
import { Calendar } from 'lucide-react';
import { format, isValid, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';

interface SubscriptionDetailsProps {
  selectedPlan: 'production' | 'connect';
  billingCycle: 'monthly' | 'annual';
  createdAt: string;
  subscriptionStartDate?: string;
}

export default function SubscriptionDetails({ 
  selectedPlan, 
  billingCycle, 
  createdAt,
  subscriptionStartDate 
}: SubscriptionDetailsProps) {
  const formatDate = (dateString: string) => {
    try {
      console.log('Formatting date:', dateString);
      
      // Try to parse the ISO string first
      const date = parseISO(dateString);
      
      if (!isValid(date)) {
        console.error('Invalid date:', dateString);
        return 'Date invalide';
      }
      
      const formattedDate = format(date, 'dd MMMM yyyy', { locale: fr });
      console.log('Formatted date:', formattedDate);
      return formattedDate;
    } catch (error) {
      console.error('Error formatting date:', error, dateString);
      return 'Date invalide';
    }
  };

  const getNextRenewalDate = (startDateString: string) => {
    try {
      console.log('Calculating next renewal from:', startDateString);
      
      const startDate = parseISO(startDateString);
      if (!isValid(startDate)) {
        console.error('Invalid start date for renewal:', startDateString);
        return 'Date invalide';
      }

      const nextDate = new Date(startDate);
      if (billingCycle === 'monthly') {
        nextDate.setMonth(nextDate.getMonth() + 1);
      } else {
        nextDate.setFullYear(nextDate.getFullYear() + 1);
      }

      const formattedNextDate = format(nextDate, 'dd MMMM yyyy', { locale: fr });
      console.log('Next renewal date:', formattedNextDate);
      return formattedNextDate;
    } catch (error) {
      console.error('Error calculating next renewal date:', error, startDateString);
      return 'Date invalide';
    }
  };

  const startDate = subscriptionStartDate || createdAt;
  console.log('Using start date:', startDate);

  return (
    <div className="glass-card p-4 rounded-xl">
      <h3 className="text-lg font-medium text-white mb-4 flex items-center gap-2">
        <Calendar className="w-5 h-5 text-blue-500" />
        Détails de l'abonnement
      </h3>
      <div className="space-y-3">
        <div className="flex justify-between items-center">
          <span className="text-gray-400">Plan</span>
          <span className="text-white font-medium">
            {selectedPlan === 'production' ? 'Production' : 'Connect'}
          </span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-gray-400">Cycle de facturation</span>
          <span className="text-white font-medium">
            {billingCycle === 'monthly' ? 'Mensuel' : 'Annuel'}
          </span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-gray-400">Date de souscription</span>
          <span className="text-white font-medium">
            {formatDate(startDate)}
          </span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-gray-400">Prochain renouvellement</span>
          <span className="text-white font-medium">
            {getNextRenewalDate(startDate)}
          </span>
        </div>
      </div>
    </div>
  );
}