import React from 'react';
import { Loader2, CheckCircle, AlertCircle } from 'lucide-react';
import { OrderProcessingStatus } from '../../../types/order';

const PROCESSING_STATUS_OPTIONS = [
  { value: 'pending_processing', label: 'À traiter' },
  { value: 'processing', label: 'En cours' },
  { value: 'processed', label: 'Traitée' }
] as const;

interface ProcessingStatusProps {
  processingStatus: OrderProcessingStatus;
  processingNotes: string;
  isUpdating: boolean;
  showSuccess: boolean;
  error: string | null;
  onStatusChange: (status: OrderProcessingStatus) => void;
  onNotesChange: (notes: string) => void;
  onUpdate: () => void;
}

export default function ProcessingStatus({
  processingStatus,
  processingNotes,
  isUpdating,
  showSuccess,
  error,
  onStatusChange,
  onNotesChange,
  onUpdate
}: ProcessingStatusProps) {
  return (
    <div className="glass-card rounded-2xl p-4">
      <div className="flex flex-wrap gap-4 items-center">
        <div className="flex-1 min-w-[200px]">
          <select
            value={processingStatus}
            onChange={(e) => onStatusChange(e.target.value as OrderProcessingStatus)}
            className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white text-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            {PROCESSING_STATUS_OPTIONS.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="flex-[2] min-w-[300px]">
          <input
            type="text"
            value={processingNotes}
            onChange={(e) => onNotesChange(e.target.value)}
            placeholder="Notes de traitement..."
            className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white text-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
        <div className="flex items-center gap-2">
          <button
            onClick={onUpdate}
            disabled={isUpdating}
            className="glow-button !py-2 !px-4 text-sm whitespace-nowrap"
          >
            {isUpdating ? (
              <>
                <Loader2 className="w-4 h-4 animate-spin" />
                Mise à jour...
              </>
            ) : (
              'Mettre à jour'
            )}
          </button>
          {showSuccess && (
            <div className="flex items-center gap-1 text-green-400 text-sm">
              <CheckCircle className="w-4 h-4" />
              <span>Mis à jour</span>
            </div>
          )}
        </div>
      </div>

      {error && (
        <div className="mt-4 p-3 bg-red-500/10 border border-red-500 rounded-lg flex items-center gap-2 text-red-500 text-sm">
          <AlertCircle className="w-4 h-4 shrink-0" />
          <p>{error}</p>
        </div>
      )}
    </div>
  );
}