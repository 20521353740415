import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useAuth } from './AuthContext';
import { CartItemType } from '../types/cart';
import { v4 as uuidv4 } from 'uuid';
import { saveCart, getCart, deleteCart, getLicenseConfig, deleteLicenseConfig } from '../services/cartService';
import { verifyAffiliateCode } from '../services/affiliateService';

interface CartContextType {
  items: CartItemType[];
  addItem: (item: CartItemType) => Promise<void>;
  removeItem: (id: string) => Promise<void>;
  updateQuantity: (id: string, quantity: number) => Promise<void>;
  clearCart: () => Promise<void>;
  selectedPlan: 'production' | 'connect';
  setSelectedPlan: (plan: 'production' | 'connect') => void;
  billingCycle: 'monthly' | 'annual';
  setBillingCycle: (cycle: 'monthly' | 'annual') => void;
  affiliateCode: string;
  setAffiliateCode: (code: string) => Promise<void>;
  refreshCart: () => Promise<void>;
}

const CartContext = createContext<CartContextType | null>(null);

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};

export function CartProvider({ children }: { children: React.ReactNode }) {
  const [items, setItems] = useState<CartItemType[]>([]);
  const [cartId] = useState(() => localStorage.getItem('cartId') || uuidv4());
  const [selectedPlan, setSelectedPlan] = useState<'production' | 'connect'>('production');
  const [billingCycle, setBillingCycle] = useState<'monthly' | 'annual'>('monthly');
  const [affiliateCode, setAffiliateCodeState] = useState('');
  const { currentUser } = useAuth();

  useEffect(() => {
    localStorage.setItem('cartId', cartId);
  }, [cartId]);

  const refreshCart = useCallback(async () => {
    const cartId = localStorage.getItem('cartId');
    if (!cartId) {
      setItems([]);
      return;
    }

    try {
      const cartData = await getCart(cartId);
      if (cartData) {
        const loadedItems = [];
        for (const item of cartData.items || []) {
          if (item.configId) {
            const config = await getLicenseConfig(item.configId);
            if (config) {
              loadedItems.push({
                ...item,
                name: `Plan ${config.selectedPlan === 'production' ? 'Production' : 'Connect'}`,
                plan: config.selectedPlan
              });
            }
          } else {
            loadedItems.push(item);
          }
        }
        setItems(loadedItems);
        if (cartData.selectedPlan) setSelectedPlan(cartData.selectedPlan);
        if (cartData.billingCycle) setBillingCycle(cartData.billingCycle);
        if (cartData.affiliateCode) setAffiliateCodeState(cartData.affiliateCode);
      } else {
        setItems([]);
      }
    } catch (error) {
      console.error('Error refreshing cart:', error);
      setItems([]);
    }
  }, []);

  const syncWithFirebase = useCallback(async (newItems: CartItemType[], newAffiliateCode?: string) => {
    if (!cartId) return;

    try {
      let affiliateAmount;
      if (newAffiliateCode) {
        const result = await verifyAffiliateCode(newAffiliateCode);
        if (result.isValid) {
          affiliateAmount = result.amount;
        }
      }

      await saveCart(cartId, {
        items: newItems,
        userId: currentUser?.uid || null,
        selectedPlan,
        billingCycle,
        affiliateCode: newAffiliateCode,
        affiliateAmount,
        updatedAt: new Date().toISOString()
      });
    } catch (error) {
      console.error('Error syncing with Firebase:', error);
    }
  }, [cartId, currentUser, selectedPlan, billingCycle]);

  const setAffiliateCode = useCallback(async (code: string) => {
    setAffiliateCodeState(code);
    await syncWithFirebase(items, code);
  }, [items, syncWithFirebase]);

  const addItem = useCallback(async (newItem: CartItemType) => {
    const newItems = [...items];
    
    if (newItem.configId) {
      const existingConfig = newItems.find(item => item.configId);
      if (existingConfig) {
        await deleteLicenseConfig(existingConfig.configId!);
      }
      const filteredItems = newItems.filter(item => !item.configId);
      filteredItems.push(newItem);
      setItems(filteredItems);
      await syncWithFirebase(filteredItems, affiliateCode);
    } else {
      const existingItem = newItems.find(item => item.id === newItem.id);
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        newItems.push(newItem);
      }
      setItems(newItems);
      await syncWithFirebase(newItems, affiliateCode);
    }
  }, [items, syncWithFirebase, affiliateCode]);

  const removeItem = useCallback(async (id: string) => {
    const itemToRemove = items.find(item => item.id === id);
    if (itemToRemove?.configId) {
      await deleteLicenseConfig(itemToRemove.configId);
    }

    const newItems = items.filter(item => item.id !== id);
    setItems(newItems);
    await syncWithFirebase(newItems, affiliateCode);
  }, [items, syncWithFirebase, affiliateCode]);

  const updateQuantity = useCallback(async (id: string, quantity: number) => {
    const newItems = items.map(item =>
      item.id === id ? { ...item, quantity } : item
    );
    setItems(newItems);
    await syncWithFirebase(newItems, affiliateCode);
  }, [items, syncWithFirebase, affiliateCode]);

  const clearCart = useCallback(async () => {
    // Supprimer toutes les config_licence
    await Promise.all(
      items
        .filter(item => item.configId)
        .map(item => deleteLicenseConfig(item.configId!))
    );

    setItems([]);
    setAffiliateCodeState('');
    if (cartId) {
      try {
        await deleteCart(cartId);
        localStorage.removeItem('cartId');
      } catch (error) {
        console.error('Error clearing cart:', error);
      }
    }
  }, [cartId, items]);

  // Initial cart load
  useEffect(() => {
    refreshCart();
  }, [refreshCart]);

  return (
    <CartContext.Provider value={{
      items,
      addItem,
      removeItem,
      updateQuantity,
      clearCart,
      selectedPlan,
      setSelectedPlan,
      billingCycle,
      setBillingCycle,
      affiliateCode,
      setAffiliateCode,
      refreshCart
    }}>
      {children}
    </CartContext.Provider>
  );
}