import React from 'react';
import { Factory } from 'lucide-react';

interface StepHeaderProps {
  number: string;
  title: string;
}

export default function StepHeader({ number, title }: StepHeaderProps) {
  return (
    <div className="flex flex-col items-center text-center mb-12">
      {number === "1" && (
        <Factory className="w-16 h-16 text-ripee mb-6" />
      )}
      <div className="flex flex-col sm:flex-row items-center gap-3 mb-2">
        <div className="w-10 h-10 shrink-0 rounded-full bg-ripee flex items-center justify-center text-xl font-medium text-white">
          {number}
        </div>
        <h2 className="text-xl sm:text-3xl font-medium text-white">
          {title}
        </h2>
      </div>
      <div className="w-20 h-px bg-gradient-to-r from-transparent via-ripee to-transparent mt-4" />
    </div>
  );
}