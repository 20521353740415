import React from 'react';
import { ArrowRight, User } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

interface IndexProps {
  onOpenAuth?: () => void;
}

export default function Index({ onOpenAuth }: IndexProps) {
  const { currentUser } = useAuth();

  const handleAccountClick = (e: React.MouseEvent) => {
    if (!currentUser && onOpenAuth) {
      e.preventDefault();
      onOpenAuth();
    }
  };

  return (
    <div className="min-h-screen p-4 md:p-6">
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-end mb-4">
          <Link 
            to={currentUser ? "/dashboard" : "#"}
            onClick={handleAccountClick}
            className="flex items-center gap-2 px-4 py-2 rounded-full text-sm font-medium bg-[rgba(30,30,30,0.8)] text-white backdrop-blur-sm border border-white/5 transition-all duration-300 hover:bg-white/10"
          >
            <User size={18} className="text-[#3B82F6]" />
            <span>Mon compte</span>
          </Link>
        </div>

        <div className="flex flex-col items-center text-center mb-16">
          <img 
            src="/src/assets/pf.jpg" 
            alt="PrintFactory Logo" 
            className="w-[400px] mb-8"
          />
          <h1 className="text-4xl md:text-6xl font-bold text-white mb-6">
            Automatisez votre atelier
          </h1>
          <p className="text-xl text-gray-400 max-w-2xl mb-8">
            Optimisez votre production d'impression avec notre solution complète de gestion d'atelier
          </p>
          <Link 
            to="/pricing" 
            className="glow-button flex items-center gap-2 group w-[400px]"
          >
            <span>Découvrir nos tarifs</span>
            <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
          </Link>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          <div className="glass-card p-6 rounded-xl text-center">
            <h3 className="text-xl font-medium text-white mb-4">
              Gestion simplifiée
            </h3>
            <p className="text-gray-400">
              Gérez vos commandes, vos machines et votre production depuis une seule interface
            </p>
          </div>

          <div className="glass-card p-6 rounded-xl text-center">
            <h3 className="text-xl font-medium text-white mb-4">
              Automatisation
            </h3>
            <p className="text-gray-400">
              Automatisez vos tâches répétitives et optimisez votre flux de production
            </p>
          </div>

          <div className="glass-card p-6 rounded-xl text-center">
            <h3 className="text-xl font-medium text-white mb-4">
              Support expert
            </h3>
            <p className="text-gray-400">
              Bénéficiez d'un support technique dédié et d'une formation personnalisée
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}