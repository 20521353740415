import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CheckCircle, AlertCircle } from 'lucide-react';
import { verifyOrderPayment } from '../services/orderService';
import LoadingOverlay from '../components/LoadingOverlay';

export default function SubscriptionUpdateSuccess() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { orderId } = location.state || {};

  useEffect(() => {
    if (!orderId) {
      navigate('/dashboard');
      return;
    }

    const verifyPayment = async () => {
      try {
        setLoading(true);
        setError(null);

        const { isValid, error } = await verifyOrderPayment(orderId);
        
        if (!isValid) {
          throw new Error(error || 'Erreur lors de la vérification du paiement');
        }

      } catch (err) {
        console.error('Error verifying payment:', err);
        setError(err instanceof Error ? err.message : 'Une erreur est survenue');
      } finally {
        setLoading(false);
      }
    };

    verifyPayment();
  }, [orderId, navigate]);

  if (loading) {
    return <LoadingOverlay message="Vérification du paiement..." />;
  }

  if (error) {
    return (
      <div className="flex items-center justify-center p-4">
        <div className="glass-card p-8 rounded-2xl text-center max-w-lg">
          <div className="text-red-500 mb-6">
            <AlertCircle className="w-20 h-20 mx-auto" />
          </div>
          
          <h1 className="text-3xl font-medium text-white mb-4">
            Une erreur est survenue
          </h1>
          
          <p className="text-gray-400 mb-8">{error}</p>

          <button
            onClick={() => navigate('/dashboard')}
            className="glow-button"
          >
            Retour au tableau de bord
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center p-4">
      <div className="glass-card p-8 rounded-2xl text-center max-w-lg">
        <div className="text-green-500 mb-6">
          <CheckCircle className="w-20 h-20 mx-auto" />
        </div>
        
        <h1 className="text-3xl font-medium text-white mb-4">
          Abonnement mis à jour !
        </h1>
        
        <p className="text-gray-400 mb-8">
          Nous allons préparer votre commande dans les plus brefs délais, merci de patienter.
        </p>

        <div className="flex flex-col sm:flex-row gap-4 justify-center">
          <button
            onClick={() => navigate('/dashboard')}
            className="glow-button"
          >
            Accéder à mon compte
          </button>
          <button
            onClick={() => navigate('/')}
            className="px-6 py-3 rounded-xl text-gray-300 hover:text-white transition-colors"
          >
            Retour à l'accueil
          </button>
        </div>
      </div>
    </div>
  );
}