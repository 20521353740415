import React from 'react';

interface StepIndicatorProps {
  currentStep: string;
  steps: string[];
}

export default function StepIndicator({ currentStep, steps }: StepIndicatorProps) {
  return (
    <div className="checkout-step-indicator">
      {steps.map((step, index) => (
        <React.Fragment key={step}>
          <div className={`step-dot ${
            step === currentStep
              ? 'active'
              : index < steps.indexOf(currentStep)
              ? 'completed'
              : 'pending'
          }`} />
          {index < steps.length - 1 && (
            <div className="step-line" />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}