import React from 'react';
import { useNavigate } from 'react-router-dom';

interface UpdateActionsProps {
  onUpdate: () => void;
  loading?: boolean;
  error?: string | null;
}

export default function UpdateActions({
  onUpdate,
  loading,
  error
}: UpdateActionsProps) {
  const navigate = useNavigate();

  return (
    <div>
      {error && (
        <div className="p-4 mb-4 bg-red-500/10 border border-red-500 rounded-lg text-red-500">
          {error}
        </div>
      )}

      <div className="flex justify-end gap-4">
        <button
          onClick={() => navigate('/dashboard')}
          className="px-4 py-2 text-gray-300 hover:text-white transition-colors"
        >
          Annuler
        </button>
        <button
          onClick={onUpdate}
          disabled={loading}
          className="glow-button"
        >
          {loading ? (
            <div className="flex items-center gap-2">
              <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
              <span>Mise à jour...</span>
            </div>
          ) : (
            "Mettre à jour l'abonnement"
          )}
        </button>
      </div>
    </div>
  );
}