import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './contexts/AuthContext';
import { CartProvider } from './contexts/CartContext';
import AppRoutes from './routes/AppRoutes';
import Navbar from './navbar/Navbar';
import Footer from './footer/footer.jsx';

function App() {
  const [showAuthModal, setShowAuthModal] = useState(false);

  return (
    <HelmetProvider>
      <AuthProvider>
        <CartProvider>
          <BrowserRouter>
            <div className="flex flex-col min-h-screen">
              <Navbar />
              <div className="background-glow"></div>
              <main className="flex-grow pt-32">
                <AppRoutes 
                  showAuthModal={showAuthModal}
                  onCloseAuthModal={() => setShowAuthModal(false)}
                  onOpenAuthModal={() => setShowAuthModal(true)}
                />
              </main>
              <Footer />
            </div>
          </BrowserRouter>
        </CartProvider>
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;