import React from 'react';
import { Package } from 'lucide-react';

interface SubscriptionDetailsProps {
  subscription: any;
  onCancelSubscription: () => void;
}

export default function SubscriptionDetails({ subscription, onCancelSubscription }: SubscriptionDetailsProps) {
  if (!subscription) return null;

  const isCancelled = subscription.status === 'cancelled';

  return (
    <div className="glass-card rounded-2xl p-6">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-medium text-white flex items-center gap-2">
          <Package className="w-5 h-5 text-blue-500" />
          Détails de l'abonnement
        </h3>
        {!isCancelled && (
          <button
            onClick={onCancelSubscription}
            className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg transition-colors text-sm"
          >
            Annuler l'abonnement
          </button>
        )}
      </div>

      <div className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <span className="text-sm text-gray-400">Plan</span>
            <p className="text-white">
              {subscription.plan === 'production' ? 'Production' : 'Connect'}
            </p>
          </div>
          <div>
            <span className="text-sm text-gray-400">Cycle de facturation</span>
            <p className="text-white">
              {subscription.billingCycle === 'monthly' ? 'Mensuel' : 'Annuel'}
            </p>
          </div>
        </div>

        {isCancelled && (
          <div className="p-3 bg-red-500/10 border border-red-500 rounded-lg">
            <p className="text-sm text-red-500">
              Abonnement annulé le {new Date(subscription.cancelledAt).toLocaleDateString('fr-FR')}
            </p>
          </div>
        )}

        {subscription.machines && (
          <div>
            <h4 className="text-sm font-medium text-gray-400 mb-2">Machines</h4>
            <div className="space-y-2">
              {Object.values(subscription.machines).map((machine: any) => (
                <div key={machine.id} className="flex justify-between items-center p-3 bg-gray-800/50 rounded-lg">
                  <div>
                    <p className="text-white">{machine.name}</p>
                    <p className="text-sm text-gray-400">Code: {machine.code}</p>
                  </div>
                  <p className="text-white">Quantité: {machine.quantity}</p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}