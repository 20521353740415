import { doc, setDoc, getDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { CartItemType, LicenseConfig } from '../types/cart';
import { calculatePrices } from './priceService';
import { MONTHLY_RIPEE_FEE } from '../constants/pricing';

const TVA_RATE = 0.20;

interface CartData {
  items: CartItemType[];
  userId: string | null;
  updatedAt: string;
  selectedPlan?: 'production' | 'connect';
  billingCycle?: 'monthly' | 'annual';
  firstPeriodPrice: number; // HT
  regularPrice: number; // HT
  firstPeriodPriceTTC: number;
  regularPriceTTC: number;
  affiliateCode?: string;
  affiliateAmount?: number; // TTC
  regularItemsTotal: number; // HT
  regularItemsTotalTTC: number;
}

export const saveCart = async (cartId: string, data: Partial<CartData>): Promise<void> => {
  try {
    if (!cartId) {
      throw new Error('Invalid cart ID');
    }

    // Ensure items array exists
    const items = data.items || [];

    // Calculate subscription prices if there's a config
    let subscriptionPrices = {
      firstPeriodPrice: 0, // HT
      regularPrice: 0, // HT
      firstPeriodPriceTTC: 0,
      regularPriceTTC: 0
    };

    const configItem = items.find(item => item.configId);
    if (configItem?.configId) {
      const configRef = doc(db, 'config_licence', configItem.configId);
      const configDoc = await getDoc(configRef);
      if (configDoc.exists()) {
        const config = configDoc.data() as LicenseConfig;
        subscriptionPrices = {
          firstPeriodPrice: config.firstPeriodPrice || 0,
          regularPrice: config.regularPrice || 0,
          firstPeriodPriceTTC: config.firstPeriodPriceTTC || 0,
          regularPriceTTC: config.regularPriceTTC || 0
        };
      }
    }

    // Calculate regular items total HT
    const regularItems = items.filter(item => !item.configId);
    const regularItemsTotal = regularItems.reduce((sum, item) => {
      return sum + (item.price * item.quantity);
    }, 0);

    // Calculate regular items TTC
    const regularItemsTotalTTC = regularItemsTotal * (1 + TVA_RATE);

    // Calculate total HT before affiliate discount
    const totalHT = subscriptionPrices.firstPeriodPrice + regularItemsTotal;
    const totalTTC = totalHT * (1 + TVA_RATE);

    // If there's an affiliate discount (TTC), convert it to HT for calculations
    let affiliateAmountHT = 0;
    if (data.affiliateAmount) {
      affiliateAmountHT = data.affiliateAmount / (1 + TVA_RATE);
    }

    // Create cart data object, removing undefined values
    const cartData: Record<string, any> = {
      items,
      userId: data.userId,
      firstPeriodPrice: subscriptionPrices.firstPeriodPrice - affiliateAmountHT,
      regularPrice: subscriptionPrices.regularPrice,
      firstPeriodPriceTTC: subscriptionPrices.firstPeriodPriceTTC - (data.affiliateAmount || 0),
      regularPriceTTC: subscriptionPrices.regularPriceTTC,
      regularItemsTotal,
      regularItemsTotalTTC,
      updatedAt: new Date().toISOString()
    };

    // Only add defined optional fields
    if (data.selectedPlan) cartData.selectedPlan = data.selectedPlan;
    if (data.billingCycle) cartData.billingCycle = data.billingCycle;
    if (data.affiliateCode) cartData.affiliateCode = data.affiliateCode;
    if (data.affiliateAmount !== undefined) cartData.affiliateAmount = data.affiliateAmount;

    const cartRef = doc(db, 'carts', cartId);
    await setDoc(cartRef, cartData);

    console.log('Cart saved with data:', cartData);
  } catch (error) {
    console.error('Error saving cart:', error);
    throw error;
  }
};

export const getCart = async (cartId: string): Promise<CartData | null> => {
  try {
    if (!cartId) {
      return null;
    }

    const cartRef = doc(db, 'carts', cartId);
    const cartDoc = await getDoc(cartRef);
    
    if (!cartDoc.exists()) {
      return null;
    }

    return cartDoc.data() as CartData;
  } catch (error) {
    console.error('Error fetching cart:', error);
    throw error;
  }
};

export const deleteCart = async (cartId: string): Promise<void> => {
  try {
    if (!cartId) {
      throw new Error('Invalid cart ID');
    }

    const cartRef = doc(db, 'carts', cartId);
    await deleteDoc(cartRef);
  } catch (error) {
    console.error('Error deleting cart:', error);
    throw error;
  }
};

export const saveLicenseConfig = async (configId: string, config: LicenseConfig): Promise<void> => {
  try {
    // Calculate prices based on machines
    const machines = config.machines.map(machine => ({
      code: machine.code,
      quantity: machine.quantity
    }));
    
    const prices = await calculatePrices(machines);
    const monthlyBasePrice = Math.ceil(prices.monthly[config.selectedPlan] / 100);
    const monthlyWithFees = monthlyBasePrice + MONTHLY_RIPEE_FEE;
    
    // Calculate first period and regular prices HT
    let firstPeriodPrice = monthlyWithFees;
    let regularPrice = monthlyWithFees;

    if (config.billingCycle === 'annual') {
      firstPeriodPrice = Math.floor(monthlyWithFees * 12 * 0.84); // 16% discount
      regularPrice = firstPeriodPrice;
    }

    // Calculate TTC prices
    const firstPeriodPriceTTC = firstPeriodPrice * (1 + TVA_RATE);
    const regularPriceTTC = regularPrice * (1 + TVA_RATE);

    // Save config with all prices
    const configRef = doc(db, 'config_licence', configId);
    const configData = {
      ...config,
      firstPeriodPrice, // HT
      regularPrice, // HT
      firstPeriodPriceTTC,
      regularPriceTTC,
      createdAt: new Date().toISOString()
    };

    await setDoc(configRef, configData);

    console.log('License config saved with prices:', {
      firstPeriodPrice,
      firstPeriodPriceTTC,
      regularPrice,
      regularPriceTTC
    });
  } catch (error) {
    console.error('Error saving license config:', error);
    throw error;
  }
};

export const getLicenseConfig = async (configId: string): Promise<LicenseConfig | null> => {
  try {
    const configRef = doc(db, 'config_licence', configId);
    const configDoc = await getDoc(configRef);
    
    if (!configDoc.exists()) {
      return null;
    }

    return configDoc.data() as LicenseConfig;
  } catch (error) {
    console.error('Error fetching license config:', error);
    throw error;
  }
};

export const deleteLicenseConfig = async (configId: string): Promise<void> => {
  try {
    const configRef = doc(db, 'config_licence', configId);
    await deleteDoc(configRef);
  } catch (error) {
    console.error('Error deleting license config:', error);
    throw error;
  }
};