import React from 'react';
import { CreditCard } from 'lucide-react';

interface PricingDetailsProps {
  pricing: {
    firstPeriodPrice: number;
    regularPrice: number;
    affiliateDiscount?: {
      code: string;
      amount: number;
    };
  };
  billingCycle: 'monthly' | 'annual';
}

export default function PricingDetails({ pricing, billingCycle }: PricingDetailsProps) {
  const hasAffiliateDiscount = pricing?.affiliateDiscount?.amount > 0;

  return (
    <div className="glass-card p-4 rounded-xl">
      <h3 className="text-lg font-medium text-white mb-4 flex items-center gap-2">
        <CreditCard className="w-5 h-5 text-blue-500" />
        Tarification
      </h3>
      <div className="space-y-3">
        {pricing && (
          <>
            {hasAffiliateDiscount ? (
              <>
                <div className="flex justify-between items-baseline">
                  <span className="text-gray-400">
                    Premier {billingCycle === 'monthly' ? 'mois' : 'année'}
                  </span>
                  <div className="text-right">
                    <div className="text-sm mb-1">
                      <span className="text-gray-400 line-through mr-2">
                        {pricing.regularPrice}€
                      </span>
                      <span className="text-xs text-green-400">
                        (-{pricing.affiliateDiscount.amount}€)
                      </span>
                    </div>
                    <span className="text-white font-medium text-xl">
                      {pricing.firstPeriodPrice}€
                    </span>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-400">
                    {billingCycle === 'monthly' ? 'Mois' : 'Années'} suivants
                  </span>
                  <span className="text-white font-medium text-xl">
                    {pricing.regularPrice}€
                  </span>
                </div>
              </>
            ) : (
              <div className="flex justify-between items-center">
                <span className="text-gray-400">Prix total</span>
                <span className="text-white font-medium text-xl">
                  {pricing.regularPrice}€
                  /{billingCycle === 'monthly' ? 'mois' : 'an'}
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}