import React from 'react';
import { Settings, Workflow } from 'lucide-react';
import { PlanType } from '../../types/pricing';

interface PricingPlanSelectorProps {
  selectedPlan: PlanType;
  onPlanChange: (plan: PlanType) => void;
  machines?: Array<{
    code: string;
    quantity: number;
  }>;
  hidePrice?: boolean;
}

export default function PricingPlanSelector({
  selectedPlan,
  onPlanChange,
  machines = [],
  hidePrice = false
}: PricingPlanSelectorProps) {
  const plans = [
    {
      id: 'production',
      title: 'Production',
      icon: Settings,
      description: 'Version de base'
    },
    {
      id: 'connect',
      title: 'Connect',
      icon: Workflow,
      description: 'Version complète'
    }
  ];

  return (
    <div className="grid md:grid-cols-2 gap-6 max-w-4xl mx-auto">
      {plans.map((plan) => {
        const Icon = plan.icon;
        const isSelected = selectedPlan === plan.id;
        
        return (
          <button
            key={plan.id}
            onClick={() => onPlanChange(plan.id as PlanType)}
            className={`
              group relative overflow-hidden rounded-xl transition-all duration-300 text-left h-full
              ${isSelected 
                ? 'border-ripee bg-ripee-light' 
                : 'glass-card hover:border hover:border-[rgb(208_121_61_/_50%)]'
              }
            `}
          >
            <div className="h-full relative flex flex-col p-8">
              <div className="flex items-center gap-3">
                <div className={`p-2 rounded-lg ${
                  isSelected 
                    ? 'bg-ripee text-white' 
                    : 'bg-ripee/10 text-ripee'
                }`}>
                  <Icon className="w-6 h-6" />
                </div>
                <div>
                  <span className={`font-medium text-lg ${
                    isSelected ? 'text-ripee' : 'text-white'
                  }`}>{plan.title}</span>
                  <p className="text-sm text-gray-400 mt-1">{plan.description}</p>
                </div>
              </div>
            </div>
          </button>
        );
      })}
    </div>
  );
}