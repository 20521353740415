import { doc, getDoc, updateDoc, collection } from 'firebase/firestore';
import { db } from '../config/firebase';
import { getStripeSession, cancelStripeSubscription } from './stripeService';
import { OrderStatus, OrderProcessingStatus } from '../types/order';

export const verifyOrderPayment = async (orderId: string): Promise<{
  isValid: boolean;
  error?: string;
}> => {
  try {
    const orderRef = doc(db, 'orders', orderId);
    const orderDoc = await getDoc(orderRef);
    
    if (!orderDoc.exists()) {
      return { isValid: false, error: 'Commande non trouvée' };
    }

    const orderData = orderDoc.data();
    const session = await getStripeSession(orderData.stripeSessionId);
    
    if (session?.payment_status === 'paid') {
      // If this is a subscription update, only archive the original order
      if (orderData.isSubscriptionUpdate && orderData.originalOrderId) {
        const originalOrderRef = doc(db, 'orders', orderData.originalOrderId);
        await updateDoc(originalOrderRef, {
          status: 'archived',
          processingStatus: 'processed',
          archivedAt: new Date().toISOString(),
          updatedAt: new Date().toISOString()
        });
      }
      
      return { isValid: true };
    }

    return { isValid: false, error: 'Paiement non validé' };
  } catch (error) {
    console.error('Error verifying payment:', error);
    return { 
      isValid: false, 
      error: error instanceof Error ? error.message : 'Une erreur est survenue' 
    };
  }
};

export const updateOrderStatus = async (orderId: string, status: OrderStatus): Promise<void> => {
  try {
    const orderRef = doc(db, 'orders', orderId);
    await updateDoc(orderRef, {
      status,
      updatedAt: new Date().toISOString()
    });
  } catch (error) {
    console.error('Error updating order status:', error);
    throw error;
  }
};

export const updateOrderProcessingStatus = async (
  orderId: string, 
  processingStatus: OrderProcessingStatus,
  notes?: string
): Promise<void> => {
  try {
    const orderRef = doc(db, 'orders', orderId);
    const updateData: any = {
      processingStatus,
      updatedAt: new Date().toISOString()
    };

    if (processingStatus === 'processed') {
      updateData.processedAt = new Date().toISOString();
    }

    if (notes) {
      updateData.processingNotes = notes;
    }

    await updateDoc(orderRef, updateData);
  } catch (error) {
    console.error('Error updating order processing status:', error);
    throw error;
  }
};

export const archiveOrder = async (orderId: string): Promise<void> => {
  try {
    const orderRef = doc(db, 'orders', orderId);
    await updateDoc(orderRef, {
      status: 'archived',
      processingStatus: 'processed',
      archivedAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    });
  } catch (error) {
    console.error('Error archiving order:', error);
    throw error;
  }
};

export const cancelOrderSubscription = async (orderId: string, accountId: string): Promise<void> => {
  try {
    const orderRef = doc(db, 'orders', orderId);
    const orderDoc = await getDoc(orderRef);
    if (!orderDoc.exists()) {
      throw new Error('Commande non trouvée');
    }

    const orderData = orderDoc.data();
    const stripeSession = await getStripeSession(orderData.stripeSessionId);
    if (!stripeSession || !stripeSession.subscription) {
      throw new Error('Aucun abonnement Stripe associé');
    }

    const cancelled = await cancelStripeSubscription(stripeSession.subscription);
    if (!cancelled) {
      throw new Error('Erreur lors de l\'annulation de l\'abonnement Stripe');
    }

    await updateDoc(orderRef, {
      status: 'cancelled' as OrderStatus,
      'subscription.status': 'cancelled',
      'subscription.cancelledAt': new Date().toISOString(),
      processingStatus: 'pending_processing' as OrderProcessingStatus,
      processingNotes: 'Abonnement annulé - En attente de traitement',
      updatedAt: new Date().toISOString()
    });

    const accountRef = doc(db, 'accounts', accountId);
    await updateDoc(accountRef, {
      'billing.status': 'cancelled',
      'billing.cancelledAt': new Date().toISOString(),
      updatedAt: new Date().toISOString()
    });
  } catch (error) {
    console.error('Error canceling subscription:', error);
    throw error;
  }
};

export const createNewOrderFromUpdate = async (
  originalOrder: any,
  config: any,
  stripeSubscription: any
): Promise<string> => {
  try {
    const orderData = {
      accountId: originalOrder.accountId,
      status: 'paid',
      processingStatus: 'pending_processing',
      amount: config.regularPriceTTC || 0,
      customerEmail: originalOrder.customerEmail || '',
      customerName: originalOrder.customerName || '',
      paymentMethod: originalOrder.paymentMethod || 'card',
      stripeSessionId: originalOrder.stripeSessionId,
      subscription: {
        plan: config.selectedPlan,
        billingCycle: config.billingCycle,
        regularPrice: config.regularPrice || 0,
        regularPriceTTC: config.regularPriceTTC || 0,
        machines: config.machines.reduce((acc: any, machine: any) => {
          acc[machine.id] = {
            id: machine.id,
            name: machine.name,
            code: machine.code,
            quantity: machine.quantity
          };
          return acc;
        }, {}),
        status: 'active',
        stripeId: stripeSubscription.id
      },
      pricing: {
        regularItemsTotal: config.regularPrice || 0,
        regularItemsTotalTTC: config.regularPriceTTC || 0,
        affiliateAmount: originalOrder.pricing?.affiliateAmount || 0,
        affiliateCode: originalOrder.pricing?.affiliateCode || null
      },
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      isSubscriptionUpdate: true,
      originalOrderId: originalOrder.id,
      resellerId: originalOrder.resellerId || null
    };

    const ordersRef = collection(db, 'orders');
    const orderRef = await ordersRef.add(orderData);
    return orderRef.id;
  } catch (error) {
    console.error('Error creating new order:', error);
    throw error;
  }
};