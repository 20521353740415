import React from 'react';
import { CreditCard, Building2 } from 'lucide-react';

interface PaymentDetailsProps {
  order: any;
}

export default function PaymentDetails({ order }: PaymentDetailsProps) {
  return (
    <div className="glass-card rounded-2xl p-6">
      <h3 className="text-lg font-medium text-white flex items-center gap-2 mb-4">
        <CreditCard className="w-5 h-5 text-blue-500" />
        Détails du paiement
      </h3>

      <div className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <span className="text-sm text-gray-400">Montant total TTC</span>
            <p className="text-white">{order.amount.toFixed(2)}€ {order.subscription?.billingCycle === 'monthly' ? '/mois' : '/an'}</p>
            {order.subscription?.billingCycle === 'annual' && (
              <p className="text-sm text-gray-400">
                (soit {(order.amount / 12).toFixed(2)}€ /mois)
              </p>
            )}
          </div>
          <div>
            <span className="text-sm text-gray-400">Méthode de paiement</span>
            <p className="text-white">{order.paymentMethod || 'N/A'}</p>
          </div>
        </div>

        {order.pricing?.affiliateAmount > 0 && (
          <div className="p-3 bg-green-500/10 border border-green-500 rounded-lg">
            <div className="flex items-center gap-2 text-green-400 mb-1">
              <Building2 className="w-4 h-4" />
              <span className="font-medium">Remise revendeur appliquée</span>
            </div>
            <p className="text-sm text-green-400">
              Une remise de {order.pricing.affiliateAmount.toFixed(2)}€ a été appliquée sur le premier paiement
            </p>
          </div>
        )}
      </div>
    </div>
  );
}