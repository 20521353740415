import React from 'react';
import { Calendar, CalendarClock, Check } from 'lucide-react';
import { calculatePrices } from '../../services/priceService';
import { MONTHLY_RIPEE_FEE } from '../../constants/pricing';

interface BillingCycleSelectorProps {
  selectedCycle: 'monthly' | 'annual';
  onCycleChange: (cycle: 'monthly' | 'annual') => void;
  machines: Array<{
    code: string;
    quantity: number;
  }>;
  selectedPlan: 'production' | 'connect';
}

export default function BillingCycleSelector({
  selectedCycle,
  onCycleChange,
  machines,
  selectedPlan
}: BillingCycleSelectorProps) {
  const [prices, setPrices] = React.useState<{
    monthly: number | null;
    annual: number | null;
  }>({ monthly: null, annual: null });

  React.useEffect(() => {
    const loadPrices = async () => {
      if (machines.length === 0) {
        setPrices({ monthly: null, annual: null });
        return;
      }

      try {
        const priceData = await calculatePrices(machines);
        const monthlyBasePrice = Math.ceil(priceData.monthly[selectedPlan] / 100) + MONTHLY_RIPEE_FEE;
        const annualPrice = Math.floor(monthlyBasePrice * 12 * 0.84); // 16% de réduction

        setPrices({
          monthly: monthlyBasePrice,
          annual: annualPrice
        });
      } catch (err) {
        console.error('Error calculating prices:', err);
        setPrices({ monthly: null, annual: null });
      }
    };

    loadPrices();
  }, [machines, selectedPlan]);

  return (
    <div className="grid md:grid-cols-2 gap-6 max-w-4xl mx-auto">
      <button
        onClick={() => onCycleChange('monthly')}
        className={`
          group relative overflow-hidden rounded-xl transition-all duration-300 text-left h-full
          ${selectedCycle === 'monthly' 
            ? 'border-ripee bg-ripee-light' 
            : 'glass-card hover:border hover:border-[rgb(208_121_61_/_50%)]'
          }
        `}
      >
        {selectedCycle === 'monthly' && (
          <Check className="absolute top-0 left-0 w-5 h-5 text-ripee m-4" />
        )}
        
        <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-700">
          <div className="absolute inset-0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-1000 bg-gradient-to-r from-transparent via-white/10 to-transparent" />
        </div>
        
        <div className="h-full relative flex flex-col p-8">
          <div className="flex items-center gap-3">
            <div className={`p-2 rounded-lg ${
              selectedCycle === 'monthly' 
                ? 'bg-ripee text-white' 
                : 'bg-ripee/10 text-ripee'
            }`}>
              <Calendar className="w-6 h-6" />
            </div>
            <div>
              <span className={`font-medium text-lg ${
                selectedCycle === 'monthly' ? 'text-ripee' : 'text-white'
              }`}>Mensuel</span>
              <p className="text-sm text-gray-400 mt-1">Paiement tous les mois</p>
            </div>
          </div>

          {prices.monthly !== null && (
            <div className="mt-6">
              <div className="text-white font-medium text-xl">
                {prices.monthly.toFixed(2)}€ HT
                <span className="text-sm text-gray-400 ml-1">/mois</span>
              </div>
            </div>
          )}
        </div>
      </button>

      <button
        onClick={() => onCycleChange('annual')}
        className={`
          group relative overflow-hidden rounded-xl transition-all duration-300 text-left h-full
          ${selectedCycle === 'annual' 
            ? 'border-ripee bg-ripee-light' 
            : 'glass-card hover:border hover:border-[rgb(208_121_61_/_50%)]'
          }
        `}
      >
        {selectedCycle === 'annual' && (
          <Check className="absolute top-0 left-0 w-5 h-5 text-ripee m-4" />
        )}
        
        <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-700">
          <div className="absolute inset-0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-1000 bg-gradient-to-r from-transparent via-white/10 to-transparent" />
        </div>
        
        <div className="h-full relative flex flex-col p-8">
          <div className="flex items-center gap-3">
            <div className={`p-2 rounded-lg ${
              selectedCycle === 'annual' 
                ? 'bg-ripee text-white' 
                : 'bg-ripee/10 text-ripee'
            }`}>
              <CalendarClock className="w-6 h-6" />
            </div>
            <div>
              <span className={`font-medium text-lg ${
                selectedCycle === 'annual' ? 'text-ripee' : 'text-white'
              }`}>Annuel</span>
              <p className="text-sm text-gray-400 mt-1">-16% sur le prix mensuel</p>
            </div>
          </div>

          {prices.monthly !== null && prices.annual !== null && (
            <div className="mt-6">
              <div className="text-white font-medium text-xl">
                {(prices.annual / 12).toFixed(2)}€ HT
                <span className="text-sm text-gray-400 ml-1">/mois</span>
              </div>
              <div className="text-sm text-green-400 mt-2">
                Économie de {((prices.monthly * 12) - prices.annual).toFixed(2)}€ HT /an
              </div>
            </div>
          )}
        </div>
      </button>
    </div>
  );
}