import { 
  collection, 
  doc, 
  setDoc, 
  getDoc, 
  getDocs, 
  query, 
  where,
  updateDoc,
  deleteDoc,
  Timestamp
} from 'firebase/firestore';
import { 
  createUserWithEmailAndPassword, 
  sendPasswordResetEmail,
  getAuth,
  signInWithEmailAndPassword
} from 'firebase/auth';
import { db } from '../config/firebase';
import { ClientFormData } from '../types/client';

export const createClientAccount = async (data: ClientFormData) => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  
  if (!currentUser) {
    throw new Error('Non autorisé');
  }

  const adminEmail = currentUser.email;
  const adminPassword = sessionStorage.getItem('adminPassword');

  try {
    // Générer un mot de passe temporaire
    const tempPassword = Math.random().toString(36).slice(-12);

    // Créer le compte Firebase Auth
    const userCredential = await createUserWithEmailAndPassword(auth, data.email, tempPassword);
    const newUserId = userCredential.user.uid;

    // Préparer les données du compte
    const accountData = {
      id: newUserId,
      userId: newUserId,
      role: data.type === 'reseller' ? 'reseller' : 'end-user',
      type: data.type,
      status: 'active',
      company: {
        name: data.companyName,
        registrationNumber: ''
      },
      contact: {
        name: data.contactName,
        email: data.email,
        phone: data.phone
      },
      billing: {
        vatNumber: data.vatNumber,
        plan: data.plan || 'none',
        billingCycle: data.billingCycle || 'none',
        billingAddress: data.address,
        subscriptionStartDate: data.plan === 'none' ? null : new Date().toISOString()
      },
      licenses: data.licenses || [],
      resellerId: data.type === 'client-reseller' ? data.resellerId : null,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };

    // Créer le document dans Firestore
    const accountRef = doc(collection(db, 'accounts'), newUserId);
    await setDoc(accountRef, accountData);

    // Envoyer l'email de réinitialisation du mot de passe
    await sendPasswordResetEmail(auth, data.email);

    // Reconnecter l'admin
    if (adminEmail && adminPassword) {
      try {
        await signInWithEmailAndPassword(auth, adminEmail, adminPassword);
      } catch (signInError) {
        console.error('Error reconnecting admin:', signInError);
        throw new Error('Erreur lors de la réauthentification de l\'administrateur');
      }
    } else {
      throw new Error('Informations de connexion administrateur manquantes');
    }

    return newUserId;

  } catch (error: any) {
    console.error('Error creating client account:', error);
    
    // Reconnecter l'admin même en cas d'erreur
    if (adminEmail && adminPassword) {
      try {
        await signInWithEmailAndPassword(auth, adminEmail, adminPassword);
      } catch (signInError) {
        console.error('Error reconnecting admin:', signInError);
      }
    }
    
    if (error.code === 'auth/email-already-in-use') {
      throw new Error('Cette adresse email est déjà utilisée');
    }
    
    if (error.code === 'auth/invalid-email') {
      throw new Error('L\'adresse email n\'est pas valide');
    }

    throw new Error('Erreur lors de la création du compte');
  }
};

export const updateClientAccount = async (clientId: string, data: ClientFormData) => {
  try {
    const accountRef = doc(db, 'accounts', clientId);
    const accountDoc = await getDoc(accountRef);

    if (!accountDoc.exists()) {
      throw new Error('Client non trouvé');
    }

    const currentData = accountDoc.data();

    // Déterminer le rôle et le resellerId en fonction du type
    const role = data.type === 'reseller' ? 'reseller' : 'end-user';
    const resellerId = data.type === 'client-reseller' ? data.resellerId : null;

    const updateData = {
      role,
      type: data.type,
      resellerId,
      company: {
        name: data.companyName
      },
      contact: {
        name: data.contactName,
        phone: data.phone,
        email: currentData.contact.email // On ne modifie pas l'email
      },
      billing: {
        vatNumber: data.vatNumber,
        plan: data.plan || 'none',
        billingCycle: data.billingCycle || 'none',
        billingAddress: data.address,
        subscriptionStartDate: data.plan === 'none' ? null : (
          currentData.billing?.subscriptionStartDate || new Date().toISOString()
        )
      },
      licenses: data.licenses || [],
      updatedAt: new Date().toISOString()
    };

    await updateDoc(accountRef, updateData);
  } catch (error) {
    console.error('Error updating client account:', error);
    throw new Error('Erreur lors de la mise à jour du client');
  }
};

export const removeClientSubscription = async (clientId: string) => {
  try {
    const accountRef = doc(db, 'accounts', clientId);
    const accountDoc = await getDoc(accountRef);

    if (!accountDoc.exists()) {
      throw new Error('Client non trouvé');
    }

    const currentData = accountDoc.data();

    await updateDoc(accountRef, {
      'billing.plan': 'none',
      'billing.billingCycle': 'none',
      'billing.subscriptionStartDate': null,
      licenses: [],
      type: currentData.type,
      role: currentData.role,
      resellerId: currentData.resellerId,
      updatedAt: new Date().toISOString()
    });
  } catch (error) {
    console.error('Error removing client subscription:', error);
    throw new Error('Erreur lors de la suppression de l\'abonnement');
  }
};