import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Package, CheckCircle, XCircle, Clock, Archive } from 'lucide-react';
import OrderDetails from './order-details/OrderDetails';

interface OrdersTabProps {
  accountId: string | undefined;
}

export default function OrdersTab({ accountId }: OrdersTabProps) {
  const [orders, setOrders] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);

  useEffect(() => {
    if (!accountId) return;

    const loadOrders = async () => {
      try {
        setLoading(true);
        setError(null);

        // Fetch orders with status paid, cancelled, or archived
        const ordersRef = collection(db, 'orders');
        const q = query(
          ordersRef, 
          where('accountId', '==', accountId),
          where('status', 'in', ['paid', 'cancelled', 'archived'])
        );
        
        const snapshot = await getDocs(q);
        
        const ordersData = snapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

        setOrders(ordersData);
      } catch (err) {
        console.error('Error fetching orders:', err);
        setError('Erreur lors du chargement des commandes');
      } finally {
        setLoading(false);
      }
    };

    loadOrders();
  }, [accountId]);

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'paid':
        return <CheckCircle className="w-5 h-5 text-green-500" />;
      case 'cancelled':
        return <XCircle className="w-5 h-5 text-red-500" />;
      case 'archived':
        return <Archive className="w-5 h-5 text-blue-500" />;
      default:
        return <Clock className="w-5 h-5 text-yellow-500" />;
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'paid':
        return 'Payée';
      case 'cancelled':
        return 'Annulée';
      case 'archived':
        return 'Archivée';
      default:
        return 'En attente';
    }
  };

  const getStatusClass = (status: string) => {
    switch (status) {
      case 'paid':
        return 'bg-green-500/10 text-green-500';
      case 'cancelled':
        return 'bg-red-500/10 text-red-500';
      case 'archived':
        return 'bg-blue-500/10 text-blue-500';
      default:
        return 'bg-yellow-500/10 text-yellow-500';
    }
  };

  const getProcessingStatusInfo = (status: string) => {
    switch (status) {
      case 'pending_processing':
        return {
          icon: <Clock className="w-4 h-4" />,
          text: 'À traiter',
          className: 'bg-yellow-500/10 text-yellow-500'
        };
      case 'processing':
        return {
          icon: <Package className="w-4 h-4 animate-spin" />,
          text: 'En cours',
          className: 'bg-blue-500/10 text-blue-500'
        };
      case 'processed':
        return {
          icon: <Package className="w-4 h-4" />,
          text: 'Traitée',
          className: 'bg-green-500/10 text-green-500'
        };
      default:
        return {
          icon: <Clock className="w-4 h-4" />,
          text: 'À traiter',
          className: 'bg-yellow-500/10 text-yellow-500'
        };
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  if (selectedOrderId) {
    return (
      <OrderDetails 
        orderId={selectedOrderId} 
        onBack={() => setSelectedOrderId(null)} 
      />
    );
  }

  return (
    <div className="glass-card rounded-2xl p-6">
      <h2 className="text-xl font-medium text-white mb-6">Historique des commandes</h2>

      {orders.length === 0 ? (
        <div className="text-center text-gray-400">
          Aucune commande trouvée
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="text-left border-b border-gray-700">
                <th className="pb-3 text-gray-400 font-medium">Date</th>
                <th className="pb-3 text-gray-400 font-medium">N° Commande</th>
                <th className="pb-3 text-gray-400 font-medium">Statut</th>
                <th className="pb-3 text-gray-400 font-medium">Traitement</th>
                <th className="pb-3 text-gray-400 font-medium">Actions</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr 
                  key={order.id} 
                  className="border-b border-gray-800 hover:bg-white/5 transition-colors"
                >
                  <td className="py-4 text-white">
                    {format(new Date(order.createdAt), 'dd/MM/yyyy HH:mm', { locale: fr })}
                  </td>
                  <td className="py-4 text-white">
                    #{order.id.slice(0, 8)}
                  </td>
                  <td className="py-4">
                    <span className={`inline-flex items-center gap-1.5 px-2.5 py-1 rounded-full text-xs font-medium ${getStatusClass(order.status)}`}>
                      {getStatusIcon(order.status)}
                      {getStatusText(order.status)}
                    </span>
                  </td>
                  <td className="py-4">
                    <span className={`inline-flex items-center gap-1.5 px-2.5 py-1 rounded-full text-xs font-medium ${getProcessingStatusInfo(order.processingStatus).className}`}>
                      {getProcessingStatusInfo(order.processingStatus).icon}
                      {getProcessingStatusInfo(order.processingStatus).text}
                    </span>
                  </td>
                  <td className="py-4">
                    <button
                      onClick={() => setSelectedOrderId(order.id)}
                      className="text-gray-400 hover:text-white transition-colors"
                    >
                      Voir détails
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}