import React, { useState, useEffect } from 'react';
import { Users, Settings, Tag, Package } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
import ClientManagement from '../components/admin/ClientManagement';
import SettingsPanel from '../components/admin/SettingsPanel';
import AffiliateManagement from '../components/admin/AffiliateManagement';
import OrdersManagement from '../components/admin/OrdersManagement';

export default function AdminPanel() {
  const [activeTab, setActiveTab] = useState<'clients' | 'settings' | 'affiliates' | 'orders'>('clients');
  const location = useLocation();
  const navigate = useNavigate();

  // Récupérer l'onglet depuis les paramètres d'URL
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    if (tab && ['clients', 'settings', 'affiliates', 'orders'].includes(tab)) {
      setActiveTab(tab as typeof activeTab);
    }
  }, [location]);

  // Mettre à jour l'URL quand l'onglet change
  const handleTabChange = (tab: typeof activeTab) => {
    setActiveTab(tab);
    navigate(`/admin?tab=${tab}`, { replace: true });
  };

  const tabs = [
    { id: 'clients', label: 'Clients', icon: Users },
    { id: 'orders', label: 'Commandes', icon: Package },
    { id: 'affiliates', label: 'Codes d\'affiliation', icon: Tag },
    { id: 'settings', label: 'Paramètres', icon: Settings }
  ];

  return (
    <div className="min-h-screen p-6">
      <div className="max-w-7xl mx-auto">
        <div className="flex gap-4 mb-8">
          {tabs.map(tab => {
            const Icon = tab.icon;
            return (
              <button
                key={tab.id}
                onClick={() => handleTabChange(tab.id as typeof activeTab)}
                className={`flex items-center gap-2 px-4 py-2 rounded-xl text-sm font-medium transition-colors ${
                  activeTab === tab.id
                    ? 'bg-blue-500 text-white'
                    : 'text-gray-400 hover:text-white hover:bg-white/5'
                }`}
              >
                <Icon className="w-5 h-5" />
                {tab.label}
              </button>
            );
          })}
        </div>

        <div className="glass-card rounded-2xl p-6">
          {activeTab === 'clients' && <ClientManagement />}
          {activeTab === 'orders' && <OrdersManagement />}
          {activeTab === 'affiliates' && <AffiliateManagement />}
          {activeTab === 'settings' && <SettingsPanel />}
        </div>
      </div>
    </div>
  );
}