import React from 'react';
import { useCart } from '../../contexts/CartContext';
import { v4 as uuidv4 } from 'uuid';

const SAMPLE_PRODUCT = {
  id: 'sample-1',
  name: 'Kit de maintenance PrintFactory',
  code: 'PF-MAINT-001',
  price: 50,
  description: 'Kit complet de maintenance pour imprimantes compatibles PrintFactory'
};

export default function FrequentlyAddedProducts() {
  const { addItem } = useCart();

  const handleAddToCart = async () => {
    await addItem({
      id: uuidv4(),
      name: SAMPLE_PRODUCT.name,
      code: SAMPLE_PRODUCT.code,
      price: SAMPLE_PRODUCT.price,
      quantity: 1
    });
  };

  return (
    <div className="mt-16">
      <h2 className="text-2xl font-medium text-white mb-8">Produits fréquemment ajoutés</h2>
      
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="glass-card p-6 rounded-xl flex flex-col">
          <h3 className="text-lg font-medium text-white mb-2">{SAMPLE_PRODUCT.name}</h3>
          <p className="text-gray-400 text-sm mb-4 flex-1">{SAMPLE_PRODUCT.description}</p>
          
          <div className="flex items-end justify-between mt-4">
            <div className="text-white font-medium text-2xl">
              {SAMPLE_PRODUCT.price.toFixed(2)}€
            </div>
            <button
              onClick={handleAddToCart}
              className="glow-button !py-2"
            >
              Ajouter au panier
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}