import React from 'react';
import { Info } from 'lucide-react';
import { calculatePrices } from '../../../services/priceService';
import { MONTHLY_RIPEE_FEE } from '../../../constants/pricing';

interface UpdatePriceSummaryProps {
  selectedPlan: 'production' | 'connect';
  currentConfig: {
    billingCycle: 'monthly' | 'annual';
    plan: 'production' | 'connect';
    machines: Array<{
      id: string;
      name: string;
      code: string;
      quantity: number;
    }>;
  };
}

export default function UpdatePriceSummary({
  selectedPlan,
  currentConfig
}: UpdatePriceSummaryProps) {
  const [monthlyPrice, setMonthlyPrice] = React.useState<number>(0);
  const [annualPrice, setAnnualPrice] = React.useState<number>(0);

  React.useEffect(() => {
    const calculateTotalPrices = async () => {
      if (!currentConfig?.machines?.length) return;

      try {
        const machines = currentConfig.machines.map(machine => ({
          code: machine.code,
          quantity: machine.quantity
        }));

        const prices = await calculatePrices(machines);
        const monthlyBasePrice = Math.ceil(prices.monthly[selectedPlan] / 100) + MONTHLY_RIPEE_FEE;
        const annualBasePrice = monthlyBasePrice * 12;
        const annualWithDiscount = Math.floor(annualBasePrice * 0.84);

        setMonthlyPrice(monthlyBasePrice);
        setAnnualPrice(annualWithDiscount);
      } catch (error) {
        console.error('Error calculating prices:', error);
      }
    };

    calculateTotalPrices();
  }, [selectedPlan, currentConfig]);

  const isAnnual = currentConfig.billingCycle === 'annual';
  const displayMonthlyPrice = isAnnual ? annualPrice / 12 : monthlyPrice;

  return (
    <div className="glass-card p-6 rounded-xl">
      <div className="space-y-6">
        <div className="flex items-start gap-4 p-4 bg-blue-500/5 border border-blue-500/20 rounded-lg">
          <Info className="w-6 h-6 text-blue-400 shrink-0 mt-1" />
          <div>
            <h4 className="text-white font-medium mb-2">
              Votre cycle de facturation : {isAnnual ? 'Annuel' : 'Mensuel'}
            </h4>
            <p className="text-gray-300">
              Le cycle de facturation ne peut pas être modifié lors d'une mise à jour d'abonnement. 
              Pour changer de cycle, vous devez annuler votre abonnement actuel et en souscrire un nouveau.
            </p>
          </div>
        </div>

        <div className="space-y-2">
          <div className="flex justify-between items-baseline">
            <span className="text-gray-400">Nouveau montant</span>
            <div className="text-right">
              <span className="text-white font-medium text-xl">
                {displayMonthlyPrice.toFixed(2)}€
              </span>
              <span className="text-sm text-gray-400 ml-1">/mois</span>
            </div>
          </div>
          
          {isAnnual && (
            <div className="text-right">
              <p className="text-sm text-gray-400">
                soit {annualPrice.toFixed(2)}€ facturé annuellement
              </p>
            </div>
          )}
        </div>

        <p className="text-sm text-gray-400">
          La différence sera calculée au prorata de votre période de facturation en cours.
        </p>
      </div>
    </div>
  );
}