import React from 'react';
import { Building2, MapPin, AlertCircle } from 'lucide-react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useAuth } from '../../contexts/AuthContext';

interface CompanyFormProps {
  formData: {
    companyName: string;
    vatNumber: string;
    billingAddress: {
      line1: string;
      line2: string;
      city: string;
      postalCode: string;
      country: string;
    };
  };
  onChange: (data: any) => void;
  loading: boolean;
  error: string | null;
  onSubmit: (e: React.FormEvent) => void;
}

const validateForm = (formData: CompanyFormProps['formData']): string | null => {
  if (!formData.companyName.trim()) {
    return 'Le nom de l\'entreprise est requis';
  }
  if (!formData.billingAddress.line1.trim()) {
    return 'L\'adresse est requise';
  }
  if (!formData.billingAddress.city.trim()) {
    return 'La ville est requise';
  }
  if (!formData.billingAddress.postalCode.trim()) {
    return 'Le code postal est requis';
  }
  if (!formData.billingAddress.country.trim()) {
    return 'Le pays est requis';
  }
  return null;
};

export default function CompanyForm({ formData, onChange, loading, error, onSubmit }: CompanyFormProps) {
  const { currentUser } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const validationError = validateForm(formData);
    if (validationError) {
      throw new Error(validationError);
    }

    try {
      if (currentUser) {
        // Récupérer le cartId du localStorage
        const cartId = localStorage.getItem('cartId');
        
        // Récupérer les données du panier pour vérifier le code d'affiliation
        let resellerId = null;
        if (cartId) {
          const cartDoc = await getDoc(doc(db, 'carts', cartId));
          if (cartDoc.exists()) {
            const cartData = cartDoc.data();
            if (cartData.affiliateCode) {
              const affiliateDoc = await getDoc(doc(db, 'affiliates', cartData.affiliateCode));
              if (affiliateDoc.exists()) {
                resellerId = affiliateDoc.data().resellerId;
              }
            }
          }
        }

        // Créer ou mettre à jour le document du compte
        const accountRef = doc(db, 'accounts', currentUser.uid);
        const accountDoc = await getDoc(accountRef);
        
        const now = new Date().toISOString();
        const accountData = {
          company: {
            name: formData.companyName
          },
          contact: {
            email: currentUser.email,
            name: currentUser.displayName || ''
          },
          billing: {
            vatNumber: formData.vatNumber,
            billingAddress: formData.billingAddress,
            status: 'active'
          },
          role: 'client',
          type: 'end-user',
          status: 'active',
          licenses: [],
          cartId,
          resellerId,
          createdAt: accountDoc.exists() ? accountDoc.data().createdAt : now,
          updatedAt: now
        };

        await setDoc(accountRef, accountData, { merge: true });
      }

      // Continuer avec le processus de checkout
      onSubmit(e);
    } catch (err) {
      console.error('Error saving billing data:', err);
      throw err;
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-8">
      <div className="checkout-card">
        <div className="checkout-section-header">
          <Building2 className="checkout-section-icon" />
          <h3 className="checkout-section-title">Informations de l'entreprise</h3>
        </div>

        <div className="checkout-grid">
          <div className="checkout-full-width">
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Nom de l'entreprise <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={formData.companyName}
              onChange={(e) => onChange({ companyName: e.target.value })}
              required
              className="checkout-input"
              placeholder="Nom de votre entreprise"
            />
          </div>

          <div className="checkout-full-width">
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Numéro de TVA
            </label>
            <input
              type="text"
              value={formData.vatNumber}
              onChange={(e) => onChange({ vatNumber: e.target.value })}
              className="checkout-input"
              placeholder="FR12345678912"
            />
          </div>
        </div>
      </div>

      <div className="checkout-card">
        <div className="checkout-section-header">
          <MapPin className="checkout-section-icon" />
          <h3 className="checkout-section-title">Adresse de facturation</h3>
        </div>

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Adresse <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={formData.billingAddress.line1}
              onChange={(e) => onChange({
                billingAddress: { ...formData.billingAddress, line1: e.target.value }
              })}
              required
              className="checkout-input"
              placeholder="Numéro et nom de rue"
            />
          </div>

          <div>
            <input
              type="text"
              value={formData.billingAddress.line2}
              onChange={(e) => onChange({
                billingAddress: { ...formData.billingAddress, line2: e.target.value }
              })}
              className="checkout-input"
              placeholder="Complément d'adresse (optionnel)"
            />
          </div>

          <div className="checkout-grid">
            <input
              type="text"
              value={formData.billingAddress.city}
              onChange={(e) => onChange({
                billingAddress: { ...formData.billingAddress, city: e.target.value }
              })}
              required
              className="checkout-input"
              placeholder="Ville"
            />
            <input
              type="text"
              value={formData.billingAddress.postalCode}
              onChange={(e) => onChange({
                billingAddress: { ...formData.billingAddress, postalCode: e.target.value }
              })}
              required
              className="checkout-input"
              placeholder="Code postal"
            />
          </div>

          <div>
            <input
              type="text"
              value={formData.billingAddress.country}
              onChange={(e) => onChange({
                billingAddress: { ...formData.billingAddress, country: e.target.value }
              })}
              required
              className="checkout-input"
              placeholder="Pays"
            />
          </div>
        </div>
      </div>

      {error && (
        <div className="p-4 bg-red-500/10 border border-red-500 rounded-lg flex items-center gap-2 text-red-500">
          <AlertCircle className="w-5 h-5 shrink-0" />
          <p>{error}</p>
        </div>
      )}

      <button
        type="submit"
        disabled={loading}
        className="checkout-button"
      >
        {loading ? (
          <div className="flex items-center justify-center gap-2">
            <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
            <span>Enregistrement...</span>
          </div>
        ) : (
          'Continuer'
        )}
      </button>
    </form>
  );
}