import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Search, Eye, Building2, Settings, CreditCard, MapPin } from 'lucide-react';
import { Client } from '../types/client';
import AlertModal from '../components/AlertModal';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';

interface ClientDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  client: Client;
}

function ClientDetailsModal({ isOpen, onClose, client }: ClientDetailsModalProps) {
  const [activeTab, setActiveTab] = useState<'profile' | 'subscription' | 'machines' | 'billing'>('profile');

  if (!isOpen) return null;

  const tabs = [
    { id: 'profile', label: 'Profil', icon: Building2 },
    { id: 'subscription', label: 'Abonnement', icon: Settings },
    { id: 'machines', label: 'Machines', icon: Settings },
    { id: 'billing', label: 'Facturation', icon: CreditCard }
  ];

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="glass-card w-full max-w-2xl rounded-2xl">
        <div className="p-6 border-b border-gray-700">
          <h2 className="text-xl font-medium text-white">Détails du client</h2>
        </div>

        <div className="flex gap-2 p-4 border-b border-gray-700">
          {tabs.map(tab => {
            const Icon = tab.icon;
            return (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id as any)}
                className={`flex items-center gap-2 px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  activeTab === tab.id
                    ? 'bg-blue-500 text-white'
                    : 'text-gray-400 hover:text-white hover:bg-white/5'
                }`}
              >
                <Icon className="w-4 h-4" />
                {tab.label}
              </button>
            );
          })}
        </div>

        <div className="p-6">
          {activeTab === 'profile' && (
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-medium text-white mb-4">Informations générales</h3>
                <div className="space-y-4">
                  <div>
                    <label className="text-sm text-gray-400">Entreprise</label>
                    <p className="text-white">{client.companyName}</p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-400">Contact</label>
                    <p className="text-white">{client.contactName}</p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-400">Email</label>
                    <p className="text-white">{client.email}</p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-400">Téléphone</label>
                    <p className="text-white">{client.phone || 'Non renseigné'}</p>
                  </div>
                </div>
              </div>

              {client.address && (
                <div>
                  <h3 className="text-lg font-medium text-white mb-4">Adresse</h3>
                  <div className="space-y-2">
                    <p className="text-white">{client.address.line1}</p>
                    {client.address.line2 && <p className="text-white">{client.address.line2}</p>}
                    <p className="text-white">
                      {client.address.postalCode} {client.address.city}
                    </p>
                    <p className="text-white">{client.address.country}</p>
                  </div>
                </div>
              )}
            </div>
          )}

          {activeTab === 'subscription' && (
            <div className="space-y-6">
              <h3 className="text-lg font-medium text-white mb-4">Détails de l'abonnement</h3>
              <div className="space-y-4">
                <div>
                  <label className="text-sm text-gray-400">Plan actuel</label>
                  <p className="text-white">{client.plan === 'production' ? 'Production' : 'Connect'}</p>
                </div>
                <div>
                  <label className="text-sm text-gray-400">Cycle de facturation</label>
                  <p className="text-white">{client.billingCycle === 'monthly' ? 'Mensuel' : 'Annuel'}</p>
                </div>
                {client.subscriptionStartDate && (
                  <div>
                    <label className="text-sm text-gray-400">Date de début</label>
                    <p className="text-white">
                      {new Date(client.subscriptionStartDate).toLocaleDateString('fr-FR', {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric'
                      })}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}

          {activeTab === 'machines' && (
            <div className="space-y-6">
              <h3 className="text-lg font-medium text-white mb-4">Machines</h3>
              {client.licenses && client.licenses.length > 0 ? (
                <div className="space-y-3">
                  {client.licenses.map((license, index) => (
                    <div key={index} className="glass-card p-4 rounded-xl">
                      <div className="flex justify-between items-center">
                        <div>
                          <p className="text-white font-medium">{license.machineName}</p>
                          <p className="text-sm text-gray-400">Quantité: {license.quantity}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-400">Aucune machine</p>
              )}
            </div>
          )}

          {activeTab === 'billing' && (
            <div className="space-y-6">
              <h3 className="text-lg font-medium text-white mb-4">Informations de facturation</h3>
              <div className="space-y-4">
                <div>
                  <label className="text-sm text-gray-400">Numéro de TVA</label>
                  <p className="text-white">{client.vatNumber || 'Non renseigné'}</p>
                </div>
                {client.address && (
                  <div>
                    <label className="text-sm text-gray-400">Adresse de facturation</label>
                    <div className="mt-2 space-y-1">
                      <p className="text-white">{client.address.line1}</p>
                      {client.address.line2 && <p className="text-white">{client.address.line2}</p>}
                      <p className="text-white">
                        {client.address.postalCode} {client.address.city}
                      </p>
                      <p className="text-white">{client.address.country}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="p-6 border-t border-gray-700">
          <div className="flex justify-end">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-300 hover:text-white transition-colors"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

// Le reste du code ResellerDashboard reste inchangé...
export default function ResellerDashboard() {
  const [clients, setClients] = useState<Client[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const loadClients = async () => {
    if (!currentUser) return;

    try {
      setLoading(true);
      setError(null);

      const accountsRef = collection(db, 'accounts');
      const q = query(accountsRef, where('resellerId', '==', currentUser.uid));
      const snapshot = await getDocs(q);

      const loadedClients = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          companyName: data.company?.name || '',
          contactName: data.contact?.name || '',
          email: data.contact?.email || '',
          phone: data.contact?.phone || '',
          type: 'end-user',
          plan: data.billing?.plan || 'production',
          billingCycle: data.billing?.billingCycle || 'monthly',
          status: data.status || 'pending',
          createdAt: data.createdAt || new Date().toISOString(),
          subscriptionStartDate: data.billing?.subscriptionStartDate,
          userId: doc.id,
          resellerId: currentUser.uid,
          address: data.billing?.billingAddress || {
            line1: '',
            line2: '',
            city: '',
            postalCode: '',
            country: ''
          },
          vatNumber: data.billing?.vatNumber || '',
          licenses: data.licenses || []
        };
      });

      setClients(loadedClients);
    } catch (err) {
      console.error('Error loading clients:', err);
      setError('Erreur lors du chargement des clients');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!currentUser) {
      navigate('/');
      return;
    }
    loadClients();
  }, [currentUser, navigate]);

  const filteredClients = clients.filter(client => {
    const searchLower = searchQuery.toLowerCase();
    return (
      client.companyName.toLowerCase().includes(searchLower) ||
      client.contactName.toLowerCase().includes(searchLower) ||
      client.email.toLowerCase().includes(searchLower)
    );
  });

  return (
    <div className="min-h-screen p-6">
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-3xl font-medium text-white">Mes clients</h1>
        </div>

        <div className="glass-card rounded-2xl p-6">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-xl font-medium text-white">Liste des clients</h2>
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
              <input
                type="text"
                placeholder="Rechercher un client..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="pl-10 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white"
              />
            </div>
          </div>

          {loading ? (
            <div className="flex justify-center py-8">
              <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
            </div>
          ) : filteredClients.length === 0 ? (
            <div className="text-center py-8 text-gray-400">
              Aucun client trouvé
            </div>
          ) : (
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="text-left border-b border-gray-700">
                    <th className="pb-3 text-gray-400 font-medium">Entreprise</th>
                    <th className="pb-3 text-gray-400 font-medium">Contact</th>
                    <th className="pb-3 text-gray-400 font-medium">Email</th>
                    <th className="pb-3 text-gray-400 font-medium">Plan</th>
                    <th className="pb-3 text-gray-400 font-medium">Licences</th>
                    <th className="pb-3 text-gray-400 font-medium">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredClients.map((client) => (
                    <tr key={client.id} className="border-b border-gray-800">
                      <td className="py-4 text-white">{client.companyName}</td>
                      <td className="py-4 text-white">{client.contactName}</td>
                      <td className="py-4 text-white">{client.email}</td>
                      <td className="py-4">
                        <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                          client.plan === 'production'
                            ? 'bg-blue-500/10 text-blue-500'
                            : 'bg-orange-500/10 text-orange-500'
                        }`}>
                          {client.plan === 'production' ? 'Production' : 'Connect'}
                        </span>
                      </td>
                      <td className="py-4">
                        <span className="text-gray-300">
                          {client.licenses?.length || 0} machine{client.licenses?.length !== 1 ? 's' : ''}
                        </span>
                      </td>
                      <td className="py-4">
                        <button
                          onClick={() => setSelectedClient(client)}
                          className="p-2 text-gray-400 hover:text-white transition-colors flex items-center gap-1"
                        >
                          <Eye className="w-4 h-4" />
                          Voir
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      {selectedClient && (
        <ClientDetailsModal
          isOpen={true}
          onClose={() => setSelectedClient(null)}
          client={selectedClient}
        />
      )}

      {error && (
        <AlertModal
          isOpen={!!error}
          onClose={() => setError(null)}
          title="Information"
          message={error}
        />
      )}
    </div>
  );
}