import React from 'react';
import { PlanType } from '../../../types/pricing';
import PricingPlanSelector from '../../pricing/PricingPlanSelector';

interface UpdatePlanSectionProps {
  selectedPlan: PlanType;
  onPlanChange: (plan: PlanType) => void;
  machines: Array<{
    code: string;
    quantity: number;
  }>;
}

export default function UpdatePlanSection({
  selectedPlan,
  onPlanChange,
  machines
}: UpdatePlanSectionProps) {
  return (
    <section>
      <PricingPlanSelector
        selectedPlan={selectedPlan}
        onPlanChange={onPlanChange}
        machines={machines}
        hidePrice={true}
      />
    </section>
  );
}