import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import AuthModal from '../components/auth/AuthModal';
import Dashboard from '../pages/Dashboard';
import AdminPanel from '../pages/AdminPanel';
import OrderDetails from '../components/admin/OrderDetails';
import ResellerDashboard from '../pages/ResellerDashboard';
import Support from '../pages/Support';
import Pricing from '../pages/Pricing';
import Checkout from '../pages/Checkout';
import CartPage from '../pages/CartPage';
import OrderConfirmation from '../pages/OrderConfirmation';
import ClientSubscriptionUpdate from '../pages/ClientSubscriptionUpdate';
import SubscriptionUpdateSuccess from '../pages/SubscriptionUpdateSuccess';
import Index from '../pages/Index';
import Login from '../pages/Login';

interface AppRoutesProps {
  showAuthModal: boolean;
  onCloseAuthModal: () => void;
  onOpenAuthModal: () => void;
}

function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const { currentUser } = useAuth();
  return currentUser ? <>{children}</> : <Index />;
}

function AdminRoute({ children }: { children: React.ReactNode }) {
  const { currentUser, isAdmin } = useAuth();
  
  if (!currentUser) {
    return <Index />;
  }

  if (!isAdmin) {
    return <Dashboard />;
  }

  return <>{children}</>;
}

function ResellerRoute({ children }: { children: React.ReactNode }) {
  const { currentUser, userData } = useAuth();
  
  if (!currentUser) {
    return <Index />;
  }

  if (userData?.role !== 'reseller') {
    return <Dashboard />;
  }

  return <>{children}</>;
}

export default function AppRoutes({ showAuthModal, onCloseAuthModal, onOpenAuthModal }: AppRoutesProps) {
  return (
    <>
      <Routes>
        <Route path="/" element={<Index onOpenAuth={onOpenAuthModal} />} />
        <Route path="/login" element={<Login onClose={onCloseAuthModal} />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/support" element={<Support />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/success" element={<OrderConfirmation />} />
        
        <Route path="/dashboard" element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        } />
        
        <Route path="/subscription/update" element={
          <ProtectedRoute>
            <ClientSubscriptionUpdate />
          </ProtectedRoute>
        } />

        <Route path="/subscription/update/success" element={
          <ProtectedRoute>
            <SubscriptionUpdateSuccess />
          </ProtectedRoute>
        } />
        
        <Route path="/admin" element={
          <AdminRoute>
            <AdminPanel />
          </AdminRoute>
        } />

        <Route path="/admin/orders/:orderId" element={
          <AdminRoute>
            <OrderDetails />
          </AdminRoute>
        } />

        <Route path="/reseller" element={
          <ResellerRoute>
            <ResellerDashboard />
          </ResellerRoute>
        } />

        <Route path="*" element={<Index onOpenAuth={onOpenAuthModal} />} />
      </Routes>

      <AuthModal 
        isOpen={showAuthModal}
        onClose={onCloseAuthModal}
        onAuthSuccess={onCloseAuthModal}
      />
    </>
  );
}