import React from 'react';
import { Package2 } from 'lucide-react';

interface MachinesListProps {
  items: Array<{
    name: string;
    quantity: number;
  }>;
}

export default function MachinesList({ items }: MachinesListProps) {
  return (
    <div className="glass-card p-4 rounded-xl">
      <h3 className="text-lg font-medium text-white mb-4 flex items-center gap-2">
        <Package2 className="w-5 h-5 text-blue-500" />
        Machines commandées
      </h3>
      {items && items.length > 0 ? (
        <div className="space-y-3">
          {items.map((item, index) => (
            <div key={index} className="flex justify-between items-center p-3 bg-gray-800/50 rounded-lg">
              <div>
                <p className="text-white font-medium">{item.name}</p>
                <p className="text-sm text-gray-400">Quantité: {item.quantity}</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-400">Aucune machine</p>
      )}
    </div>
  );
}