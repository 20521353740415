import React from 'react';

interface ModalFooterProps {
  onClose: () => void;
  onSave?: () => void;
  mode?: 'create' | 'edit';
  showSave?: boolean;
}

export default function ModalFooter({ onClose, onSave, mode, showSave = false }: ModalFooterProps) {
  return (
    <div className="p-6 border-t border-gray-700">
      <div className="flex justify-end gap-3">
        <button
          onClick={onClose}
          className="px-4 py-2 text-gray-300 hover:text-white transition-colors"
        >
          Annuler
        </button>
        {showSave && onSave && (
          <button
            onClick={onSave}
            className="glow-button"
          >
            {mode === 'create' ? 'Créer' : 'Enregistrer'}
          </button>
        )}
      </div>
    </div>
  );
}