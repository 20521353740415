import axios from 'axios';

interface UpdateSubscriptionParams {
  configId: string;
}

export const updateStripeSubscription = async (
  subscriptionId: string,
  params: UpdateSubscriptionParams
): Promise<void> => {
  try {
    await axios.post('/.netlify/functions/update-subscription', {
      subscriptionId,
      configId: params.configId
    });
  } catch (error) {
    console.error('Error updating Stripe subscription:', error);
    throw new Error('Erreur lors de la mise à jour de l\'abonnement Stripe');
  }
};

export const cancelStripeSubscription = async (subscriptionId: string): Promise<boolean> => {
  try {
    await axios.post('/.netlify/functions/cancel-subscription', {
      subscriptionId
    });
    return true;
  } catch (error) {
    console.error('Error canceling Stripe subscription:', error);
    throw new Error('Erreur lors de l\'annulation de l\'abonnement Stripe');
  }
};

export const getStripeSession = async (sessionId: string) => {
  try {
    const response = await axios.get('/.netlify/functions/get-session', {
      params: { sessionId }
    });
    return response.data;
  } catch (error) {
    console.error('Error getting Stripe session:', error);
    throw new Error('Erreur lors de la récupération de la session Stripe');
  }
};

export const updatePaymentMethod = async (customerId: string) => {
  try {
    const response = await axios.post('/.netlify/functions/update-payment-method', {
      customerId
    });
    return response.data;
  } catch (error) {
    console.error('Error updating payment method:', error);
    throw new Error('Erreur lors de la mise à jour du moyen de paiement');
  }
};

export const getCustomerInvoices = async (customerId: string) => {
  try {
    const response = await axios.get('/.netlify/functions/get-customer-invoices', {
      params: { customerId }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching customer invoices:', error);
    throw new Error('Erreur lors de la récupération des factures');
  }
};

export const getPaymentMethod = async (sessionId: string) => {
  try {
    const response = await axios.get('/.netlify/functions/get-payment-method', {
      params: { sessionId }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching payment method:', error);
    throw new Error('Erreur lors de la récupération du moyen de paiement');
  }
};