import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyCwnTjjIfjdyhOku6bbh7MKL1c67Qnt-e0",
  authDomain: "ripee-e9ef2.firebaseapp.com",
  projectId: "ripee-e9ef2",
  storageBucket: "ripee-e9ef2.firebasestorage.app",
  messagingSenderId: "715096184266",
  appId: "1:715096184266:web:a3ea0c93bc61fbbc27ab33",
  measurementId: "G-GQ2TNSG4BF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
export const db = getFirestore(app);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);

// Enable persistence for offline support
import { enableIndexedDbPersistence } from 'firebase/firestore';

try {
  enableIndexedDbPersistence(db, {
    synchronizeTabs: true
  }).catch((err) => {
    if (err.code === 'failed-precondition') {
      console.warn('Multiple tabs open, persistence can only be enabled in one tab at a time.');
    } else if (err.code === 'unimplemented') {
      console.warn('The current browser doesn\'t support persistence.');
    }
  });
} catch (err) {
  console.warn('Error enabling persistence:', err);
}