import { User } from 'firebase/auth';

interface AuthSession {
  userId: string;
  email: string | null;
  displayName: string | null;
  isAdmin: boolean;
  lastLogin: string;
}

export const saveAuthSession = (user: User, isAdmin: boolean = false): void => {
  const session: AuthSession = {
    userId: user.uid,
    email: user.email,
    displayName: user.displayName,
    isAdmin,
    lastLogin: new Date().toISOString()
  };

  // Set secure cookies
  document.cookie = `user_logged_in=true; path=/; domain=ripee.fr; secure`;
  document.cookie = `user_token=${JSON.stringify(session)}; path=/; domain=ripee.fr; secure`;

  // Dispatch custom event for static site
  const event = new CustomEvent('ripee:auth', { 
    detail: { 
      authenticated: true,
      user: session 
    }
  });
  window.dispatchEvent(event);
};

export const clearAuthSession = (): void => {
  // Clear cookies by setting expiration to past date
  document.cookie = 'user_logged_in=; path=/; domain=ripee.fr; secure; expires=Thu, 01 Jan 1970 00:00:00 GMT';
  document.cookie = 'user_token=; path=/; domain=ripee.fr; secure; expires=Thu, 01 Jan 1970 00:00:00 GMT';

  // Notify static site about logout
  const event = new CustomEvent('ripee:auth', {
    detail: { 
      authenticated: false,
      user: null
    }
  });
  window.dispatchEvent(event);
};

export const getAuthSession = (): AuthSession | null => {
  const cookies = document.cookie.split(';');
  const userToken = cookies
    .find(cookie => cookie.trim().startsWith('user_token='))
    ?.split('=')[1];

  if (!userToken) return null;
  
  try {
    return JSON.parse(decodeURIComponent(userToken)) as AuthSession;
  } catch {
    return null;
  }
};

export const isAuthenticated = (): boolean => {
  const cookies = document.cookie.split(';');
  return cookies.some(cookie => 
    cookie.trim().startsWith('user_logged_in=true')
  );
};