import React from 'react';
import { Calendar, User } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

interface GeneralInformationProps {
  order: any;
  accountData: any;
  resellerData: any;
}

export default function GeneralInformation({ order, accountData, resellerData }: GeneralInformationProps) {
  return (
    <div className="glass-card rounded-2xl p-6">
      <div className="grid md:grid-cols-2 gap-6">
        <div>
          <h3 className="text-lg font-medium text-white flex items-center gap-2 mb-4">
            <Calendar className="w-5 h-5 text-blue-500" />
            Informations générales
          </h3>
          <div className="space-y-3">
            <div>
              <span className="text-sm text-gray-400">ID de commande</span>
              <p className="text-white text-sm">{order.id}</p>
            </div>
            <div>
              <span className="text-sm text-gray-400">Date</span>
              <p className="text-white text-sm">
                {format(new Date(order.createdAt), 'dd MMMM yyyy HH:mm', { locale: fr })}
              </p>
            </div>
            {resellerData && (
              <div>
                <span className="text-sm text-gray-400">Revendeur</span>
                <p className="text-white text-sm">{resellerData.company?.name}</p>
                <p className="text-xs text-gray-400">{resellerData.contact?.name}</p>
              </div>
            )}
          </div>
        </div>

        <div>
          <h3 className="text-lg font-medium text-white flex items-center gap-2 mb-4">
            <User className="w-5 h-5 text-blue-500" />
            Informations client
          </h3>
          <div className="space-y-3">
            <div>
              <span className="text-sm text-gray-400">Société</span>
              <p className="text-white text-sm font-medium">{accountData?.company?.name || 'N/A'}</p>
              <p className="text-xs text-gray-400">{accountData?.contact?.name || 'N/A'}</p>
            </div>
            <div>
              <span className="text-sm text-gray-400">Email</span>
              <p className="text-white text-sm">{accountData?.contact?.email || order.customerEmail || 'N/A'}</p>
            </div>
            {accountData?.contact?.phone && (
              <div>
                <span className="text-sm text-gray-400">Téléphone</span>
                <p className="text-white text-sm">{accountData.contact.phone}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}