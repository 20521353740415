import React, { useState, useEffect } from 'react';
import { Receipt, Download, AlertCircle } from 'lucide-react';
import { getCustomerInvoices } from '../../../services/stripeService';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

interface Invoice {
  id: string;
  number: string;
  amount: number;
  status: string;
  created: number;
  hostedInvoiceUrl: string;
  pdfUrl: string;
}

interface InvoicesSectionProps {
  customerId: string;
}

export default function InvoicesSection({ customerId }: InvoicesSectionProps) {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadInvoices = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await getCustomerInvoices(customerId);
        setInvoices(data);
      } catch (err) {
        setError('Erreur lors du chargement des factures');
        console.error('Error loading invoices:', err);
      } finally {
        setLoading(false);
      }
    };

    loadInvoices();
  }, [customerId]);

  if (loading) {
    return (
      <div className="glass-card rounded-2xl p-6">
        <div className="flex justify-center py-8">
          <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
        </div>
      </div>
    );
  }

  return (
    <div className="glass-card rounded-2xl p-6">
      <h3 className="text-lg font-medium text-white flex items-center gap-2 mb-6">
        <Receipt className="w-5 h-5 text-blue-500" />
        Factures
      </h3>

      {error ? (
        <div className="flex items-center gap-2 text-red-500 bg-red-500/10 p-4 rounded-lg">
          <AlertCircle className="w-5 h-5 shrink-0" />
          <p>{error}</p>
        </div>
      ) : invoices.length === 0 ? (
        <p className="text-center text-gray-400 py-6">
          Aucune facture disponible
        </p>
      ) : (
        <div className="space-y-4">
          {invoices.map((invoice) => (
            <div
              key={invoice.id}
              className="flex items-center justify-between p-4 bg-gray-800/50 rounded-lg"
            >
              <div>
                <p className="text-white font-medium">
                  Facture #{invoice.number}
                </p>
                <p className="text-sm text-gray-400">
                  {format(new Date(invoice.created * 1000), 'dd MMMM yyyy', { locale: fr })}
                </p>
                <p className="text-sm text-gray-400">
                  {(invoice.amount / 100).toFixed(2)}€
                </p>
              </div>
              <div className="flex items-center gap-2">
                <a
                  href={invoice.hostedInvoiceUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-2 text-gray-400 hover:text-white transition-colors"
                >
                  Voir
                </a>
                <a
                  href={invoice.pdfUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-2 text-gray-400 hover:text-white transition-colors"
                >
                  <Download className="w-5 h-5" />
                </a>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}