import React from 'react';
import { Plus } from 'lucide-react';
import { Machine } from '../../types/machine';
import { CartItemType } from '../../types/cart';

interface MachineSearchListProps {
  machines: Machine[];
  loading: boolean;
  error: string | null;
  selectedItems: CartItemType[];
  onAdd: (machine: Machine) => void;
  onRemove: (id: string) => void;
  onUpdateQuantity: (id: string, quantity: number) => void;
}

export default function MachineSearchList({
  machines,
  loading,
  error,
  selectedItems,
  onAdd
}: MachineSearchListProps) {
  if (loading) {
    return (
      <div className="w-full bg-gray-900/95 backdrop-blur-sm border border-gray-700 rounded-xl shadow-xl p-4 text-center">
        <div className="w-6 h-6 border-2 border-[#e9571c] border-t-transparent rounded-full animate-spin mx-auto"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full bg-gray-900/95 backdrop-blur-sm border border-red-500 rounded-xl shadow-xl p-4 text-red-500 text-sm">
        {error}
      </div>
    );
  }

  if (machines.length === 0) {
    return (
      <div className="w-full bg-gray-900/95 backdrop-blur-sm border border-gray-700 rounded-xl shadow-xl p-4 text-center text-gray-400 text-sm">
        Aucune machine trouvée
      </div>
    );
  }

  return (
    <div className="w-full bg-gray-900/95 backdrop-blur-sm border border-gray-700 rounded-xl shadow-xl overflow-hidden max-h-[300px] overflow-y-auto">
      {machines.map((machine) => {
        const isSelected = selectedItems.some(item => item.id === machine.id);
        
        return (
          <button
            key={machine.id}
            onClick={(e) => {
              e.stopPropagation();
              !isSelected && onAdd(machine);
            }}
            disabled={isSelected}
            className={`
              w-full px-4 py-3 text-left transition-colors border-b border-gray-700/50 last:border-0
              ${isSelected 
                ? 'opacity-50 cursor-not-allowed bg-gray-800/50'
                : 'hover:bg-[#e9571c]/10 cursor-pointer'
              }
            `}
          >
            <div className="flex items-center justify-between">
              <h3 className="text-white font-medium">{machine.name}</h3>
              {!isSelected && (
                <div className="flex items-center gap-2">
                  <span className="px-3 py-1 rounded-lg bg-[#e9571c] text-white text-sm font-medium hover:bg-[#d44c17] transition-colors">
                    Ajouter
                  </span>
                </div>
              )}
            </div>
          </button>
        );
      })}
    </div>
  );
}