import React, { useState, useEffect } from 'react';
import { Search, Plus, X } from 'lucide-react';

interface Machine {
  id: string;
  name: string;
  code: string;
}

interface UpdateMachinesSectionProps {
  machines: Machine[];
  selectedMachines: any[];
  onAddMachine: (machine: Machine) => void;
  onUpdateQuantity: (id: string, quantity: number) => void;
  onRemoveMachine: (id: string) => void;
  loading?: boolean;
  error?: string | null;
}

export default function UpdateMachinesSection({
  machines,
  selectedMachines,
  onAddMachine,
  onUpdateQuantity,
  onRemoveMachine,
  loading,
  error
}: UpdateMachinesSectionProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [filteredMachines, setFilteredMachines] = useState<Machine[]>([]);

  useEffect(() => {
    if (!searchQuery.trim()) {
      setFilteredMachines([]);
      return;
    }

    const query = searchQuery.toLowerCase();
    const results = machines.filter(machine => {
      const nameMatch = machine.name.toLowerCase().includes(query);
      const codeMatch = machine.code.toLowerCase().includes(query);
      const notAlreadySelected = !selectedMachines.some(m => m.id === machine.id);
      return (nameMatch || codeMatch) && notAlreadySelected;
    });

    setFilteredMachines(results);
  }, [searchQuery, machines, selectedMachines]);

  const handleSearchFocus = () => {
    setShowResults(true);
  };

  const handleSearchBlur = () => {
    // Petit délai pour permettre le clic sur les résultats
    setTimeout(() => setShowResults(false), 200);
  };

  const handleAddMachine = (machine: Machine) => {
    onAddMachine(machine);
    setSearchQuery('');
    setShowResults(false);
  };

  return (
    <div className="space-y-6">
      <div className="relative">
        <div className="relative">
          <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="text"
            placeholder="Rechercher une machine..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onFocus={handleSearchFocus}
            onBlur={handleSearchBlur}
            className="w-full pl-12 pr-4 py-3 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>

        {showResults && searchQuery && (
          <div className="absolute z-50 w-full mt-2 bg-gray-800 border border-gray-700 rounded-lg shadow-xl max-h-[300px] overflow-y-auto">
            {loading ? (
              <div className="p-4 text-center text-gray-400">
                Chargement...
              </div>
            ) : filteredMachines.length === 0 ? (
              <div className="p-4 text-center text-gray-400">
                Aucune machine trouvée
              </div>
            ) : (
              filteredMachines.map(machine => (
                <button
                  key={machine.id}
                  onClick={() => handleAddMachine(machine)}
                  className="w-full px-4 py-3 text-left hover:bg-gray-700 transition-colors flex items-center justify-between"
                >
                  <div>
                    <div className="text-white font-medium">{machine.name}</div>
                    <div className="text-sm text-gray-400">Code: {machine.code}</div>
                  </div>
                  <Plus className="w-5 h-5 text-blue-400" />
                </button>
              ))
            )}
          </div>
        )}
      </div>

      {selectedMachines.length > 0 && (
        <div className="space-y-3">
          {selectedMachines.map((machine) => (
            <div
              key={machine.id}
              className="glass-card p-4 rounded-xl flex items-center justify-between"
            >
              <div>
                <h4 className="font-medium text-white">{machine.name}</h4>
                <p className="text-sm text-gray-400">Code: {machine.code}</p>
              </div>
              <div className="flex items-center gap-4">
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => onUpdateQuantity(machine.id, Math.max(1, machine.quantity - 1))}
                    className="w-8 h-8 flex items-center justify-center rounded-lg bg-gray-700 text-white hover:bg-gray-600 transition-colors"
                  >
                    -
                  </button>
                  <input
                    type="number"
                    min="1"
                    value={machine.quantity}
                    onChange={(e) => {
                      const value = parseInt(e.target.value);
                      if (!isNaN(value) && value > 0) {
                        onUpdateQuantity(machine.id, value);
                      }
                    }}
                    className="w-16 px-2 py-1 bg-gray-700 border border-gray-600 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent text-center"
                  />
                  <button
                    onClick={() => onUpdateQuantity(machine.id, machine.quantity + 1)}
                    className="w-8 h-8 flex items-center justify-center rounded-lg bg-gray-700 text-white hover:bg-gray-600 transition-colors"
                  >
                    +
                  </button>
                </div>
                <button
                  onClick={() => onRemoveMachine(machine.id)}
                  className="p-2 text-red-400 hover:text-red-300 hover:bg-red-400/10 rounded-lg transition-colors"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {error && (
        <div className="p-4 bg-red-500/10 border border-red-500 rounded-lg text-red-500">
          {error}
        </div>
      )}
    </div>
  );
}