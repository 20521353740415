import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../config/firebase';

export interface Reseller {
  id: string;
  companyName: string;
  contactName: string;
  email: string;
}

export const getResellers = async (): Promise<Reseller[]> => {
  try {
    const accountsRef = collection(db, 'accounts');
    const q = query(accountsRef, where('role', '==', 'reseller'));
    const snapshot = await getDocs(q);
    
    return snapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        companyName: data.company?.name || '',
        contactName: data.contact?.name || '',
        email: data.contact?.email || ''
      };
    });
  } catch (error) {
    console.error('Error fetching resellers:', error);
    throw new Error('Erreur lors de la récupération des revendeurs');
  }
};