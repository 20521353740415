import React from 'react';
import { User, AlertCircle } from 'lucide-react';
import { createCheckoutAccount } from '../../services/checkoutAuthService';
import { useNavigate } from 'react-router-dom';

interface AccountFormProps {
  formData: {
    contactName: string;
    email: string;
    phone: string;
    password: string;
    confirmPassword: string;
  };
  onChange: (data: any) => void;
  loading: boolean;
  error: string | null;
  onError: (error: string) => void;
}

const getErrorMessage = (error: string): string => {
  if (error.includes('email-already-in-use')) {
    return 'Cette adresse email est déjà utilisée. Veuillez vous connecter ou utiliser une autre adresse.';
  }
  if (error.includes('invalid-email')) {
    return 'L\'adresse email n\'est pas valide.';
  }
  if (error.includes('weak-password')) {
    return 'Le mot de passe doit contenir au moins 6 caractères.';
  }
  return 'Une erreur est survenue. Veuillez réessayer.';
};

export default function AccountForm({ 
  formData, 
  onChange, 
  loading, 
  error,
  onError
}: AccountFormProps) {
  const navigate = useNavigate();

  const validateForm = (): string | null => {
    if (!formData.contactName.trim()) {
      return 'Le nom complet est requis';
    }
    if (!formData.email.trim()) {
      return 'L\'adresse email est requise';
    }
    if (!formData.password) {
      return 'Le mot de passe est requis';
    }
    if (formData.password.length < 6) {
      return 'Le mot de passe doit contenir au moins 6 caractères';
    }
    if (formData.password !== formData.confirmPassword) {
      return 'Les mots de passe ne correspondent pas';
    }
    return null;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const validationError = validateForm();
    if (validationError) {
      onError(validationError);
      return;
    }

    try {
      await createCheckoutAccount(
        formData.email,
        formData.password,
        formData.contactName,
        formData.phone
      );

      navigate('/checkout?step=company');
    } catch (err) {
      const errorMessage = err instanceof Error ? getErrorMessage(err.message) : 'Une erreur est survenue';
      onError(errorMessage);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-8">
      <div className="checkout-card">
        <div className="checkout-section-header">
          <User className="checkout-section-icon" />
          <h3 className="checkout-section-title">Informations personnelles</h3>
        </div>

        <div className="checkout-grid">
          <div className="checkout-full-width">
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Nom complet <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={formData.contactName}
              onChange={(e) => onChange({ contactName: e.target.value })}
              required
              className="checkout-input"
              placeholder="Jean Dupont"
            />
          </div>

          <div className="checkout-full-width">
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Email <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              value={formData.email}
              onChange={(e) => onChange({ email: e.target.value })}
              required
              className="checkout-input"
              placeholder="jean.dupont@exemple.fr"
            />
          </div>

          <div className="checkout-full-width">
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Téléphone
            </label>
            <input
              type="tel"
              value={formData.phone}
              onChange={(e) => onChange({ phone: e.target.value })}
              className="checkout-input"
              placeholder="01 23 45 67 89"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Mot de passe <span className="text-red-500">*</span>
            </label>
            <input
              type="password"
              value={formData.password}
              onChange={(e) => onChange({ password: e.target.value })}
              required
              className="checkout-input"
              placeholder="••••••••"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Confirmer le mot de passe <span className="text-red-500">*</span>
            </label>
            <input
              type="password"
              value={formData.confirmPassword}
              onChange={(e) => onChange({ confirmPassword: e.target.value })}
              required
              className="checkout-input"
              placeholder="••••••••"
            />
          </div>
        </div>
      </div>

      {error && (
        <div className="p-4 bg-red-500/10 border border-red-500 rounded-lg flex items-center gap-2 text-red-500">
          <AlertCircle className="w-5 h-5 shrink-0" />
          <p>{error}</p>
        </div>
      )}

      <button
        type="submit"
        disabled={loading}
        className="checkout-button"
      >
        {loading ? (
          <div className="flex items-center justify-center gap-2">
            <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
            <span>Création du compte...</span>
          </div>
        ) : (
          'Continuer'
        )}
      </button>
    </form>
  );
}