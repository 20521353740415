import React from 'react';
import { ClientFormData } from '../../../types/client';

interface ProfileFormProps {
  formData: ClientFormData;
  onChange: (data: ClientFormData) => void;
  mode: 'create' | 'edit';
  resellers: any[];
}

export default function ProfileForm({ formData, onChange, mode, resellers }: ProfileFormProps) {
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Type de compte
          </label>
          <select
            value={formData.type}
            onChange={(e) => onChange({
              ...formData,
              type: e.target.value as 'end-user' | 'reseller' | 'client-reseller',
              resellerId: e.target.value === 'client-reseller' ? formData.resellerId : ''
            })}
            className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            <option value="end-user">Client direct</option>
            <option value="reseller">Revendeur</option>
            <option value="client-reseller">Client via revendeur</option>
          </select>
        </div>

        {formData.type === 'client-reseller' && (
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Revendeur
            </label>
            <select
              value={formData.resellerId}
              onChange={(e) => onChange({
                ...formData,
                resellerId: e.target.value
              })}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              required={formData.type === 'client-reseller'}
            >
              <option value="">Sélectionner un revendeur</option>
              {resellers.map(reseller => (
                <option key={reseller.id} value={reseller.id}>
                  {reseller.companyName}
                </option>
              ))}
            </select>
          </div>
        )}

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Nom de l'entreprise
          </label>
          <input
            type="text"
            value={formData.companyName}
            onChange={(e) => onChange({
              ...formData,
              companyName: e.target.value
            })}
            className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Nom du contact
          </label>
          <input
            type="text"
            value={formData.contactName}
            onChange={(e) => onChange({
              ...formData,
              contactName: e.target.value
            })}
            className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Email
          </label>
          <input
            type="email"
            value={formData.email}
            onChange={(e) => onChange({
              ...formData,
              email: e.target.value
            })}
            className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            required
            disabled={mode === 'edit'}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Téléphone
          </label>
          <input
            type="tel"
            value={formData.phone}
            onChange={(e) => onChange({
              ...formData,
              phone: e.target.value
            })}
            className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Numéro de TVA
          </label>
          <input
            type="text"
            value={formData.vatNumber}
            onChange={(e) => onChange({
              ...formData,
              vatNumber: e.target.value
            })}
            className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-300 mb-1">
          Adresse
        </label>
        <div className="space-y-4">
          <input
            type="text"
            placeholder="Ligne 1"
            value={formData.address.line1}
            onChange={(e) => onChange({
              ...formData,
              address: {
                ...formData.address,
                line1: e.target.value
              }
            })}
            className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <input
            type="text"
            placeholder="Ligne 2 (optionnel)"
            value={formData.address.line2}
            onChange={(e) => onChange({
              ...formData,
              address: {
                ...formData.address,
                line2: e.target.value
              }
            })}
            className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <div className="grid grid-cols-3 gap-4">
            <input
              type="text"
              placeholder="Ville"
              value={formData.address.city}
              onChange={(e) => onChange({
                ...formData,
                address: {
                  ...formData.address,
                  city: e.target.value
                }
              })}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <input
              type="text"
              placeholder="Code postal"
              value={formData.address.postalCode}
              onChange={(e) => onChange({
                ...formData,
                address: {
                  ...formData.address,
                  postalCode: e.target.value
                }
              })}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <input
              type="text"
              placeholder="Pays"
              value={formData.address.country}
              onChange={(e) => onChange({
                ...formData,
                address: {
                  ...formData.address,
                  country: e.target.value
                }
              })}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
        </div>
      </div>
    </div>
  );
}