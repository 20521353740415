import { collection, doc, getDoc, getDocs, query, where, setDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { AffiliateCode, AffiliateDiscount } from '../types/affiliate';

export const verifyAffiliateCode = async (code: string): Promise<AffiliateDiscount> => {
  try {
    const affiliateRef = doc(db, 'affiliates', code);
    const affiliateDoc = await getDoc(affiliateRef);
    
    if (affiliateDoc.exists()) {
      const data = affiliateDoc.data() as AffiliateCode;
      return {
        amount: data.discountAmount,
        isValid: true,
        resellerId: data.resellerId
      };
    }
    
    return {
      amount: 0,
      isValid: false
    };
  } catch (error) {
    console.error('Error verifying affiliate code:', error);
    throw error;
  }
};

export const createAffiliateCode = async (
  resellerId: string, 
  code: string,
  discountAmount: number
): Promise<void> => {
  try {
    const affiliateRef = doc(db, 'affiliates', code);
    await setDoc(affiliateRef, {
      resellerId,
      code,
      discountAmount,
      createdAt: new Date().toISOString()
    });
  } catch (error) {
    console.error('Error creating affiliate code:', error);
    throw error;
  }
};

export const deleteAffiliateCode = async (code: string): Promise<void> => {
  try {
    const affiliateRef = doc(db, 'affiliates', code);
    await deleteDoc(affiliateRef);
  } catch (error) {
    console.error('Error deleting affiliate code:', error);
    throw error;
  }
};

export const getAffiliatesByReseller = async (resellerId: string) => {
  try {
    const affiliatesRef = collection(db, 'affiliates');
    const q = query(affiliatesRef, where('resellerId', '==', resellerId));
    const snapshot = await getDocs(q);
    
    return snapshot.docs.map(doc => ({
      code: doc.id,
      ...doc.data()
    })) as AffiliateCode[];
  } catch (error) {
    console.error('Error fetching affiliate codes:', error);
    throw error;
  }
};