import React from 'react';
import { ShoppingBag } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function EmptyCart() {
  const navigate = useNavigate();

  return (
    <div className="p-6 flex flex-col items-center justify-center">
      <div className="glass-card p-8 rounded-2xl text-center max-w-md">
        <ShoppingBag className="w-16 h-16 text-gray-400 mx-auto mb-4" />
        <h2 className="text-2xl font-medium text-white mb-4">Votre panier est vide</h2>
        <p className="text-gray-400 mb-6">
          Découvrez nos machines et commencez à configurer votre solution d'impression.
        </p>
        <button
          onClick={() => navigate('/pricing')}
          className="glow-button"
        >
          Découvrir nos machines
        </button>
      </div>
    </div>
  );
}