import React from 'react';
import { Settings } from 'lucide-react';

export default function SettingsPanel() {
  return (
    <div className="space-y-6">
      <div className="flex items-center gap-3">
        <Settings className="w-6 h-6 text-blue-500" />
        <h2 className="text-2xl font-medium text-white">Paramètres</h2>
      </div>

      <div className="text-center py-8 text-gray-400">
        Paramètres en cours de développement
      </div>
    </div>
  );
}