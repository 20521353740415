import React, { useState } from 'react';
import { Send, Mail, Phone, MessageSquare, AlertCircle } from 'lucide-react';

export default function Support() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [status, setStatus] = useState<'idle' | 'sending' | 'success' | 'error'>('idle');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus('sending');
    
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      setStatus('success');
      setFormData({ name: '', email: '', subject: '', message: '' });
    } catch (error) {
      setStatus('error');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  return (
    <div className="min-h-screen p-4 sm:p-6">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col items-center text-center mb-12">
          <MessageSquare className="w-12 h-12 sm:w-16 sm:h-16 mb-4 sm:mb-6 text-sky-400" />
          <h1 className="title-font text-3xl sm:text-5xl font-medium text-white mb-4">
            Support & Contact
          </h1>
          <p className="text-gray-400 max-w-2xl">
            Notre équipe est là pour vous aider. Remplissez le formulaire ci-dessous et nous vous répondrons dans les plus brefs délais.
          </p>
        </div>

        <div className="grid lg:grid-cols-12 gap-8">
          {/* Contact Info */}
          <div className="lg:col-span-4">
            <div className="glass-card rounded-2xl p-6 space-y-6">
              <div className="flex items-start gap-4">
                <div className="bg-[#3B82F6] bg-opacity-10 p-3 rounded-xl">
                  <Mail className="w-6 h-6 text-[#3B82F6]" />
                </div>
                <div>
                  <h3 className="text-white font-medium mb-1">Email</h3>
                  <p className="text-gray-400">support@ripee.fr</p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <div className="bg-[#3B82F6] bg-opacity-10 p-3 rounded-xl">
                  <Phone className="w-6 h-6 text-[#3B82F6]" />
                </div>
                <div>
                  <h3 className="text-white font-medium mb-1">Téléphone</h3>
                  <p className="text-gray-400">+33 (0)1 23 45 67 89</p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <div className="bg-[#3B82F6] bg-opacity-10 p-3 rounded-xl">
                  <MessageSquare className="w-6 h-6 text-[#3B82F6]" />
                </div>
                <div>
                  <h3 className="text-white font-medium mb-1">Support</h3>
                  <p className="text-gray-400">Lun-Ven, 9h-18h</p>
                </div>
              </div>
            </div>
          </div>

          {/* Contact Form */}
          <div className="lg:col-span-8">
            <form onSubmit={handleSubmit} className="glass-card rounded-2xl p-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-300 mb-1">
                    Nom complet
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                </div>

                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                </div>
              </div>

              <div className="mb-6">
                <label htmlFor="subject" className="block text-sm font-medium text-gray-300 mb-1">
                  Sujet
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>

              <div className="mb-6">
                <label htmlFor="message" className="block text-sm font-medium text-gray-300 mb-1">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  rows={6}
                  className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
                />
              </div>

              {status === 'success' && (
                <div className="mb-6 p-4 bg-green-500 bg-opacity-10 border border-green-500 rounded-lg flex items-center gap-2 text-green-400">
                  <AlertCircle className="w-5 h-5" />
                  <p>Votre message a été envoyé avec succès !</p>
                </div>
              )}

              {status === 'error' && (
                <div className="mb-6 p-4 bg-red-500 bg-opacity-10 border border-red-500 rounded-lg flex items-center gap-2 text-red-400">
                  <AlertCircle className="w-5 h-5" />
                  <p>Une erreur est survenue. Veuillez réessayer.</p>
                </div>
              )}

              <button
                type="submit"
                disabled={status === 'sending'}
                className="glow-button w-full flex items-center justify-center gap-2 group"
              >
                {status === 'sending' ? (
                  <>
                    <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                    Envoi en cours...
                  </>
                ) : (
                  <>
                    Envoyer le message
                    <Send className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                  </>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}