import React, { useState, useMemo } from 'react';
import { Client } from '../../types/client';
import { Edit2, Trash2, Users, ChevronRight, ChevronDown, Building2 } from 'lucide-react';

interface ClientListProps {
  clients: Client[];
  loading: boolean;
  onEdit: (client: Client) => void;
  onDelete: (client: Client) => void;
  searchQuery: string;
}

export default function ClientList({ clients, loading, onEdit, onDelete, searchQuery }: ClientListProps) {
  const [expandedResellers, setExpandedResellers] = useState<Record<string, boolean>>({});

  // Organiser et filtrer les clients
  const { resellers, directClients, clientsByReseller, filteredClients, resellerNames } = useMemo(() => {
    const resellers = clients.filter(client => client.type === 'reseller');
    const resellerMap = new Map(resellers.map(r => [r.id, r.companyName || '']));
    
    // Créer un map des noms de revendeurs pour un accès rapide
    const resellerNames = new Map<string, string>();
    resellers.forEach(r => {
      if (r.companyName) {
        resellerNames.set(r.id, r.companyName);
      }
    });

    const searchLower = (searchQuery || '').toLowerCase();
    let filteredClients: Client[] = [];

    if (searchLower) {
      filteredClients = clients.filter(client => {
        const matchCompany = (client.companyName || '').toLowerCase().includes(searchLower);
        const matchContact = (client.contactName || '').toLowerCase().includes(searchLower);
        const matchEmail = (client.email || '').toLowerCase().includes(searchLower);
        const matchReseller = client.resellerId ? 
          (resellerMap.get(client.resellerId) || '').toLowerCase().includes(searchLower) : 
          false;

        return matchCompany || matchContact || matchEmail || matchReseller;
      });
    }

    // Clients directs = clients sans revendeur et qui ne sont pas revendeurs eux-mêmes
    const directClients = clients.filter(client => 
      !client.resellerId && client.type !== 'reseller'
    );

    // Clients par revendeur
    const clientsByReseller = resellers.reduce((acc, reseller) => {
      acc[reseller.id] = clients.filter(client => 
        client.resellerId === reseller.id
      );
      return acc;
    }, {} as Record<string, Client[]>);

    return {
      resellers,
      directClients,
      clientsByReseller,
      filteredClients,
      resellerNames
    };
  }, [clients, searchQuery]);

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  const toggleReseller = (resellerId: string) => {
    setExpandedResellers(prev => ({
      ...prev,
      [resellerId]: !prev[resellerId]
    }));
  };

  const renderClientRow = (client: Client, isSubClient: boolean = false, showReseller: boolean = false) => (
    <tr key={client.id} className={`border-b border-gray-800 ${isSubClient ? 'bg-gray-800/30' : ''}`}>
      <td className="py-4 text-white">
        <div className={`flex items-center ${isSubClient ? 'ml-8' : ''}`}>
          {isSubClient && <div className="w-6 h-px bg-gray-700 mr-3" />}
          {client.companyName}
        </div>
      </td>
      <td className="py-4 text-white">{client.contactName}</td>
      <td className="py-4 text-white">{client.email}</td>
      {showReseller && (
        <td className="py-4">
          {client.resellerId && resellerNames.get(client.resellerId) && (
            <div className="flex items-center gap-2">
              <Building2 className="w-4 h-4 text-gray-400" />
              <span className="text-gray-400">
                {resellerNames.get(client.resellerId)}
              </span>
            </div>
          )}
        </td>
      )}
      <td className="py-4">
        <span className={`px-2 py-1 rounded-full text-xs font-medium ${
          client.plan === 'production'
            ? 'bg-blue-500/10 text-blue-500'
            : 'bg-orange-500/10 text-orange-500'
        }`}>
          {client.plan === 'production' ? 'Production' : 'Connect'}
        </span>
      </td>
      <td className="py-4">
        <div className="flex items-center gap-3">
          <button
            onClick={() => onEdit(client)}
            className="p-2 text-gray-400 hover:text-white transition-colors"
          >
            <Edit2 className="w-4 h-4" />
          </button>
          <button
            onClick={() => onDelete(client)}
            className="p-2 text-gray-400 hover:text-red-500 transition-colors"
          >
            <Trash2 className="w-4 h-4" />
          </button>
        </div>
      </td>
    </tr>
  );

  // Si une recherche est active, afficher les résultats filtrés
  if (searchQuery) {
    if (filteredClients.length === 0) {
      return (
        <div className="text-center py-8 text-gray-400">
          Aucun client trouvé
        </div>
      );
    }

    // Compter les clients avec un revendeur
    const hasClientsWithReseller = filteredClients.some(client => client.resellerId);

    return (
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="text-left border-b border-gray-700">
              <th className="pb-3 text-gray-400 font-medium">Entreprise</th>
              <th className="pb-3 text-gray-400 font-medium">Contact</th>
              <th className="pb-3 text-gray-400 font-medium">Email</th>
              {hasClientsWithReseller && (
                <th className="pb-3 text-gray-400 font-medium">Revendeur</th>
              )}
              <th className="pb-3 text-gray-400 font-medium">Plan</th>
              <th className="pb-3 text-gray-400 font-medium">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredClients.map(client => renderClientRow(client, false, hasClientsWithReseller))}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* Revendeurs et leurs clients */}
      {resellers.length > 0 && (
        <div className="space-y-4">
          <div className="flex items-center gap-2 pb-2 border-b border-gray-700">
            <Users className="w-5 h-5 text-blue-500" />
            <h3 className="text-lg font-medium text-white">Revendeurs</h3>
            <span className="text-sm text-gray-400">({resellers.length})</span>
          </div>

          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="text-left border-b border-gray-700">
                  <th className="pb-3 text-gray-400 font-medium">Entreprise</th>
                  <th className="pb-3 text-gray-400 font-medium">Contact</th>
                  <th className="pb-3 text-gray-400 font-medium">Email</th>
                  <th className="pb-3 text-gray-400 font-medium">Plan</th>
                  <th className="pb-3 text-gray-400 font-medium">Actions</th>
                </tr>
              </thead>
              <tbody>
                {resellers.map(reseller => (
                  <React.Fragment key={reseller.id}>
                    <tr className="border-b border-gray-800">
                      <td className="py-4 text-white">
                        <button
                          onClick={() => toggleReseller(reseller.id)}
                          className="flex items-center gap-2 hover:text-blue-400 transition-colors"
                        >
                          {expandedResellers[reseller.id] ? (
                            <ChevronDown className="w-4 h-4" />
                          ) : (
                            <ChevronRight className="w-4 h-4" />
                          )}
                          {reseller.companyName}
                          <span className="text-xs text-gray-400">
                            ({clientsByReseller[reseller.id]?.length || 0} clients)
                          </span>
                        </button>
                      </td>
                      <td className="py-4 text-white">{reseller.contactName}</td>
                      <td className="py-4 text-white">{reseller.email}</td>
                      <td className="py-4">
                        <span className="px-2 py-1 rounded-full text-xs font-medium bg-purple-500/10 text-purple-400">
                          Revendeur
                        </span>
                      </td>
                      <td className="py-4">
                        <div className="flex items-center gap-3">
                          <button
                            onClick={() => onEdit(reseller)}
                            className="p-2 text-gray-400 hover:text-white transition-colors"
                          >
                            <Edit2 className="w-4 h-4" />
                          </button>
                          <button
                            onClick={() => onDelete(reseller)}
                            className="p-2 text-gray-400 hover:text-red-500 transition-colors"
                          >
                            <Trash2 className="w-4 h-4" />
                          </button>
                        </div>
                      </td>
                    </tr>
                    {expandedResellers[reseller.id] && clientsByReseller[reseller.id]?.map(client => 
                      renderClientRow(client, true)
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Clients directs */}
      {directClients.length > 0 && (
        <div className="space-y-4">
          <div className="flex items-center gap-2 pb-2 border-b border-gray-700">
            <Users className="w-5 h-5 text-blue-500" />
            <h3 className="text-lg font-medium text-white">Clients directs</h3>
            <span className="text-sm text-gray-400">({directClients.length})</span>
          </div>

          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="text-left border-b border-gray-700">
                  <th className="pb-3 text-gray-400 font-medium">Entreprise</th>
                  <th className="pb-3 text-gray-400 font-medium">Contact</th>
                  <th className="pb-3 text-gray-400 font-medium">Email</th>
                  <th className="pb-3 text-gray-400 font-medium">Plan</th>
                  <th className="pb-3 text-gray-400 font-medium">Actions</th>
                </tr>
              </thead>
              <tbody>
                {directClients.map(client => renderClientRow(client))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}