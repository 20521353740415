import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function OrderHeader() {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate('/admin?tab=orders')}
      className="flex items-center gap-2 text-gray-400 hover:text-white mb-6 transition-colors"
    >
      <ArrowLeft className="w-5 h-5" />
      Retour aux commandes
    </button>
  );
}