import React, { useState, useEffect } from 'react';
import { CreditCard, Edit2 } from 'lucide-react';
import { collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import { updatePaymentMethod, getPaymentMethod } from '../../../services/stripeService';

interface PaymentMethodProps {
  customerId: string;
}

interface PaymentMethodInfo {
  brand?: string;
  last4?: string;
  expMonth?: number;
  expYear?: number;
  customerId?: string;
}

export default function PaymentMethodSection({ customerId }: PaymentMethodProps) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodInfo | null>(null);

  useEffect(() => {
    const loadPaymentMethod = async () => {
      console.log('Starting loadPaymentMethod with accountId:', customerId);
      try {
        // 1. Get latest paid order for this account
        const ordersRef = collection(db, 'orders');
        const q = query(
          ordersRef,
          where('accountId', '==', customerId),
          where('status', '==', 'paid'),
          orderBy('createdAt', 'desc'),
          limit(1)
        );
        
        console.log('Fetching latest paid order');
        const snapshot = await getDocs(q);
        
        if (snapshot.empty) {
          console.log('No paid orders found');
          setLoading(false);
          return;
        }

        const latestOrder = snapshot.docs[0].data();
        console.log('Latest order found:', { 
          id: snapshot.docs[0].id,
          sessionId: latestOrder.stripeSessionId 
        });

        if (!latestOrder.stripeSessionId) {
          console.error('No stripeSessionId found in latest order');
          throw new Error('Session ID not found');
        }

        // 2. Get payment method using session ID
        console.log('Fetching payment method for session:', latestOrder.stripeSessionId);
        const paymentData = await getPaymentMethod(latestOrder.stripeSessionId);
        console.log('Payment method data:', paymentData);

        setPaymentMethod(paymentData);

      } catch (err) {
        console.error('Error loading payment method:', err);
        setError('Erreur lors du chargement du moyen de paiement');
      } finally {
        setLoading(false);
      }
    };

    if (customerId) {
      loadPaymentMethod();
    }
  }, [customerId]);

  const handleUpdatePaymentMethod = async () => {
    if (!paymentMethod?.customerId) {
      setError('ID client Stripe non disponible');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      const { url } = await updatePaymentMethod(paymentMethod.customerId);
      if (url) {
        window.location.href = url;
      }
    } catch (err) {
      console.error('Error updating payment method:', err);
      setError('Erreur lors de la mise à jour du moyen de paiement');
    } finally {
      setLoading(false);
    }
  };

  const renderPaymentMethodDetails = () => {
    if (!paymentMethod) return null;

    const isCard = paymentMethod.brand && paymentMethod.brand !== 'sepa_debit';
    const icon = isCard ? <CreditCard className="w-6 h-6 text-white" /> : null;
    const label = isCard ? 'Carte' : 'Prélèvement SEPA';

    return (
      <div className="flex items-center gap-4">
        <div className="p-3 bg-gray-800 rounded-lg">
          {icon}
        </div>
        <div>
          <p className="text-white font-medium">
            {label} •••• {paymentMethod.last4}
          </p>
          {isCard && paymentMethod.expMonth && paymentMethod.expYear && (
            <p className="text-sm text-gray-400">
              Expire {paymentMethod.expMonth}/{paymentMethod.expYear}
            </p>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="glass-card rounded-2xl p-6">
      <h3 className="text-lg font-medium text-white flex items-center gap-2 mb-6">
        <CreditCard className="w-5 h-5 text-blue-500" />
        Moyen de paiement
      </h3>

      <div className="space-y-6">
        {loading ? (
          <div className="flex justify-center py-4">
            <div className="w-6 h-6 border-2 border-blue-500 border-t-transparent rounded-full animate-spin" />
          </div>
        ) : (
          <div className="flex items-center justify-between">
            {paymentMethod ? renderPaymentMethodDetails() : (
              <p className="text-gray-400">Aucun moyen de paiement trouvé</p>
            )}
            {paymentMethod?.customerId && (
              <button
                onClick={handleUpdatePaymentMethod}
                disabled={loading}
                className="flex items-center gap-2 px-4 py-2 rounded-lg text-sm font-medium text-gray-300 hover:text-white hover:bg-white/5 transition-colors"
              >
                <Edit2 className="w-4 h-4" />
                Modifier sur Stripe
              </button>
            )}
          </div>
        )}

        {error && (
          <div className="p-4 bg-red-500/10 border border-red-500 rounded-lg text-red-500 text-sm">
            {error}
          </div>
        )}
      </div>
    </div>
  );
}