import React, { useState } from 'react';
import { AlertCircle } from 'lucide-react';
import { Client, ClientFormData } from '../../../types/client';
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';
import ProfileForm from './ProfileForm';
import LicensesTab from './LicensesTab';

interface ClientModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: ClientFormData) => Promise<void>;
  client?: Client;
  mode: 'create' | 'edit';
  resellers: any[];
}

export default function ClientModal({ 
  isOpen, 
  onClose, 
  onSubmit, 
  client, 
  mode,
  resellers
}: ClientModalProps) {
  const [activeTab, setActiveTab] = useState<'profile' | 'licenses'>('profile');
  const [error, setError] = useState<string | null>(null);

  const [formData, setFormData] = useState<ClientFormData>({
    companyName: client?.companyName || '',
    contactName: client?.contactName || '',
    email: client?.email || '',
    phone: client?.phone || '',
    type: client?.type || 'end-user',
    plan: client?.plan || 'none',
    billingCycle: client?.billingCycle || 'none',
    address: client?.address || {
      line1: '',
      line2: '',
      city: '',
      postalCode: '',
      country: ''
    },
    vatNumber: client?.vatNumber || '',
    licenses: client?.licenses || [],
    resellerId: client?.resellerId || ''
  });

  const handleSubmit = async () => {
    try {
      setError(null);
      await onSubmit(formData);
      onClose();
    } catch (err) {
      console.error('Error submitting:', err);
      setError(err instanceof Error ? err.message : 'Une erreur est survenue');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="glass-card w-full max-w-2xl rounded-2xl">
        <ModalHeader
          title={mode === 'create' ? 'Nouveau client' : 'Modifier le client'}
          onClose={onClose}
          activeTab={activeTab}
          onTabChange={(tab) => setActiveTab(tab as 'profile' | 'licenses')}
        />

        <div className="p-6">
          {activeTab === 'profile' ? (
            <ProfileForm
              formData={formData}
              onChange={setFormData}
              mode={mode}
              resellers={resellers}
            />
          ) : (
            client && <LicensesTab client={client} />
          )}

          {error && (
            <div className="mt-6 p-4 bg-red-500/10 border border-red-500 rounded-lg flex items-center gap-2 text-red-500">
              <AlertCircle className="w-5 h-5 shrink-0" />
              <p>{error}</p>
            </div>
          )}
        </div>

        <ModalFooter
          onClose={onClose}
          onSave={handleSubmit}
          mode={mode}
          showSave={activeTab === 'profile'}
        />
      </div>
    </div>
  );
}