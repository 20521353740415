import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { 
  User, 
  Settings, 
  LogOut,
  AlertCircle,
  Package,
  CreditCard
} from 'lucide-react';
import ProfileTab from '../components/dashboard/ProfileTab';
import OrdersTab from '../components/dashboard/OrdersTab';
import BillingTab from '../components/dashboard/billing/BillingTab';

export default function Dashboard() {
  const [activeTab, setActiveTab] = useState('profile');
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [accountData, setAccountData] = useState<any>(null);

  const { currentUser, logout, isAdmin } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
      return;
    }

    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        
        if (isAdmin) {
          setAccountData({
            company: { name: 'Administration' },
            contact: {
              name: 'Administrateur',
              email: currentUser.email,
            },
            role: 'admin'
          });
          setLoading(false);
          return;
        }

        const accountDoc = await getDoc(doc(db, 'accounts', currentUser.uid));
        if (accountDoc.exists()) {
          setAccountData(accountDoc.data());
        } else {
          setError('Compte non trouvé');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Erreur lors du chargement des données');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUser, navigate, isAdmin]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
      setError('Erreur lors de la déconnexion');
    }
  };

  const handleUpdateField = (field: string, value: any) => {
    if (isAdmin) {
      setIsEditing(false);
      return;
    }

    setAccountData(prev => {
      const newData = { ...prev };
      const fields = field.split('.');
      let current = newData;
      
      for (let i = 0; i < fields.length - 1; i++) {
        if (!current[fields[i]]) {
          current[fields[i]] = {};
        }
        current = current[fields[i]];
      }
      
      current[fields[fields.length - 1]] = value;
      return newData;
    });
  };

  const handleUpdateProfile = async () => {
    if (isAdmin) {
      setIsEditing(false);
      return;
    }

    try {
      const accountRef = doc(db, 'accounts', currentUser!.uid);
      await updateDoc(accountRef, {
        company: accountData.company,
        contact: accountData.contact,
        billing: {
          ...accountData.billing,
          billingAddress: accountData.billing?.billingAddress
        },
        updatedAt: new Date().toISOString()
      });
      setIsEditing(false);
      setError(null);
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Erreur lors de la mise à jour du profil');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="w-16 h-16 border-4 border-sky-400 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  if (!accountData) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-xl font-medium text-white mb-2">Une erreur est survenue</h2>
          <p className="text-gray-400">Impossible de charger les données du compte</p>
        </div>
      </div>
    );
  }

  return (
    <main className="pt-28 px-4 pb-12 max-w-7xl mx-auto">
      <div className="grid lg:grid-cols-12 gap-6">
        <div className="lg:col-span-3">
          <div className="glass-card rounded-2xl p-4 sticky top-28">
            <div className="flex flex-col space-y-2">
              <button
                onClick={() => setActiveTab('profile')}
                className={`flex items-center gap-3 px-4 py-3 rounded-xl text-sm font-medium transition-all ${
                  activeTab === 'profile'
                    ? 'bg-[#3B82F6] text-white'
                    : 'text-gray-300 hover:text-white hover:bg-white/5'
                }`}
              >
                <User size={18} />
                Informations
              </button>

              <button
                onClick={() => setActiveTab('orders')}
                className={`flex items-center gap-3 px-4 py-3 rounded-xl text-sm font-medium transition-all ${
                  activeTab === 'orders'
                    ? 'bg-[#3B82F6] text-white'
                    : 'text-gray-300 hover:text-white hover:bg-white/5'
                }`}
              >
                <Package size={18} />
                Commandes
              </button>

              <button
                onClick={() => setActiveTab('billing')}
                className={`flex items-center gap-3 px-4 py-3 rounded-xl text-sm font-medium transition-all ${
                  activeTab === 'billing'
                    ? 'bg-[#3B82F6] text-white'
                    : 'text-gray-300 hover:text-white hover:bg-white/5'
                }`}
              >
                <CreditCard size={18} />
                Facturation
              </button>

              {isAdmin && (
                <button
                  onClick={() => navigate('/admin')}
                  className={`flex items-center gap-3 px-4 py-3 rounded-xl text-sm font-medium transition-all text-gray-300 hover:text-white hover:bg-white/5`}
                >
                  <Settings size={18} />
                  Administration
                </button>
              )}

              <button
                onClick={handleLogout}
                className="flex items-center gap-3 px-4 py-3 rounded-xl text-sm font-medium text-red-400 hover:text-red-300 hover:bg-red-500/10 transition-all mt-4"
              >
                <LogOut size={18} />
                Se déconnecter
              </button>
            </div>
          </div>
        </div>

        <div className="lg:col-span-9 space-y-6">
          {activeTab === 'profile' && (
            <ProfileTab
              accountData={accountData}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              onSave={handleUpdateProfile}
              onUpdate={handleUpdateField}
            />
          )}

          {activeTab === 'orders' && (
            <OrdersTab accountId={currentUser?.uid} />
          )}

          {activeTab === 'billing' && (
            <BillingTab accountData={accountData} />
          )}

          {error && (
            <div className="glass-card rounded-2xl p-4 bg-red-500/10 border border-red-500">
              <div className="flex items-center gap-2 text-red-500">
                <AlertCircle className="w-5 h-5 shrink-0" />
                <p>{error}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </main>
  );
}