import React from 'react';
import { X } from 'lucide-react';

interface ModalHeaderProps {
  title: string;
  onClose: () => void;
  activeTab: string;
  onTabChange: (tab: string) => void;
}

export default function ModalHeader({ title, onClose, activeTab, onTabChange }: ModalHeaderProps) {
  return (
    <div className="p-6 border-b border-gray-700">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-medium text-white">{title}</h2>
        <button
          onClick={onClose}
          className="text-gray-400 hover:text-white transition-colors"
        >
          <X className="w-6 h-6" />
        </button>
      </div>

      <div className="flex gap-4">
        <button
          onClick={() => onTabChange('profile')}
          className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
            activeTab === 'profile'
              ? 'bg-blue-500 text-white'
              : 'text-gray-400 hover:text-white hover:bg-gray-700'
          }`}
        >
          Profil
        </button>
        <button
          onClick={() => onTabChange('licenses')}
          className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
            activeTab === 'licenses'
              ? 'bg-blue-500 text-white'
              : 'text-gray-400 hover:text-white hover:bg-gray-700'
          }`}
        >
          Licences
        </button>
      </div>
    </div>
  );
}