import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import CartIcon from '../components/cart/CartIcon';
import './navbar.css';
import { Helmet } from 'react-helmet-async';

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleAuthClick = () => {
    if (currentUser) {
      navigate('/dashboard');
    } else {
      navigate('/login');
    }
  };

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.4.2/css/all.min.css"
        />
      </Helmet>
      <nav className="navbar">
        <div className="logo">
          <a href="https://ripee.fr">
            <img src="https://ripee.fr/ripee-logo.svg" alt="Ripee Logo" width="48" height="48" />
          </a>
        </div>
        <div className="nav-links">
          <a href="https://ripee.fr/explorez.html">Explorez</a>
          <a href="https://ripee.fr/services.html">Nos Services</a>
          <a href="https://app.ripee.fr/pricing">Tarifs</a>
          <a href="https://app.ripee.fr/support">Contact</a>
        </div>
        <button 
          className="mobile-menu-btn"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          <i className="fas fa-bars"></i>
        </button>
        <div className="auth-container flex items-center gap-4">
          <CartIcon />
          <button className="login-btn" onClick={handleAuthClick}>
            <i className="fas fa-circle-user"></i>
            <span className="auth-text">
              {currentUser ? 'Mon compte' : 'Connexion'}
            </span>
          </button>
        </div>
      </nav>

      <div className={`mobile-menu ${isMobileMenuOpen ? 'active' : ''}`}>
        <div className="mobile-menu-links">
          <a href="https://ripee.fr/explorez.html">Explorez</a>
          <a href="https://ripee.fr/services.html">Nos Services</a>
          <a href="https://app.ripee.fr/pricing">Tarifs</a>
          <a href="https://app.ripee.fr/support">Contact</a>
          <button 
            className="mobile-login-btn"
            onClick={handleAuthClick}
          >
            <i className="fas fa-circle-user"></i>
            <span>{currentUser ? 'Mon compte' : 'Connexion'}</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Navbar;