import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { getMachines } from '../services/machineService';
import { Machine } from '../types/machine';
import LoadingOverlay from '../components/LoadingOverlay';
import UpdateHeader from '../components/subscription/update/UpdateHeader';
import UpdatePlanSection from '../components/subscription/update/UpdatePlanSection';
import UpdateMachinesSection from '../components/subscription/update/UpdateMachinesSection';
import UpdatePriceSummary from '../components/subscription/update/UpdatePriceSummary';
import UpdateActions from '../components/subscription/update/UpdateActions';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

export default function ClientSubscriptionUpdate() {
  const [loading, setLoading] = useState(true);
  const [machines, setMachines] = useState<Machine[]>([]);
  const [selectedMachines, setSelectedMachines] = useState<any[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<'production' | 'connect'>('production');
  const [error, setError] = useState<string | null>(null);
  const [currentConfig, setCurrentConfig] = useState<any>(null);
  const [configId, setConfigId] = useState<string>('');

  const location = useLocation();
  const navigate = useNavigate();
  const { orderId } = location.state || {};

  useEffect(() => {
    if (!orderId) {
      navigate('/dashboard');
      return;
    }

    const loadInitialData = async () => {
      try {
        setLoading(true);
        setError(null);

        const orderDoc = await getDoc(doc(db, 'orders', orderId));
        if (!orderDoc.exists()) throw new Error('Commande non trouvée');
        
        const orderData = orderDoc.data();
        const subscriptionData = orderData.subscription;

        if (!subscriptionData) throw new Error('Aucun abonnement trouvé');

        const currentMachines = Object.values(subscriptionData.machines).map((machine: any) => ({
          id: machine.id,
          name: machine.name,
          code: machine.code,
          quantity: machine.quantity
        }));

        const config = {
          billingCycle: subscriptionData.billingCycle,
          plan: subscriptionData.plan,
          machines: currentMachines,
          accountId: orderData.accountId,
          subscriptionId: subscriptionData.stripeId
        };

        setCurrentConfig(config);
        setSelectedPlan(subscriptionData.plan);
        setSelectedMachines(currentMachines);

        const machinesData = await getMachines();
        setMachines(machinesData);

        // Create initial config_licence
        const newConfigId = uuidv4();
        const configRef = doc(db, 'config_licence', newConfigId);
        await setDoc(configRef, {
          machines: currentMachines,
          selectedPlan: subscriptionData.plan,
          billingCycle: subscriptionData.billingCycle,
          accountId: orderData.accountId,
          createdAt: new Date().toISOString()
        });

        setConfigId(newConfigId);

      } catch (err) {
        console.error('Error loading initial data:', err);
        setError('Erreur lors du chargement des données');
      } finally {
        setLoading(false);
      }
    };

    loadInitialData();
  }, [orderId, navigate]);

  useEffect(() => {
    const updateConfig = async () => {
      if (!configId || !currentConfig) return;

      try {
        const configRef = doc(db, 'config_licence', configId);
        await setDoc(configRef, {
          machines: selectedMachines,
          selectedPlan,
          billingCycle: currentConfig.billingCycle,
          accountId: currentConfig.accountId,
          createdAt: new Date().toISOString()
        }, { merge: true });

        setCurrentConfig(prev => ({
          ...prev,
          machines: selectedMachines,
          plan: selectedPlan
        }));
      } catch (err) {
        console.error('Error updating config:', err);
      }
    };

    updateConfig();
  }, [selectedMachines, selectedPlan, configId, currentConfig]);

  const handleUpdateSubscription = async () => {
    if (!configId || !currentConfig?.subscriptionId || selectedMachines.length === 0) {
      setError('Configuration invalide');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const response = await axios.post('/.netlify/functions/update-subscription', {
        subscriptionId: currentConfig.subscriptionId,
        configId
      });

      if (!response.data?.success) {
        throw new Error(response.data?.error || 'Erreur lors de la mise à jour');
      }

      navigate('/subscription/update/success', {
        state: { orderId }
      });
    } catch (err) {
      console.error('Error updating subscription:', err);
      setError('Erreur lors de la mise à jour de l\'abonnement');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoadingOverlay message="Chargement de la configuration..." />;
  }

  return (
    <div className="min-h-screen p-6">
      <div className="max-w-4xl mx-auto">
        <UpdateHeader />

        <div className="space-y-16">
          <section className="pricing-section">
            <div className="flex flex-col items-center text-center mb-12">
              <div className="flex items-center gap-4">
                <div className="w-10 h-10 shrink-0 rounded-full bg-ripee flex items-center justify-center text-xl font-medium text-white">
                  1
                </div>
                <h2 className="text-xl sm:text-3xl font-medium text-white">
                  Plan
                </h2>
              </div>
            </div>

            <UpdatePlanSection
              selectedPlan={selectedPlan}
              onPlanChange={setSelectedPlan}
              machines={selectedMachines.map(m => ({
                code: m.code,
                quantity: m.quantity
              }))}
            />
          </section>

          <section className="pricing-section">
            <div className="flex flex-col items-center text-center mb-12">
              <div className="flex items-center gap-4">
                <div className="w-10 h-10 shrink-0 rounded-full bg-ripee flex items-center justify-center text-xl font-medium text-white">
                  2
                </div>
                <h2 className="text-xl sm:text-3xl font-medium text-white">
                  Machines
                </h2>
              </div>
            </div>

            <div className="max-w-2xl mx-auto">
              <UpdateMachinesSection
                machines={machines}
                selectedMachines={selectedMachines}
                onAddMachine={(machine) => {
                  setSelectedMachines(prev => [...prev, {
                    id: machine.id,
                    name: machine.name,
                    code: machine.code,
                    quantity: 1
                  }]);
                }}
                onUpdateQuantity={(id, quantity) => {
                  setSelectedMachines(prev => prev.map(m =>
                    m.id === id ? { ...m, quantity } : m
                  ));
                }}
                onRemoveMachine={(id) => {
                  setSelectedMachines(prev => prev.filter(m => m.id !== id));
                }}
                loading={loading}
                error={error}
              />
            </div>
          </section>

          <div className="max-w-2xl mx-auto space-y-8">
            <UpdatePriceSummary
              selectedPlan={selectedPlan}
              currentConfig={currentConfig}
            />

            <div className="mt-8">
              <UpdateActions
                onUpdate={handleUpdateSubscription}
                loading={loading}
                error={error}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}