import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { OrderProcessingStatus } from '../../types/order';
import { updateOrderProcessingStatus, cancelOrderSubscription } from '../../services/orderService';

import OrderHeader from './order-details/OrderHeader';
import GeneralInformation from './order-details/GeneralInformation';
import ProcessingStatus from './order-details/ProcessingStatus';
import SubscriptionDetails from './order-details/SubscriptionDetails';
import PaymentDetails from './order-details/PaymentDetails';
import CancelSubscriptionModal from './order-details/CancelSubscriptionModal';
import LoadingState from './order-details/LoadingState';
import ErrorState from './order-details/ErrorState';

export default function OrderDetails() {
  const [order, setOrder] = useState<any>(null);
  const [accountData, setAccountData] = useState<any>(null);
  const [resellerData, setResellerData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [processingStatus, setProcessingStatus] = useState<OrderProcessingStatus>('pending_processing');
  const [processingNotes, setProcessingNotes] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const { orderId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      try {
        if (!orderId) {
          setError('ID de commande manquant');
          return;
        }

        setLoading(true);
        setError(null);

        // Charger la commande
        const orderDoc = await getDoc(doc(db, 'orders', orderId));
        if (!orderDoc.exists()) {
          setError('Commande non trouvée');
          return;
        }

        const orderData = {
          id: orderDoc.id,
          ...orderDoc.data()
        };

        setOrder(orderData);
        setProcessingStatus(orderData.processingStatus || 'pending_processing');
        setProcessingNotes(orderData.processingNotes || '');

        // Charger les données du compte client
        if (orderData.accountId) {
          const accountDoc = await getDoc(doc(db, 'accounts', orderData.accountId));
          if (accountDoc.exists()) {
            const accountData = accountDoc.data();
            setAccountData(accountData);

            // Si le compte a un revendeur, charger ses données
            if (accountData.resellerId) {
              const resellerDoc = await getDoc(doc(db, 'accounts', accountData.resellerId));
              if (resellerDoc.exists()) {
                setResellerData(resellerDoc.data());
              }
            }
          }
        }
      } catch (err) {
        console.error('Error loading order data:', err);
        setError('Erreur lors du chargement des données');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [orderId]);

  const handleUpdateProcessingStatus = async () => {
    if (!order) return;

    try {
      setIsUpdating(true);
      setError(null);
      
      await updateOrderProcessingStatus(order.id, processingStatus, processingNotes);
      
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
    } catch (err) {
      console.error('Error updating processing status:', err);
      setError('Erreur lors de la mise à jour du statut');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleCancelSubscription = async () => {
    if (!order || !order.accountId) return;

    try {
      await cancelOrderSubscription(order.id, order.accountId);
      
      setOrder(prev => ({
        ...prev,
        subscription: {
          ...prev.subscription,
          status: 'cancelled',
          cancelledAt: new Date().toISOString()
        }
      }));

      setShowCancelModal(false);
    } catch (err) {
      console.error('Error canceling subscription:', err);
      setError('Erreur lors de l\'annulation de l\'abonnement');
    }
  };

  if (loading) return <LoadingState />;
  if (error) return <ErrorState error={error} />;
  if (!order) return <ErrorState error="Commande non trouvée" />;

  return (
    <div className="min-h-screen p-4 sm:p-6">
      <div className="max-w-4xl mx-auto">
        <OrderHeader />

        <div className="space-y-6">
          <GeneralInformation 
            order={order}
            accountData={accountData}
            resellerData={resellerData}
          />

          <ProcessingStatus
            processingStatus={processingStatus}
            processingNotes={processingNotes}
            isUpdating={isUpdating}
            showSuccess={showSuccess}
            error={error}
            onStatusChange={setProcessingStatus}
            onNotesChange={setProcessingNotes}
            onUpdate={handleUpdateProcessingStatus}
          />

          <SubscriptionDetails 
            subscription={order.subscription}
            onCancelSubscription={() => setShowCancelModal(true)}
          />
          
          <PaymentDetails order={order} />
        </div>

        {showCancelModal && (
          <CancelSubscriptionModal
            isOpen={showCancelModal}
            onClose={() => setShowCancelModal(false)}
            onConfirm={handleCancelSubscription}
            companyName={accountData?.company?.name || ''}
            billingCycle={order.subscription?.billingCycle}
          />
        )}
      </div>
    </div>
  );
}