import { useState, useCallback } from 'react';
import { Client, ClientFormData } from '../types/client';
import { createClientAccount, updateClientAccount } from '../services/adminService';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

export function useClientManagement() {
  const [clients, setClients] = useState<Client[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const loadClients = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      
      const accountsRef = collection(db, 'accounts');
      const snapshot = await getDocs(accountsRef);
      
      const loadedClients = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          companyName: data.company?.name || '',
          contactName: data.contact?.name || '',
          email: data.contact?.email || '',
          phone: data.contact?.phone || '',
          type: data.role || 'end-user',
          plan: data.billing?.plan || 'production',
          billingCycle: data.billing?.billingCycle || 'monthly',
          status: data.status || 'pending',
          createdAt: data.createdAt || new Date().toISOString(),
          subscriptionStartDate: data.billing?.subscriptionStartDate,
          userId: doc.id,
          resellerId: data.resellerId || null,
          address: data.billing?.billingAddress || {
            line1: '',
            line2: '',
            city: '',
            postalCode: '',
            country: ''
          },
          vatNumber: data.billing?.vatNumber || '',
          licenses: data.licenses || []
        };
      });

      setClients(loadedClients);
    } catch (err) {
      console.error('Error loading clients:', err);
      setError('Erreur lors du chargement des clients');
    } finally {
      setLoading(false);
    }
  }, []);

  const createClient = async (data: ClientFormData) => {
    try {
      setLoading(true);
      setError(null);
      await createClientAccount(data);
      await loadClients();
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Erreur lors de la création du client';
      setError(errorMessage);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const updateClient = async (clientId: string, data: ClientFormData) => {
    try {
      setLoading(true);
      setError(null);
      await updateClientAccount(clientId, data);
      await loadClients();
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Erreur lors de la mise à jour du client';
      setError(errorMessage);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const deleteClient = async (clientId: string) => {
    try {
      setLoading(true);
      setError(null);
      const accountRef = doc(db, 'accounts', clientId);
      await deleteDoc(accountRef);
      await loadClients();
    } catch (err) {
      setError('Erreur lors de la suppression du client');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    clients,
    loading,
    error,
    setError,
    loadClients,
    createClient,
    updateClient,
    deleteClient
  };
}