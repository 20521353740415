import React, { useMemo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import { ShoppingCart } from 'lucide-react';
import EmptyCart from '../components/cart/EmptyCart';
import CartSummary from '../components/cart/CartSummary';
import RegularItems from '../components/cart/RegularItems';
import SubscriptionItems from '../components/cart/SubscriptionItems';
import FrequentlyAddedProducts from '../components/cart/FrequentlyAddedProducts';
import { getCart } from '../services/cartService';
import LoadingOverlay from '../components/LoadingOverlay';
import '../styles/cart.css';

export default function CartPage() {
  const { items, updateQuantity, removeItem, selectedPlan, billingCycle } = useCart();
  const [cartData, setCartData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const { subscriptionItems, regularItems } = useMemo(() => {
    return {
      subscriptionItems: items.filter(item => item.plan),
      regularItems: items.filter(item => !item.plan)
    };
  }, [items]);

  // Scroll to top on mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Recharger les données du panier à chaque modification
  useEffect(() => {
    const loadCartData = async () => {
      const cartId = localStorage.getItem('cartId');
      if (!cartId) {
        setLoading(false);
        return;
      }

      try {
        const data = await getCart(cartId);
        if (data) {
          console.log('Cart data updated:', data);
          setCartData(data);
        }
      } catch (error) {
        console.error('Error loading cart data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadCartData();
  }, [items, selectedPlan, billingCycle]);

  const handleCheckout = () => {
    const cartId = localStorage.getItem('cartId');
    if (cartId) {
      navigate('/checkout', { state: { cartId } });
    }
  };

  const handleUpdateQuantity = async (id: string, quantity: number) => {
    await updateQuantity(id, quantity);
  };

  const handleRemoveItem = async (id: string) => {
    await removeItem(id);
  };

  if (loading) {
    return <LoadingOverlay message="Chargement du panier..." />;
  }

  if (items.length === 0) {
    return <EmptyCart />;
  }

  return (
    <div className="cart-container">
      <div className="cart-content">
        <div className="cart-header">
          <ShoppingCart className="cart-title-icon" />
          <h1 className="cart-title">Panier</h1>
        </div>

        <div className="cart-grid">
          <div className="cart-items-container">
            {subscriptionItems.length > 0 && (
              <SubscriptionItems
                items={subscriptionItems}
                selectedPlan={selectedPlan}
                billingCycle={billingCycle}
                onRemoveSubscription={() => {
                  subscriptionItems.forEach(item => handleRemoveItem(item.id));
                }}
              />
            )}

            {regularItems.length > 0 && (
              <RegularItems
                items={regularItems}
                onUpdateQuantity={handleUpdateQuantity}
                onRemoveItem={handleRemoveItem}
              />
            )}
          </div>

          <div>
            <CartSummary
              hasSubscription={subscriptionItems.length > 0}
              billingCycle={billingCycle}
              regularItemsCount={regularItems.length}
              onCheckout={handleCheckout}
              cartData={cartData}
            />
          </div>
        </div>

        <FrequentlyAddedProducts />
      </div>
    </div>
  );
}