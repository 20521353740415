import React from 'react';

interface LoadingOverlayProps {
  message?: string;
}

export default function LoadingOverlay({ message = 'Chargement...' }: LoadingOverlayProps) {
  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="glass-card p-8 rounded-2xl text-center">
        <div className="w-16 h-16 border-4 border-ripee border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
        <p className="text-white font-medium">{message}</p>
      </div>
    </div>
  );
}