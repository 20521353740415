import React, { useEffect, useState } from 'react';
import { Settings, X, Edit2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { CartItemType } from '../../types/cart';
import { getLicenseConfig } from '../../services/cartService';

interface SubscriptionItemsProps {
  items: CartItemType[];
  selectedPlan: 'production' | 'connect';
  billingCycle: 'monthly' | 'annual';
  onRemoveSubscription: () => void;
}

export default function SubscriptionItems({
  items,
  selectedPlan,
  billingCycle,
  onRemoveSubscription
}: SubscriptionItemsProps) {
  const navigate = useNavigate();
  const [machines, setMachines] = useState<Array<{
    id: string;
    name: string;
    code: string;
    quantity: number;
  }>>([]);

  useEffect(() => {
    const loadConfigDetails = async () => {
      const subscriptionItem = items.find(item => item.configId);
      if (!subscriptionItem?.configId) return;

      try {
        const config = await getLicenseConfig(subscriptionItem.configId);
        if (config) {
          setMachines(config.machines || []);
        }
      } catch (error) {
        console.error('Error loading subscription details:', error);
      }
    };

    loadConfigDetails();
  }, [items]);

  if (items.length === 0) return null;

  return (
    <div className="cart-section">
      <div className="cart-section-header">
        <Settings className="cart-section-icon" />
        <h2 className="cart-section-title">Configuration d'abonnement</h2>
      </div>

      <div className="cart-subscription">
        <div className="cart-subscription-header">
          <div>
            <h3 className="cart-subscription-title">
              Plan {selectedPlan === 'production' ? 'Production' : 'Connect'}
            </h3>
            <p className="cart-subscription-subtitle">
              Facturation {billingCycle === 'monthly' ? 'mensuelle' : 'annuelle'}
            </p>
          </div>

          <div className="cart-subscription-actions">
            <button
              onClick={() => navigate('/pricing')}
              className="cart-action-button"
              title="Modifier la configuration"
            >
              <Edit2 className="w-4 h-4" />
            </button>
            <button
              onClick={onRemoveSubscription}
              className="cart-remove-button"
              title="Supprimer la configuration"
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        </div>

        <div className="cart-subscription-machines">
          <div className="cart-machines-list">
            {machines.map((machine) => (
              <div key={machine.id} className="cart-machine">
                <div className="cart-machine-details">
                  <div className="cart-machine-name">{machine.name}</div>
                  <div className="cart-machine-quantity">
                    <span className="cart-machine-quantity-label">Quantité:</span>
                    <span className="cart-machine-quantity-value">{machine.quantity}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}