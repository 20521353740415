import React from 'react';
import { Shield } from 'lucide-react';
import { getCart } from '../../services/cartService';

interface CartSummaryProps {
  hasSubscription: boolean;
  billingCycle: 'monthly' | 'annual';
  regularItemsCount: number;
  onCheckout: () => void;
  cartData?: {
    firstPeriodPrice: number;
    regularPrice: number;
    regularItemsTotal: number;
    regularItemsTotalTTC: number;
    affiliateAmount?: number;
  };
}

export default function CartSummary({
  hasSubscription,
  billingCycle,
  regularItemsCount,
  onCheckout,
  cartData: initialCartData
}: CartSummaryProps) {
  const [cartData, setCartData] = React.useState(initialCartData);

  React.useEffect(() => {
    const loadCartData = async () => {
      const cartId = localStorage.getItem('cartId');
      if (!cartId) return;

      try {
        const data = await getCart(cartId);
        if (data) {
          console.log('Cart data updated:', data);
          setCartData(data);
        }
      } catch (error) {
        console.error('Error loading cart data:', error);
      }
    };

    loadCartData();
  }, [initialCartData, regularItemsCount, hasSubscription, billingCycle]);

  if (!cartData) {
    return (
      <div className="cart-summary">
        <h2 className="cart-summary-title">Récapitulatif</h2>
        <div className="cart-summary-content">
          <div className="text-center py-4">
            <div className="w-6 h-6 border-2 border-ripee border-t-transparent rounded-full animate-spin mx-auto mb-2"></div>
            <p className="text-gray-400">Calcul des prix...</p>
          </div>
        </div>
      </div>
    );
  }

  // Prix HT de l'abonnement
  const subscriptionHT = cartData.regularPrice || 0;

  // Prix HT des articles additionnels
  const regularItemsHT = cartData.regularItemsTotal || 0;

  // Total HT avant remise
  const totalHT = subscriptionHT + regularItemsHT;

  // Remise HT
  const affiliateDiscountHT = cartData.affiliateAmount || 0;

  // Total HT après remise
  const totalHTAfterDiscount = totalHT - affiliateDiscountHT;

  // TVA (20% du total HT après remise)
  const tva = totalHTAfterDiscount * 0.20;

  // Total TTC
  const totalTTC = totalHTAfterDiscount * 1.20;

  // Prix réguliers pour les périodes suivantes (sans les articles standards)
  const regularTotalHT = subscriptionHT;
  const regularTotalTTC = regularTotalHT * 1.20;

  // Vérifier s'il y a une différence entre les montants actuels et futurs
  const hasDifferentAmounts = cartData.affiliateAmount && cartData.affiliateAmount > 0;

  return (
    <div className="cart-summary">
      <h2 className="cart-summary-title">Récapitulatif</h2>
      
      <div className="cart-summary-content">
        {hasSubscription && (
          <div className="cart-summary-row">
            <span>Abonnement HT</span>
            <span className="text-white font-medium">
              {subscriptionHT.toFixed(2)}€
            </span>
          </div>
        )}

        {regularItemsCount > 0 && (
          <div className="cart-summary-row">
            <span>Articles additionnels HT</span>
            <span className="text-white font-medium">
              {regularItemsHT.toFixed(2)}€
            </span>
          </div>
        )}

        {cartData.affiliateAmount > 0 && (
          <div className="cart-summary-row text-green-400">
            <span>Remise Affiliation Revendeur HT</span>
            <span>-{affiliateDiscountHT.toFixed(2)}€</span>
          </div>
        )}

        <div className="cart-summary-row border-t border-gray-700 pt-3">
          <div className="flex flex-col">
            <span>Total HT</span>
            {hasDifferentAmounts && (
              <span className="text-xs text-gray-400">
                Puis {regularTotalHT.toFixed(2)}€ {billingCycle === 'monthly' ? '/mois' : '/an'}
              </span>
            )}
          </div>
          <div className="text-right">
            <span className="text-white font-medium">
              {totalHTAfterDiscount.toFixed(2)}€
            </span>
          </div>
        </div>

        <div className="cart-summary-row">
          <span>TVA (20%)</span>
          <span className="text-white font-medium">
            {tva.toFixed(2)}€
          </span>
        </div>

        <div className="cart-summary-total">
          <div className="flex flex-col">
            <span>Total TTC</span>
            {hasDifferentAmounts && (
              <span className="text-xs text-gray-400">
                Puis {regularTotalTTC.toFixed(2)}€ {billingCycle === 'monthly' ? '/mois' : '/an'}
              </span>
            )}
          </div>
          <span className="text-xl font-medium">
            {totalTTC.toFixed(2)}€
          </span>
        </div>

        <div className="glass-card p-4 rounded-xl flex items-center gap-3 mt-4">
          <Shield className="w-5 h-5 text-green-500 shrink-0" />
          <p className="text-sm text-gray-300">
            Paiement sécurisé par Stripe avec possibilité de payer par carte ou prélèvement SEPA.
          </p>
        </div>

        <button
          onClick={onCheckout}
          className="cart-checkout-button"
        >
          Commander
        </button>
      </div>
    </div>
  );
}