import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
  User,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail
} from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../config/firebase';
import { saveAuthSession, clearAuthSession } from '../services/sessionService';

interface UserData {
  role: 'admin' | 'reseller' | 'client';
  email: string;
  company?: {
    name?: string;
  };
  contact?: {
    name?: string;
    email?: string;
  };
  resellerId?: string;
}

interface AuthContextType {
  currentUser: User | null;
  userData: UserData | null;
  login: (email: string, password: string) => Promise<void>;
  signup: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  loading: boolean;
  isAdmin: boolean;
}

const AuthContext = createContext<AuthContextType | null>(null);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  async function checkIfUserIsAdmin(email: string | null): Promise<boolean> {
    if (!email) return false;
    
    try {
      const adminRef = doc(db, 'admins', email);
      const adminDoc = await getDoc(adminRef);
      return adminDoc.exists();
    } catch (error) {
      console.error('Error checking admin status:', error);
      return false;
    }
  }

  async function signup(email: string, password: string) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  async function login(email: string, password: string) {
    const result = await signInWithEmailAndPassword(auth, email, password);
    const adminStatus = await checkIfUserIsAdmin(email);
    setIsAdmin(adminStatus);
    
    saveAuthSession(result.user, adminStatus);
    
    if (adminStatus) {
      sessionStorage.setItem('adminPassword', password);
    }
    
    return result;
  }

  async function logout() {
    sessionStorage.removeItem('adminPassword');
    setUserData(null);
    setIsAdmin(false);
    clearAuthSession();
    return signOut(auth);
  }

  async function resetPassword(email: string) {
    return sendPasswordResetEmail(auth, email);
  }

  async function fetchUserData(user: User) {
    try {
      const adminStatus = await checkIfUserIsAdmin(user.email);
      setIsAdmin(adminStatus);

      if (adminStatus) {
        setUserData({
          role: 'admin',
          email: user.email || '',
          contact: {
            name: user.displayName || '',
            email: user.email || ''
          }
        });
        return;
      }

      const accountDoc = await getDoc(doc(db, 'accounts', user.uid));
      if (accountDoc.exists()) {
        const data = accountDoc.data();
        setUserData({
          role: data.role || 'client',
          email: data.contact?.email || '',
          company: {
            name: data.company?.name || ''
          },
          contact: {
            name: data.contact?.name || '',
            email: data.contact?.email || ''
          },
          resellerId: data.resellerId
        });
      } else {
        setUserData(null);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setUserData(null);
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      
      if (user) {
        await fetchUserData(user);
        const adminStatus = await checkIfUserIsAdmin(user.email);
        saveAuthSession(user, adminStatus);
      } else {
        setUserData(null);
        setIsAdmin(false);
        clearAuthSession();
      }
      
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    userData,
    login,
    signup,
    logout,
    resetPassword,
    loading,
    isAdmin
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}